
import * as React from "react";
import Ajax from "../Ajax";

interface IPasswordResetFormProps {
    change_password_url : string
}

interface IPasswordResetFormState {
    success : string
    error   : string
    form    : IForm
    loading : boolean
}

interface IForm{
    password            : string
    confirm_password    : string
    remember_token      : string
}

export interface IResponse{
    success : string
    error   : string
}

export default class PasswordResetForm extends React.Component<IPasswordResetFormProps, IPasswordResetFormState>{

    constructor(props:IPasswordResetFormProps){
        super(props);
        this.state = {
            success                 : null,
            error                   : null,
            form                    : {
                password                    : '',
                confirm_password            : '',
                remember_token              : window.location.href.substr(window.location.href.lastIndexOf('/')+1)
            },
            loading                 : false
        };
    }

    private submitHandler(evt): void {
        let saveData        = this.state.form;
        if ( evt !== null ) {
            evt.preventDefault();
        }
        this.setState({
            loading: true
        }, () =>{
            //changePassword
            var ajax = new Ajax( this.props.change_password_url );
            ajax.doPost(saveData, false).then((result:IResponse) => {
                this.setState({
                    success:    result.success,
                    error:      result.error,
                    loading:    false
                }, ()=>{
                    if ( this.state.success !== null ) {
                        setTimeout(()=>{
                            // window.location.href='https://link.sirhelyapp.memorialsoft.hu/app';
                            // FIXME: itt jártam létrehozni a dinamikus linket
                        }, 1500);
                    }
                });
            });
        });
    }

    private changeInputHandler(evt, type:string): void {
        evt.preventDefault();
        var form            = this.state.form;
        form[type]          = evt.target.value;
        let error:string    = null;
        if ( form.password !== form.confirm_password ) {
            error = 'A két jelszó nem egyezik!';
        }
        this.setState({
            form    : form,
            error   : error
        });
    }

    render() {
        let sendDisabled:boolean = this.state.loading;
        if ( sendDisabled === false && this.state.error !== null ) {
            sendDisabled = true;
        }
        if ( sendDisabled === false && this.state.success !== null ) {
            sendDisabled = true;
        }
        return (
            <>
            <h4 style={{marginTop: '30px'}}>Új jelszó</h4>
            <form method="post" onSubmit={e => this.submitHandler(e)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-row">
                                    <div className="col">
                                        <input className="form-control" type="password" required={true} placeholder="Jelszó" name="password" value={this.state.form.password} onChange={ e => this.changeInputHandler(e,'password') } />
                                    </div>
                                </div>
                                <div className="form-row" style={{
                                    marginTop: '15px'
                                }}>
                                    <div className="col">
                                        <input className="form-control" type="password" required={true} placeholder="Jelszó megerősítése" name="confirm_password" value={this.state.form.confirm_password} onChange={ e => this.changeInputHandler(e,'confirm_password') } />
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.error !== null || this.state.success !== null?<>
                            <div className="form-row" style={{
                                marginTop: '15px'
                            }}>
                                <div className="col-sm-12">
                                    { this.state.error !== null?<div className="alert alert-danger">
                                            { this.state.error }
                                    </div>:''}
                                    { this.state.success !== null?<div className="alert alert-success">
                                            { this.state.success }
                                    </div>:''
                                    }
                                </div>
                            </div>
                        </>:'' }

                        { this.state.success === null?<div className="row"  style={{
                                marginTop: '15px'
                            }}>
                            <div className="col-12">
                                <button className="btn btn-primary btn-block btn_orange" type="submit" disabled={sendDisabled}>{this.state.loading?'Mentés...':'Módosítás'}</button>
                            </div>
                        </div>:'' }
                    </div>
                </form>
			</>
        );
    }
}

import Ajax from '../avernus/ajax/Ajax';
import * as React from 'react';

export interface IComboBoxChanged{
    (value: string, label: string, coords?:any): void;
}

export interface IComboBoxProps {
	placeholder : string;
	currentValue : string;
	currentLabel : string;
	source?: Array<IComboBoxValues>;
	google?:boolean;
	googleCityMode?:boolean;
	googleFromCountry?:string;
	changed: IComboBoxChanged;
	changedButNotInSource?: IComboBoxChanged;
    selectMode?:boolean
    // justEqual?:boolean
    class?:string
    disabled?:boolean
    inputName:string
}
export interface IComboBoxValues {
	label: string;
	value: string;
    place_id?:string;
}

interface IComboBoxState {
	source: Array<IComboBoxValues>;
    currentValue:string;
    showItems:boolean;
}

export class ComboBox extends React.Component<IComboBoxProps, IComboBoxState>{

  	constructor(props:IComboBoxProps){
  		super(props);
  		this.state = this.getInitState();
    }

	componentDidMount() {
        // setState(this.getInitState());
	}

	protected getInitState():any{
		return {
			source      : this.props.source !== undefined?this.props.source:[],
            currentValue: null,
            showItems   : false
		};
	}

    private getDataFromGoogle(str:String):void{
        if ( str.length < 3) {
            return;
        }
        var url:string = '/google';
        var sData:any = {
            'input'         : str
        };
        if ( this.props.googleCityMode !== undefined && this.props.googleCityMode === true ) {
            sData.types = '(cities)';
        }
        if ( this.props.googleFromCountry !== undefined ) {
            sData.country = this.props.googleFromCountry;
        }
        new Ajax(url, 'POST', sData, (data) => {
            this.setState({
                source:  data.data
            } as IComboBoxState);
        });
    }

    private isGoogleMode():boolean{
        if ( this.props.google !== undefined && this.props.google === true ) {
            return true;
        }
        return false;
    }

	private selectedOneHandler(evt, value:string, label:string, force?:boolean): void {
		evt.preventDefault();
        if ( this.isGoogleMode() ) {
            var curr:string = label;
            if ( value !== '' && value !== '0' ) {
                curr = value;
            }
            this.getDataFromGoogle(curr);
            this.setState({
                currentValue: curr
            } as IComboBoxState);
            if ( force !== undefined ) {
                this.getCoords(this.getPlaceId(label), (coords)=>{
                    this.props.changed(value, label, coords);
                });
                this.toggleShowItems(null, true);
            }
        }else{
            this.setState({
                 currentValue: label
            }, () =>{
                this.props.changed(value, label);
            });

        }
	}

    private toggleShowItems(evt, hide?:boolean): void {
        if ( evt !== null ) {
            evt.preventDefault();
        }
        setTimeout(()=>{
            if ( hide !== undefined &&  hide === true ) {
                this.setState({
                    showItems: false
                } as IComboBoxState);
                return;
            }
            this.setState({
                showItems: true
            } as IComboBoxState);
        }, 200);
    }

    private blurHandler(evt, currentValue:string, currentLabel:string): void {
        evt.preventDefault();
        setTimeout(() => {
            if ( this.isGoogleMode() ) {
                if ( this.inSource(currentLabel) ) {
                    if ( this.state.source.length  === 1 ) { //just one founded
                        var first:string = this.state.source[0].label;
                        this.setState({
                            currentValue: first,
                            showItems: false
                        } as IComboBoxState);
                        this.getCoords(this.state.source[0].place_id, (coords)=>{
                            this.props.changed(first, first, coords);
                        });
                        return;
                    }else{
                        this.getCoords(this.getPlaceId(currentLabel), (coords)=>{
                            this.props.changed(currentValue, currentLabel, coords);
                        });
                    }
                }
            }else{
                if ( this.inSource(currentLabel) ) {
                    let newValue = this.getSourceValue(currentLabel);
                    this.setState({
                        // currentValue: newValue,
                        showItems: false
                    } as IComboBoxState, () =>{
                        this.props.changed(newValue, currentLabel);
                    });
                    return;
                }else{
                    console.log('not in source', currentLabel);
                    this.props.changedButNotInSource(currentValue, currentLabel);
                }
            }
            this.toggleShowItems(null, true);
        }, 400);
    }

    private getCoords(placeId:string, cb:Function):void{
        var coords:any = null;
        if ( placeId  === undefined || placeId  === null || placeId  === '' ) {
			return cb({
 				lat: '',
 				lng: ''
 			});
        }
        new Ajax('/googleCoord', 'POST',{
            'place_id'           : placeId
        },(data) => {
            cb(data.data);
        });
    }

    private inSource(currentLabel:string):boolean{
        for (var key in this.state.source) {
            var item = this.state.source[key];
            // if ( item.label.toLowerCase().indexOf(currentLabel.toLowerCase()) > -1 ) {
            if ( item.label.toLowerCase() === currentLabel.toLowerCase() ) {
                return true;
            }
        }
        return false;
    }

    private getPlaceId(currentLabel:string):string{
        for (var key in this.state.source) {
            var item = this.state.source[key];
            if ( item.label.toLowerCase().indexOf(currentLabel.toLowerCase()) > -1 ) {
                return item.place_id;
            }
        }
        return '';
    }

    private getSourceValue(currentLabel:string):string{
        for (var key in this.state.source) {
            var item = this.state.source[key];
            if ( item.label.toLowerCase() === currentLabel.toLowerCase() ) {
                return item.value;
            }
        }
        return '';
    }



    render() {
		var source       = this.state.source;
        var currentValue = this.props.currentValue;
        var currentLabel = this.props.currentLabel;
        if ( this.state.currentValue !== null ) {
            currentValue = this.state.currentValue;
            currentLabel = this.state.currentValue;
        }
        let className = this.props.class!==undefined?this.props.class:'text form-control';
        let disabled:boolean = false;
        if ( this.props.disabled !== undefined ) {
            disabled = this.props.disabled;
        }
        return (
			<div className="combobox">
                <input type="text" name={this.props.inputName} disabled={disabled} className={className} value={ currentLabel } onChange={ (e:any) => this.selectedOneHandler(e, '0' ,e.target.value) } onKeyUp={(e:any) =>{
                    if (e.keyCode == 13) {
                        for (var key in source) {
                            var item = source[key];
                            if ( item.label === e.target.value) {
                                this.selectedOneHandler(e, item.value, item.label);
                                break;
                            }
                        }


                    }
                }} onFocus={ e => this.toggleShowItems(e) } onBlur={ e => this.blurHandler(e, currentValue, currentLabel) } placeholder={ this.props.placeholder } />
                { this.state.showItems === true?<span className="close_icon" onClick={ (evt) => {
                    evt.preventDefault();
                    this.setState({
                        showItems: !this.state.showItems,
                        currentValue: ''
                    });
                }}></span>:'' }
                <div className="items" style={ this.state.showItems === true ? { display: 'block' }  : { display: 'none' } }>
    		        {source.map((item:any) => {
                        if ( this.props.selectMode !== undefined && this.props.selectMode === true ) {
                            currentLabel = '';
                        }
    					var class_name = '';
    					if ( item.value === currentValue) {
    				        class_name = 'active';
    					}
                        var label:string = item.label;
                        if ( item.otherLabel !== undefined && item.otherLabel !== '' ) {
                            label += ' ' + item.otherLabel;
                        }
                        if ( label.toLowerCase().indexOf(currentLabel.toLowerCase()) > -1 ) {
                            return <a key={item.value} className={ class_name } onClick={ (e) => {
        						this.selectedOneHandler(e,item.value, item.label, true);
        					} }>{label}</a>;
                        }
    				})}
    			</div>
			</div>
        );
    }
}

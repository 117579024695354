import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../../Ajax";

interface IFormProps {
  domain: string,
}
interface MyStates {
  usernameField: string,
  usernamesg: string,
  clicked: boolean,
}
export default class Email extends React.Component<IFormProps, MyStates>{
 
  constructor(props:IFormProps) {
    super(props);

    this.state = { usernameField: 'invalid', usernamesg: '', clicked: false }
  }


  private check_email(e){
    if(e.target.value == ''){
      if( this.state.usernameField != 'invalid' ){
        this.setState({
          usernameField: 'invalid',
          usernamesg: 'Kötelező megadni',
          clicked: true
        })
      }
    }else{
      var ajax = new Ajax( this.props.domain + '/api/checkemail' );
      ajax.doPost({email: e.target.value}).then((response) => {
        this.setState({
          usernameField: response.status,
          usernamesg: response.msg,
          clicked: true
        })
      })
    }
  }

  public render() {
    return (
      <>
        <div className="col-12 col-md-6 form-group">
          <input type="email" className="form-control" name="email" placeholder="E-mail cím" onBlur={ (e) => this.check_email(e) } required />
          <div className={(this.state.clicked?'':' hiddencallout') } key={"password_"+Math.random()}>
            <div className={this.state.usernameField == 'valid'?'callout callout-success':'callout callout-danger'}>
            <p>{this.state.usernamesg}</p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../Ajax";
import { Url } from "../tools/Url";
import PasswordReset from "./PasswordReset";
import PasswordResetForm from "./PasswordResetForm.";

interface ILoginProps {
    login_url          : string
    registration_url   : string
    reset_password_url : string
    change_password_url: string
    token              : string
    view               : IView
}


interface IForm{
    email   : string
    password: string
}

type IView = 'login' | 'PasswordResetForm' | 'passwordReset';

interface ILoginState {
    error   : string
    form    : IForm
    view    : IView
}

interface ILoginResult{
    error   : string
    redirect: string
    success : boolean
}

export default class Login extends React.Component<ILoginProps, ILoginState>{

    constructor(props:ILoginProps){
        super(props);
        this.state = {
            view    : this.props.view,
            error   : null,
            form    : {
                email   : '',
                password: ''
            }
        };
    }

    private onSubmitHandler(evt): void {
        evt.preventDefault();
        var ajax = new Ajax( this.props.login_url );
        ajax.doPost({
            email   : this.state.form.email,
            password: this.state.form.password,
            _token  : this.props.token
        }, false).then((response:ILoginResult) => {
            if ( response.success === true ) {
                if ( Url.isApplicationRequest() === true ) {
                    (window as any).mobilAppSetUrl(response.redirect);
                }else{
                    window.location.href = Url.convertToApiUrl(response.redirect);
                }
            }else{
                this.setState({
                    error: response.error
                });
            }
        });
    }

    private changeInputHandler(evt, type:string): void {
        evt.preventDefault();
        var form    = this.state.form;
        form[type]  = evt.target.value;
        this.setState({
            form: form
        });
    }

    private changeView(e, view:IView):void{
        e.preventDefault();
        this.setState({
            view: view
        });
    }

    render() {
        return (
            <>
                { this.state.view === 'login'?<form id="login_form" onSubmit={(e)=>{ this.onSubmitHandler(e) }}>
                    <div className="login_logo">
                        <img src="/img/sirhelyapp_logo.png" style={{width: '60%', marginTop:'10px', marginBottom:'10px'}} />
                    </div>

                    { this.state.error !== null?<div className="callout callout-danger" style={{display: 'block', padding: 10}}>
                        { this.state.error }
                    </div>:'' }

                    <div className="form-group">
                        <input id="email" type="email" className={'form-control'+(this.state.error!==null?' is-invalid':'')} name="email" defaultValue="" required autoComplete="email" placeholder="E-mail cím" autoFocus={true} onChange={ (e) => {
                            this.changeInputHandler(e, 'email');
                        }} />
                    </div>
                    <div className="form-group">
                        <input id="password" type="password" className={'form-control'+(this.state.error!==null?' is-invalid':'')} name="password" required autoComplete="current-password" placeholder="Jelszó" onChange={ (e) => {
                            this.changeInputHandler(e, 'password');
                        }} />
                    </div>
                    <div className="form-group">
                        <div className="row">
                            <div className="col-6">
                                <a href={this.props.registration_url} onClick={ e => this.redirect(e) }
                                 className="btn btn-primary btn-block btn_orange_trans">Regisztráció</a>
                            </div>
                            <div className="col-6">
                                <input type="hidden" name="_token" defaultValue={this.props.token} />
                                <button type="submit" className="btn btn-primary btn-block btn_orange">Belépés</button>
                            </div>
                        </div>
                        <div className="row" style={{marginTop: '10px'}}>
                            <div className="col-12">
                                <a className="btn btn-link forgot_password_btn" onClick={e => this.changeView(e, 'passwordReset')}>Elfelejtettem a jelszavam</a>
                            </div>
                        </div>
                    </div>
                </form>:<>
                    { this.state.view === 'passwordReset'?<PasswordReset reset_password_url={this.props.reset_password_url} />:''}
                    { this.state.view === 'PasswordResetForm'?<PasswordResetForm change_password_url={this.props.change_password_url} />:''}
                    <div className="row" style={{marginTop: '50px'}}>
                        <div className="col-12">
                            <a className="btn btn-link" onClick={e => this.changeView(e, 'login')}>Vissza a bejelentkezésre</a>
                        </div>
                    </div>
                </> }
            </>
        );
    }
    redirect(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        try {
            if ( (window as any).mobilAppSetUrl != undefined ) {
        e.preventDefault();
        (window as any).mobilAppSetUrl((e.target as HTMLAnchorElement).href);
    }
        } catch (error) {}
    }
}
export class InitLogin {
    constructor() {
        var div: HTMLDivElement = document.getElementById('login_comp') as HTMLDivElement;
        if (div !== null) {
            ReactDOM.unmountComponentAtNode(div);
            let token =  (document.getElementsByName('_token')[0] as HTMLInputElement).value;
            ReactDOM.render(<Login
                view={div.dataset.view as IView}
                login_url={div.dataset.login_url}
                registration_url={div.dataset.registration_url}
                reset_password_url={div.dataset.reset_password_url}
                change_password_url={div.dataset.change_password_url}
                token={token} />, div);
        }
    }
}

import { TStorage } from "./tools/TStorage";
import { Url } from "./tools/Url";

export class Token{
    constructor() {
        let currentToken    = this.getCurrentToken();
        let url             = Url.getUrl();
        if ( Url.isApplicationRequest() === true ) {
            url = (window as any).mobilAppGetUrl();
        }
        if ( url === 'belepes' || url.indexOf('/belepes') > -1 ) {
            console.log('check token', currentToken);
            if ( currentToken !== null ) {
                this.setTokenFromStorage(null);
                let redirectUrl = '/index?token=' + currentToken;
                if ( Url.isApplicationRequest() === true ) {
                    (window as any).mobilAppSetUrl(redirectUrl);
                }else{
                    window.location.href = Url.convertToApiUrl(redirectUrl);
                }
            }
        }
    }

    private getCurrentToken():string{
        let newToken        = this.getTokenFromGET();
        if ( newToken !== null ) {
            if ( newToken === 'drop' ) {
                newToken = null;
            }
            this.setTokenFromStorage(newToken);
            return newToken;
        }
        return this.getTokenFromStorage();
    }

    private getTokenFromGET():string {
        let token = Url.get('token');
        if ( Url.isApplicationRequest() === true ) {
            let url = (window as any).mobilAppGetUrl();
            token = Url.get('token', url);
            console.log('getTokenFromGET', url, token);
        }
        return token;
    }

    private getTokenFromStorage():string{
        let Storage = new TStorage();
        return Storage.get('token', null);
    }

    private setTokenFromStorage(token:string):void{
        let Storage = new TStorage();
        console.log('token refreshed', token);
        return Storage.set('token', token);
    }
}



import * as React from "react";
import Ajax from "../Ajax";

interface IPasswordResetProps {
    reset_password_url : string
}

interface IPasswordResetState {
    success : string
    error   : string
    form    : IForm
    loading : boolean
}

interface IForm{
    email   : string
}

export interface IResponse{
    success : string
    error   : string
}

export default class PasswordReset extends React.Component<IPasswordResetProps, IPasswordResetState>{

    constructor(props:IPasswordResetProps){
        super(props);
        this.state = {
            success                 : null,
            error                   : null,
            form                    : {
                email           : ''
            },
            loading                 : false
        };
    }

    private submitHandler(evt): void {
        let saveData        = this.state.form;
        if ( evt !== null ) {
            evt.preventDefault();
        }
        this.setState({
            loading: true
        }, () =>{
            //PasswordReset
            var ajax = new Ajax( this.props.reset_password_url );
            ajax.doPost(saveData, false).then((result:IResponse) => {
                this.setState({
                    success:    result.success,
                    error:      result.error,
                    loading:    false
                });
            });
        });
    }

    private changeInputHandler(evt, type:string): void {
        evt.preventDefault();
        var form = this.state.form;
        form[type] = evt.target.value;
        this.setState({
            form: form
        });
    }

    render() {
        return (
            <>
            <h4 style={{marginTop: '30px'}}>Elfelejtettem a jelszavam</h4>
            <form method="post" onSubmit={e => this.submitHandler(e)}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <p className="lead">Adja meg a valós e-mail címét és küldünk egy jelszó visszaállítási linket.</p>
                                <div className="form-row">
                                    <div className="col">
                                        <input className="form-control" type="email" required={true} placeholder="E-mail címe" name="email" value={this.state.form.email} onChange={ e => this.changeInputHandler(e,'email') } />
                                    </div>
                                </div>
                            </div>
                        </div>
                        { this.state.error !== null || this.state.success !== null?<>
                            <div className="form-row" style={{
                                marginTop: '15px'
                            }}>
                                <div className="col-sm-12">
                                    { this.state.error !== null?<div className="alert alert-danger">
                                            { this.state.error }
                                    </div>:''}
                                    { this.state.success !== null?<div className="alert alert-success">
                                            { this.state.success }
                                    </div>:''
                                    }
                                </div>
                            </div>
                        </>:'' }

                        { this.state.success === null?<div className="row"  style={{
                                marginTop: '15px'
                            }}>
                            <div className="col-12">
                                <button className="btn btn-primary btn-block btn_orange" id="send_password_reset_email_btn" type="submit" disabled={this.state.loading}>{this.state.loading?'Küldés...':'Új jelszó igénylése'}</button>
                            </div>
                        </div>:'' }
                    </div>
                </form>
			</>
        );
    }
}

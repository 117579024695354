export class TStorage {

    private enabled:boolean;

    constructor(){
        if( window.localStorage ) {
            this.enabled = true;
        } else {
            this.enabled = false;
        }
    }

    public get(field:string, default_value:any):any{
        if ( this.enabled === true ) {
            if ( localStorage.getItem(field) !== undefined && localStorage.getItem(field) !== null  ) {
                var ret = localStorage.getItem(field);
                if ( ret.indexOf('[') === 0 || ret.indexOf('{') === 0 ) {
                    return JSON.parse(ret);
                }

                 if ( ret === 'null' ) {
                    return null;
                }
                if ( ret === 'undefined' ) {
                    return undefined;
                }
                return ret;
            }
        }
        if ( default_value === 'null' ) {
            return null;
        }
        if ( default_value === 'undefined' ) {
            return undefined;
        }

        return default_value;
    }

    public set(field:string, value:any):void{
        if ( this.enabled === true ) {
            if ( value instanceof Array || value instanceof Object ) {
                value = JSON.stringify(value);
            }

            localStorage.setItem(field, value);
        }
    }
}

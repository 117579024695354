interface IUrl{
    url  : string
    title: string
    data : any
}
declare var device:any;
export class Url {
    private static urls:any = null;
    private static historyUrls:Array<IUrl> = [];

    public static get(field:string, url:string=null):string{
        let cache:any = url===null?this.urls:null;

        if ( cache === null ) {
            cache   = {};
            if ( url === null ) {
                url     = decodeURIComponent(window.location.href);
            }else{
                console.log(url);
            }
            var firstParamIndex:number = url.indexOf('?');
            if ( firstParamIndex > -1 ) {
                var paramsString = url.substr(firstParamIndex+1)
                var temp:Array<string> = null;
                if ( paramsString.indexOf('&') > -1 ) {
                    temp = paramsString.split('&');
                }else{
                    temp = [paramsString];
                }
                for (var key in temp) {
                    var item = temp[key].split('=');
                    cache[item[0]] = item[1];
                }
            }
        }
        if ( url === null ) {
            this.urls = cache;
        }

        if ( cache[field] !== undefined ) {
            return cache[field];
        }else{
            return null;
        }
    }

    public static emptyAll(){
        this.urls        = null;
        this.historyUrls = [];
    }

    public static setUrl(url:string, data:any=null, title:string=null):string{
        if (  url === '-1' ) {
            let lastUrl = this.historyGetLast(true);
            if (  lastUrl !== null ) {
                url         = lastUrl.url;
                data        = lastUrl.data;
                title       = lastUrl.title;
            }else{
                url = '';
            }
        }else{
            this.historyAdd(url,title,data);
        }
        history.pushState(data, title, '#'+url);
        return url;
    }

    private static historyAdd(url: string, title: string, data: any) {
        let lastUrl = this.historyGetLast();
        if ( lastUrl === null || (lastUrl.url !== url && JSON.stringify(lastUrl.data) !== JSON.stringify(data) ) ) {
            this.historyUrls.push({
                url: url,
                title: url,
                data: url,
            });
        }
    }

    private static historyGetLast(removeLast:boolean=false):IUrl{
        let index = this.historyUrls.length-2;
        if (  index >= 0 ) {
            if ( this.historyUrls[index] !== undefined ) {
                if ( this.historyUrls[index].toString() !== Url.getUrl() ) {
                    let lastUrl = this.historyUrls[index];
                    if (  removeLast === true ) {
                        delete this.historyUrls[index];
                        let temp = [];
                        for (const item of this.historyUrls) {
                            if ( item !== undefined ) {
                                temp.push(item);
                            }
                            this.historyUrls = temp;
                        }
                    }
                    return lastUrl;
                }

            }
        }
        return null;
    }

    public static getUrl():string{
        return window.location.pathname.substr(1);
    }

    public static isUrl(pattern:RegExp):boolean {
        return pattern.test(this.getUrl());
    }

    public static getUrlPart(part:number):string {
        var temp:Array<string> = this.getUrl().split('/');
        if (  temp[part] !== undefined ) {
            return temp[part];
        }else{
            console.error('getUrlPart error, not found url part');
        }


        return null;
    }

    public static isSafari():boolean{
        // if ( device.platform === 'iOS' ) {
        //     return true;
        // }
        return navigator.userAgent.toLowerCase().indexOf('safari')>-1 && navigator.userAgent.toLowerCase().indexOf('chrome')=== -1;
    }

    public static getDomainWithProtocol():string{
        // return 'app://localhost';
        return window.location.protocol+'//'+window.location.host+'/';
    }

    public static isApplicationRequest():boolean{
        try {
            if ( device != undefined ) {
                return true;
            }
        } catch (error) {}
        return Url.get('mobilrequest')!==null;
    }

    public static convertToApiUrl(url:string):string{
        if (  Url.isApplicationRequest() ) {
            if ( url.indexOf('.hu') === -1 ) {
                return '/api'+url.replace('//','/');
            }
            return url.indexOf('.hu/api/')===-1?url.replace('.hu/', '.hu/api/'):url;
        }
        return url;
    }
}

import { InitRegForm } from "./forms/RegForm";
import { InitProfilForm } from "./forms/ProfilForm";
import { Token } from "./Token";
import { InitLogin } from "./forms/Login";

class Main{
    constructor(){
        new Token();
        new InitLogin();
        new InitRegForm();
        new InitProfilForm();
    }
}
new Main();

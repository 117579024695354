import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../Ajax";
import City from "./items/City";
import Password from './items/Password';

interface IFormProps {
    domain: string
    token : string
}
interface MyStates {
    message      : string
    message_title: string
    message_type : string
    ajaxloaded   : number
    clicked      : boolean
    user         : any
}
export default class ProfilForm extends React.Component<IFormProps, MyStates>{
    private text = [];
    private token = '';
    private interests:object;
    constructor(props:IFormProps) {
        super(props);

        this.token = this.props.token

        this.state = {message: '', message_title: '', message_type: '', ajaxloaded: 0, clicked: false, user: {}}
        // this.handleSubmit = this.handleSubmit.bind(this)
        this.loadData();
    }

    private loadData(){
        var ajax = new Ajax( this.props.domain + '/profil/getUserData?token='+ this.token );
        ajax.doPost({}).then((response) => {
            this.token = response.token
            this.setState({user: response, ajaxloaded: Math.random()})
        })
    }

    private handleSubmit(event) {
        event.preventDefault();
        var ajax = new Ajax( this.props.domain + '/profil/update?token='+ this.token );
        ajax.doPost('profilform', true).then((response) => {
            this.token = response.token
            this.setState({user: response.user, ajaxloaded: Math.random(), message: response.message, message_type: response.message_type})
        })
    }

    private changeInputHandler(evt, type:string): void {
        evt.preventDefault();
        var user = this.state.user;
        user[type] = evt.target.value;
        this.setState({
            user: user
        });
    }

    public render() {
        if(this.state.ajaxloaded > 0){
            return (
            <>
                <form onSubmit={(e) =>{ this.handleSubmit(e); }} id="profilform">
                    <div className="row">
                        <div className="col-12">
                            <h2>Profil szerkesztése</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Vezetéknév</label>
                                <input type="text" className="form-control" name="last_name" defaultValue={this.state.user.last_name} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Keresztnév</label>
                                <input type="text" className="form-control" name="First_name" defaultValue={this.state.user.First_name} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div className="form-control" style={{background:'transparent'}}>{this.state.user.email}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>
                                    Telefonszám
                                </label>
                                <div className={ this.state.user.phone === ''?'required_row':''}>
                                    <input type="text" className="form-control" name="phone" value={this.state.user.phone} onChange={(e)=>{
                                        this.changeInputHandler(e, 'phone');
                                    }} />
                                    { this.state.user.phone === ''?<span className="not_filled_all_data_icon">!</span>:'' }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label>Irányítószám</label>
                                <div className={ this.state.user.zip === ''?'required_row':''}>
                                    <input type="text" className="form-control" name="zip" value={this.state.user.zip} onChange={(e)=>{
                                        this.changeInputHandler(e, 'zip');
                                    }} />
                                    { this.state.user.zip === ''?<span className="not_filled_all_data_icon">!</span>:'' }
                                </div>
                            </div>
                        </div>
                        <div className="col-8">
                            <div className="form-group">
                                <label>Település</label>
                                <div className={ this.state.user.city === ''?'required_row':''}>
                                    <input type="text" className="form-control" name="city" value={this.state.user.city} onChange={(e)=>{
                                        this.changeInputHandler(e, 'city');
                                    }} />
                                    { this.state.user.city === ''?<span className="not_filled_all_data_icon">!</span>:'' }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <div className={ this.state.user.address === ''?'required_row':''}>
                                    <input type="text" className="form-control" name="address" value={this.state.user.address} onChange={(e)=>{
                                        this.changeInputHandler(e, 'address');
                                    }} />
                                    { this.state.user.address === ''?<span className="not_filled_all_data_icon">!</span>:'' }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Password domain={this.props.domain} canEmpty={true}/>
                    <div className="row">
                        <City domain={this.props.domain} name="cemetery_city" value={this.state.user.cemetery_city} placeholder="Hol található temetője?" onChange={(e)=>{
                            this.changeInputHandler(e, 'cemetery_city');
                        }} />
                    </div>
                    <div className="row">
                        <div className="col-12" style={{textAlign:'center'}}>
                            <button type="submit" className="btn btn-primary btn_orange">Mentés</button>
                        </div>
                    </div>
                    {this.state.message_type != ''?
                        <div className={ "callout callout-" + this.state.message_type + " callout-" + this.state.message_type + "_display" }>
                        {this.state.message}
                        </div>
                    :<></>}
                </form>
                <div className="back">
                    <a href={this.props.domain + '/index?token=' + this.token} onClick={ e => this.redirect(e) } className="btn btn-primary btn_grey"> <i className="fas fa-arrow-left"></i> vissza a sírhelyekhez</a>
                </div>
            </>
        )
        }else{
            return (<></>)
        }
    }

    redirect(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void {
        try {
            if ( (window as any).mobilAppSetUrl != undefined ) {
                e.preventDefault();
                (window as any).mobilAppSetUrl((e.target as HTMLAnchorElement).href);
            }
        } catch (error) {}
    }
}

export class InitProfilForm {
    constructor() {
        var div: HTMLDivElement = document.getElementById('profil_form') as HTMLDivElement;
        if (div !== null) {
            var data:any = div.dataset;
            ReactDOM.render(<ProfilForm domain={data.domain} token={data.token} />, div);
        }
    }
}

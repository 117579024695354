
import * as React from "react";
import { ComboBox } from "../../../avernus/combobox/source/ComboBox";
import CityDatabase from "./CityDatabase";

interface ICityProps {
    name       : string
    placeholder: string
    value      : string
    domain     : string
    onChange   : Function
}

interface ICityState {
    value       : string
    index       : number
}

export default class City extends React.Component<ICityProps, ICityState>{

    constructor(props:ICityProps){
        super(props);
        this.state = {
            value   : this.props.value,
            index   : 0
        };
    }

    // private finInSource(value:string):string{
    //     value = value.toLowerCase();
    //     for (var key in CityDatabase.cityList) {
    //         var item = CityDatabase.cityList[key];
    //         if ( item.value.toLowerCase().indexOf(value) > -1 ) {
    //             return item.value;
    //         }
    //     }
    //     return null;
    // }

    private finInSource(value:string):boolean{
        value = value.toLowerCase();
        for (var key in CityDatabase.cityList) {
            var item = CityDatabase.cityList[key];
            if ( item.value.toLowerCase() === value ) {
                return true;
            }
        }
        return false;
    }

    render() {
        return (
            <div className="col-12 col-md-12 form-group">
                <label>{ this.props.placeholder }</label>
                <ComboBox
                    key             = { this.props.name + '_' + this.state.index.toString() }
                    selectMode      = { false }
                    placeholder     = { this.props.placeholder }
                    inputName       = { this.props.name }
                    currentValue    = { this.state.value }
                    currentLabel    = { this.state.value }
                    source          = { CityDatabase.cityList }
                    changed         = { (value, label) => {
                        this.setState({
                            value: label
                        });
                    }}
                    changedButNotInSource         = { (value, label) => {
                        setTimeout(() => {
                            if ( this.finInSource(this.state.value) === false ) {
                                let index = this.state.index;
                                ++index;
                                this.setState({
                                    value: '',
                                    index: index
                                });
                            }
                        }, 800);
                    }}
                />
            </div>
        );
    }
}

import * as React from "react";
import * as ReactDOM from "react-dom";
import Ajax from "../Ajax";
import Password from './items/Password';
import Email from "./items/Email";
import City from "./items/City";

interface IFormProps {
    domain: string,
}
interface MyStates {
    message: string
    message_title: string
    message_type: string
    ajaxloaded: number,
    clicked: boolean,
    checked: boolean
}
export default class RegForm extends React.Component<IFormProps, MyStates>{
    private text = [];
    private checkboxwarning:boolean;
    private interests:object;
    constructor(props:IFormProps) {
        super(props);

        this.state = {message: '', message_title: '', message_type: '', ajaxloaded: 0, clicked: false, checked: false}
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    private handleSubmit(event) {
        event.preventDefault();

        if(!this.state.checked){
            this.checkboxwarning = true; 
            this.setState({ajaxloaded: Math.random()})
        }else{
            this.checkboxwarning = false; 
            var ajax = new Ajax( this.props.domain + '/api/registrationform' );
            ajax.doPost('regform', true).then((response) => {
                this.setState({message_type: response.status, message: response.msg, message_title: response.title, ajaxloaded: Math.random()})
            })
        }
    }

    private checkAszfCb(e){
        if(e.target.checked){
            this.setState({checked: true})
            this.checkboxwarning = false; 
        }else{
            this.setState({checked: false})
            this.checkboxwarning = true; 
        }
    }

    public render() {
        return (
        <>
            <form onSubmit={this.handleSubmit} id="regform" key={"registrationform"}>
                <div className="row">
                    <div className="col-12">
                    <h2>{this.text['data_3']}</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 col-md-6 col-lg-6 form-group">
                    <input type="text" className="form-control" name="last_name" placeholder="Vezetéknév" required />
                    </div>
                    <div className="col-6 col-md-6 col-lg-6 form-group">
                    <input type="text" className="form-control" name="first_name" placeholder="Keresztnév" required />
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 form-group">
                    <input type="text" className="form-control" name="phone" placeholder="Telefonszám" required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 form-group">
                    <input type="text" className="form-control" name="address" placeholder="Lakcíme" required />
                    </div>
                </div> */}
                {/* <div className="reg_title_2">
                    Belépési adatok
                </div> */}
                <div className="row">
                    <Email domain={this.props.domain}/>
                </div>
                <Password domain={this.props.domain} canEmpty={false}/>
                <div className="row">
                    <City domain={this.props.domain} name="cemetery_city" value="" placeholder="Hol található temetője?" onChange={(e)=>{
                        // kiolvassaaz Andoros módon
                    }} />
                </div>

                <div className="row aszf_container">
                    {this.checkboxwarning
                    ?
                    <>
                        <div className="col-12 errormsg">
                            <p>A regisztrációhoz el kell fogadnia az Adattovábbítási nyilatkozatot!</p>
                        </div>
                    </>
                    :
                    <></>}
                    <div className={"col-12 aszf " + (this.checkboxwarning?'errorbox':'')}>
                        {this.termsAndConditions()}
                        <div className="checkbox" key ={"cb_"+ Math.random()}>
                            <input type="checkbox" id="aszf" name="aszf" value="accepted" defaultChecked={this.state.checked} onChange={(e) => this.checkAszfCb(e)} />
                            <label htmlFor="aszf"> Az Adattovábbítási nyilatkozatot elolvastam és az abban foglaltakat elfogadom </label>
                        </div>
                    </div>
                </div>
                {this.state.message_type != ''?
                    <div className={ "callout callout-success message_" + this.state.message_type }>
                    <div className="title">{this.state.message_title}</div>
                    {this.state.message}
                    </div>
                    :<></>}
                <div className="row centered">
                    <div className="col-12">
                    <input type="password" className="form-control alt" name="password__again" />
                    <button className="btn btn-primary btn_orange" name="contact">Regisztráció</button>
                    </div>
                </div>
            </form>
        </>
        );
    }
    private termsAndConditions() {
        return (
        <>
<p><b>ADATTOVÁBBÍTÁSI  NYILATKOZAT  TÁJÉKOZTATÓ</b></p> 
<p>Az adattovábbításra vonatkozó nyilatkozat: Az adatkezeléssel érintett (a 2011. évi CXII. törvény az információs önrendelkezési jogról 3. § 1. pontja szerinti természetes személy, a továbbiakban: érintett) és az információszabadságról nyilatkozatával az Európai Parlament és Tanács (EU) 2016/679 rendelete (2016. április 27.) (a továbbiakban: GDPR) 20. cikk 1. bek. szerinti jogával élve önkéntes és kifejezett hozzájárulását adja ahhoz, hogy a Memorial Szoftver Kft-vel szerződéses jogviszonyban álló Temetkezési szolgáltató, Temető üzemeltető, Temetkezési iroda (előfizető), mint adatkezelő által a Memorial Szoftver rendszerében kezelt, a hozzájárulásban egyértelműen megjelölt személyes adatai a Memorial Szoftver Kft. (2600 Vác, Cházár A. u. 17., Cg.: 13-09-181333, tel.: +36 20 526 9791, e-mail.: info@memorialszoftver.hu, képv.: Szilágyi László ügyvezető, a továbbiakban: Memorial Szoftver Kft.) címzett részére továbbításra kerüljenek azok címzett általi, önálló adatkezelési tevékenység végzése céljából. </p>
<p>Az érintett a GDPR 20. cikk 1. bek. szerinti jogával élve önkéntes és kifejezett hozzájárulását adja továbbá ahhoz is, hogy a Memorial Szoftver Kft. által a SírhelyAPP mobil alkalmazás igénybevétele (szerződés) kapcsán az általa megadott és a Memorial Szoftver Kft. által kezelt lakcím és e-mail cím adatai az érintetthez tartozó sírhely, vagy sírhelyek üzemeltetését végző adatkezelő (Temetkezési szolgáltató, Temető üzemeltető, Temetkezési iroda), mint címzett részére továbbításra kerüljenek azok címzett általi adatkezelési tevékenység végzése (adatfrissítés) céljából. </p>
<p>Az adatkezelő kötelezettsége: Az adatkezelő az érintett hozzájárulása alapján az általa kezelt, hozzájárulásban megjelölt személyes adatokat a kérelem kézhezvételével egyidejűleg, késedelem nélkül köteles a címzett részére átadni, vagy azokhoz a címzett részére hozzáférést biztosítani az adatvédelmre, ill. adatbiztonságra vonatkozó előírások megtartása mellett. </p>
<p>Az adattovábbítás célja: a címzett és az érintett között létrejött szerződés teljesítéséhez (SírhelyAPP mobil alkalmazás, ill. temetkezési szolgáltatás, vagy sírhelymegváltás) szükséges, adatkezelő által kezelt személyes adatok címzett rendelkezésére bocsátása.</p> 
<p>Az adattovábbítás terjedelme:</p>
<p>A Memorial Szoftver Kft. címzett részére történő adattovábbítás esetén: az érintett Memorial Szoftver rendszerébe a Temetkezési szolgáltató, Temető üzemeltető, Temetkezési iroda, mint adatkezelő által feltöltött, ill. kezelt személyes adatai közül: az érintett által megadott e-mail cím és lakcím, a sírhelyben fekvő személy(ek) viselt vezeték és keresztneve(i), a sírhelyben fekvő személy(ek) születési, elhalálozási és a sírhelyben történő temetésének ideje, a sírhely-használat lejárati időpontja, a sírhely elhelyezkedésére és méretére, állagára vonatkozó adatok, a sírhely fotói. </p>
<p>Az érintetthez tartozó sírhely, vagy sírhelyek üzemeltetését végző adatkezelő (Temetkezési szolgáltató, Temető üzemeltető, Temetkezési iroda), mint címzett részére történő adattovábbítás esetén a Memorial Szoftver Kft. által kezelt, a SírhelyAPP mobil alkalmazás igénybevétele során megadott lakcím és e-mail cím. A továbbított személyes adatokat a címzett kizárólag az érintett Memorial Szoftver rendszerében tárolt adatai frissítésére használja fel. </p>
<p>Az érintettet megillető jogok:</p> 
<p style={{marginBottom: '0px',paddingBottom: '0px'}}>a személyes adatai kezelésével kapcsolatos tájékoztatáshoz való jog, </p> 
<p style={{marginBottom: '0px',paddingBottom: '0px'}}>a kezelt személyes adatainak helyesbítéshez való jog, </p> 
<p style={{marginBottom: '0px',paddingBottom: '0px'}}>a kezelt személyes adatainak törléséhez való jog, </p> 
<p style={{marginBottom: '0px',paddingBottom: '0px'}}>az adatkezelés korlátozásához való jog, </p> 
<p style={{marginBottom: '0px',paddingBottom: '0px'}}>az adathordozhatósághoz való jog, </p> 
<p style={{marginBottom: '0px',paddingBottom: '0px'}}>a személyes adatai kezelésével kapcsolatos tiltakozáshoz való jog, </p> 
<p>a személyes adatai kezelésével kapcsolatos jogorvoslathoz való jog. </p> 
<p>Jogorvoslat: Az érintett a személyes adatai kezelésével kapcsolatos jogsérelme esetén, jogai védelme érdekében jogosult panaszt, ill. bejelentést előterjeszteni a Nemzeti Adatvédelmi és Információszabadság Hatóság (NAIH) előtt (1055 Budapest, Falk Miksa utca 9-11., levelezési cím: 1374 Budapest, Pf. 603., Telefon: +36 (30) 683-5969, ill. +36 (30) 549-6838, Email: ugyfelszolgalat@naih.hu, Honlap: http://www.naih.hu), illetőleg bírósághoz (a választása szerint a lakóhelye vagy tartózkodási helye szerint illetékes törvényszék) fordulhat. </p> 
<p><b>HOZZÁJÁRULÓ NYILATKOZAT</b></p> 
<p>Alulírott személyes adatok kezelésével érintett a jelen nyilatkozatom útján a GDPR 20. cikk. (1) bekezdésében írt jogommal élve megfelelő és érthető tájékoztatás birtokában a SírhelyAPP mobil alkalmazásban történő véglegesített regisztrációmmal önkéntesen és kifejezetten hozzájárulok ahhoz, és kérem, hogy a Temetkezési szolgáltató, Temető üzemeltető, Temetkezési iroda, mint adatkezelő (a továbbiakban: adatkezelő) által a Memorial Szoftver rendszerében tárolt, ill. kezelt személyes adataim közül a hozzám, mint sírhely felett rendelkezőhöz kapcsolt sírhelyek esetében, az e-mail címem, a lakcímem, sírhelyben fekvő személy(ek) viselt vezeték és keresztneve(i), a sírhelyben fekvő személy(ek) születési, elhalálozási és a sírhelyben történő temetésének ideje, a sírhely-használat lejárati időpontja, a sírhely elhelyezkedésére és méretére, állagára vonatkozó adatok, a sírhely fotói adataimat a Memorial Szoftver Kft. (2600 Vác, Cházár A. u. 17., a továbbiakban: címzett) címzett részére továbbítsa a köztem és a címzett között létrejött szerződés (SírhelyAPP mobil alkalmazás használata) teljesítése érdekében. </p> 
<p>Alulírott személyes adatok kezelésével érintett a jelen nyilatkozatom útján a GDPR 20. cikk. (1) bekezdésében írt jogommal élve megfelelő és érthető tájékoztatás birtokában a SírhelyAPP mobil alkalmazásban történő véglegesített regisztrációmmal önkéntesen és kifejezetten hozzájárulok ahhoz, és kérem, hogy a Memorial Szoftver Kft., mint adatkezelő által kezelt lakcím és e-mail cím adataimat a Memorial Szoftver Kft., mint adatkezelő adatfrissítés céljából a Memorial Szoftver rendszerében tárolt személyes adataim adatkezelője (Temetkezési szolgáltató, Temető üzemeltető, Temetkezési iroda) részére továbbítsa a köztem és a címzett között létrejött szerződés (temetkezési szolgáltatás, ill. sírhely megváltás) teljesítése érdekében. </p> 
<p>Alulírott megfelelő, részletes és érthető tájékoztatás birtokában kijelentem, hogy tudomásul veszem, hogy a címzett részére továbbított személyes adataimat a címzett, mint önálló adatkezelő a közöttünk létrejött szerződés teljesítése érdekében kezeli. Alulírott kijelentem, hogy a címzett, mint önálló adatkezelő által végzett adatkezelés tekintetében előzetesen megfelelő, részletes és érthető tájékoztatást kaptam az általa végzett adatkezelés lényeges jellemzői (kezelt személyes adatok köre, a személyes adataim megismerésére jogosultak, az adatok továbbítása, az adatkezelés jogalapja, az adatkezelés időtartama, az engem megillető jogok, ill. a jogorvoslat, stb.) tekintetében. Alulírott tudomásul veszem, hogy a címzett és köztem létrejött szerződés jogalapja a szerződés teljesítése. </p> 
</>)
    }
}

export class InitRegForm {
    constructor() {
        var div: HTMLDivElement = document.getElementById('registration_form') as HTMLDivElement;
        if (div !== null) {
            var data:any = div.dataset;
            ReactDOM.render(<RegForm domain={data.domain} />, div);
        }
    }
}

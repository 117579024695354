import { IComboBoxValues } from "../../../avernus/combobox/source/ComboBox";

var cityList:Array<IComboBoxValues> = [
        {
            "label": "Aba",
            "value": "Aba"
        },
        {
            "label": "Abádszalók",
            "value": "Abádszalók"
        },
        {
            "label": "Abaliget",
            "value": "Abaliget"
        },
        {
            "label": "Abasár",
            "value": "Abasár"
        },
        {
            "label": "Abaújalpár",
            "value": "Abaújalpár"
        },
        {
            "label": "Abaújkér",
            "value": "Abaújkér"
        },
        {
            "label": "Abaújlak",
            "value": "Abaújlak"
        },
        {
            "label": "Abaújszántó",
            "value": "Abaújszántó"
        },
        {
            "label": "Abaújszolnok",
            "value": "Abaújszolnok"
        },
        {
            "label": "Abaújvár",
            "value": "Abaújvár"
        },
        {
            "label": "Abda",
            "value": "Abda"
        },
        {
            "label": "Abod",
            "value": "Abod"
        },
        {
            "label": "Abony",
            "value": "Abony"
        },
        {
            "label": "Ábrahámhegy",
            "value": "Ábrahámhegy"
        },
        {
            "label": "Ács",
            "value": "Ács"
        },
        {
            "label": "Acsa",
            "value": "Acsa"
        },
        {
            "label": "Acsád",
            "value": "Acsád"
        },
        {
            "label": "Acsalag",
            "value": "Acsalag"
        },
        {
            "label": "Ácsteszér",
            "value": "Ácsteszér"
        },
        {
            "label": "Adács",
            "value": "Adács"
        },
        {
            "label": "Ádánd",
            "value": "Ádánd"
        },
        {
            "label": "Adásztevel",
            "value": "Adásztevel"
        },
        {
            "label": "Adony",
            "value": "Adony"
        },
        {
            "label": "Adorjánháza",
            "value": "Adorjánháza"
        },
        {
            "label": "Adorjás",
            "value": "Adorjás"
        },
        {
            "label": "Ág",
            "value": "Ág"
        },
        {
            "label": "Ágasegyháza",
            "value": "Ágasegyháza"
        },
        {
            "label": "Ágfalva",
            "value": "Ágfalva"
        },
        {
            "label": "Aggtelek",
            "value": "Aggtelek"
        },
        {
            "label": "Agyagosszergény",
            "value": "Agyagosszergény"
        },
        {
            "label": "Ajak",
            "value": "Ajak"
        },
        {
            "label": "Ajka",
            "value": "Ajka"
        },
        {
            "label": "Aka",
            "value": "Aka"
        },
        {
            "label": "Akasztó",
            "value": "Akasztó"
        },
        {
            "label": "Alacska",
            "value": "Alacska"
        },
        {
            "label": "Alap",
            "value": "Alap"
        },
        {
            "label": "Alattyán",
            "value": "Alattyán"
        },
        {
            "label": "Albertirsa",
            "value": "Albertirsa"
        },
        {
            "label": "Alcsútdoboz",
            "value": "Alcsútdoboz"
        },
        {
            "label": "Aldebrő",
            "value": "Aldebrő"
        },
        {
            "label": "Algyő",
            "value": "Algyő"
        },
        {
            "label": "Alibánfa",
            "value": "Alibánfa"
        },
        {
            "label": "Almamellék",
            "value": "Almamellék"
        },
        {
            "label": "Almásfüzitő",
            "value": "Almásfüzitő"
        },
        {
            "label": "Almásháza",
            "value": "Almásháza"
        },
        {
            "label": "Almáskamarás",
            "value": "Almáskamarás"
        },
        {
            "label": "Almáskeresztúr",
            "value": "Almáskeresztúr"
        },
        {
            "label": "Álmosd",
            "value": "Álmosd"
        },
        {
            "label": "Alsóberecki",
            "value": "Alsóberecki"
        },
        {
            "label": "Alsóbogát",
            "value": "Alsóbogát"
        },
        {
            "label": "Alsódobsza",
            "value": "Alsódobsza"
        },
        {
            "label": "Alsógagy",
            "value": "Alsógagy"
        },
        {
            "label": "Alsómocsolád",
            "value": "Alsómocsolád"
        },
        {
            "label": "Alsónána",
            "value": "Alsónána"
        },
        {
            "label": "Alsónémedi",
            "value": "Alsónémedi"
        },
        {
            "label": "Alsónemesapáti",
            "value": "Alsónemesapáti"
        },
        {
            "label": "Alsónyék",
            "value": "Alsónyék"
        },
        {
            "label": "Alsóörs",
            "value": "Alsóörs"
        },
        {
            "label": "Alsópáhok",
            "value": "Alsópáhok"
        },
        {
            "label": "Alsópetény",
            "value": "Alsópetény"
        },
        {
            "label": "Alsórajk",
            "value": "Alsórajk"
        },
        {
            "label": "Alsóregmec",
            "value": "Alsóregmec"
        },
        {
            "label": "Alsószenterzsébet",
            "value": "Alsószenterzsébet"
        },
        {
            "label": "Alsószentiván",
            "value": "Alsószentiván"
        },
        {
            "label": "Alsószentmárton",
            "value": "Alsószentmárton"
        },
        {
            "label": "Alsószölnök",
            "value": "Alsószölnök"
        },
        {
            "label": "Alsószuha",
            "value": "Alsószuha"
        },
        {
            "label": "Alsótelekes",
            "value": "Alsótelekes"
        },
        {
            "label": "Alsótold",
            "value": "Alsótold"
        },
        {
            "label": "Alsóújlak",
            "value": "Alsóújlak"
        },
        {
            "label": "Alsóvadász",
            "value": "Alsóvadász"
        },
        {
            "label": "Alsózsolca",
            "value": "Alsózsolca"
        },
        {
            "label": "Ambrózfalva",
            "value": "Ambrózfalva"
        },
        {
            "label": "Anarcs",
            "value": "Anarcs"
        },
        {
            "label": "Andocs",
            "value": "Andocs"
        },
        {
            "label": "Andornaktálya",
            "value": "Andornaktálya"
        },
        {
            "label": "Andrásfa",
            "value": "Andrásfa"
        },
        {
            "label": "Annavölgy",
            "value": "Annavölgy"
        },
        {
            "label": "Apácatorna",
            "value": "Apácatorna"
        },
        {
            "label": "Apagy",
            "value": "Apagy"
        },
        {
            "label": "Apaj",
            "value": "Apaj"
        },
        {
            "label": "Aparhant",
            "value": "Aparhant"
        },
        {
            "label": "Apátfalva",
            "value": "Apátfalva"
        },
        {
            "label": "Apátistvánfalva",
            "value": "Apátistvánfalva"
        },
        {
            "label": "Apátvarasd",
            "value": "Apátvarasd"
        },
        {
            "label": "Apc",
            "value": "Apc"
        },
        {
            "label": "Áporka",
            "value": "Áporka"
        },
        {
            "label": "Apostag",
            "value": "Apostag"
        },
        {
            "label": "Aranyosapáti",
            "value": "Aranyosapáti"
        },
        {
            "label": "Aranyosgadány",
            "value": "Aranyosgadány"
        },
        {
            "label": "Arka",
            "value": "Arka"
        },
        {
            "label": "Arló",
            "value": "Arló"
        },
        {
            "label": "Arnót",
            "value": "Arnót"
        },
        {
            "label": "Ároktő",
            "value": "Ároktő"
        },
        {
            "label": "Árpádhalom",
            "value": "Árpádhalom"
        },
        {
            "label": "Árpás",
            "value": "Árpás"
        },
        {
            "label": "Ártánd",
            "value": "Ártánd"
        },
        {
            "label": "Ásotthalom",
            "value": "Ásotthalom"
        },
        {
            "label": "Ásványráró",
            "value": "Ásványráró"
        },
        {
            "label": "Aszaló",
            "value": "Aszaló"
        },
        {
            "label": "Ászár",
            "value": "Ászár"
        },
        {
            "label": "Aszód",
            "value": "Aszód"
        },
        {
            "label": "Aszófő",
            "value": "Aszófő"
        },
        {
            "label": "Áta",
            "value": "Áta"
        },
        {
            "label": "Átány",
            "value": "Átány"
        },
        {
            "label": "Atkár",
            "value": "Atkár"
        },
        {
            "label": "Attala",
            "value": "Attala"
        },
        {
            "label": "Babarc",
            "value": "Babarc"
        },
        {
            "label": "Babarcszőlős",
            "value": "Babarcszőlős"
        },
        {
            "label": "Babócsa",
            "value": "Babócsa"
        },
        {
            "label": "Bábolna",
            "value": "Bábolna"
        },
        {
            "label": "Bábonymegyer",
            "value": "Bábonymegyer"
        },
        {
            "label": "Babosdöbréte",
            "value": "Babosdöbréte"
        },
        {
            "label": "Babót",
            "value": "Babót"
        },
        {
            "label": "Bácsalmás",
            "value": "Bácsalmás"
        },
        {
            "label": "Bácsbokod",
            "value": "Bácsbokod"
        },
        {
            "label": "Bácsborsód",
            "value": "Bácsborsód"
        },
        {
            "label": "Bácsszentgyörgy",
            "value": "Bácsszentgyörgy"
        },
        {
            "label": "Bácsszőlős",
            "value": "Bácsszőlős"
        },
        {
            "label": "Badacsonytomaj",
            "value": "Badacsonytomaj"
        },
        {
            "label": "Badacsonytördemic",
            "value": "Badacsonytördemic"
        },
        {
            "label": "Bag",
            "value": "Bag"
        },
        {
            "label": "Bagamér",
            "value": "Bagamér"
        },
        {
            "label": "Baglad",
            "value": "Baglad"
        },
        {
            "label": "Bagod",
            "value": "Bagod"
        },
        {
            "label": "Bágyogszovát",
            "value": "Bágyogszovát"
        },
        {
            "label": "Baj",
            "value": "Baj"
        },
        {
            "label": "Baja",
            "value": "Baja"
        },
        {
            "label": "Bajánsenye",
            "value": "Bajánsenye"
        },
        {
            "label": "Bajna",
            "value": "Bajna"
        },
        {
            "label": "Bajót",
            "value": "Bajót"
        },
        {
            "label": "Bak",
            "value": "Bak"
        },
        {
            "label": "Bakháza",
            "value": "Bakháza"
        },
        {
            "label": "Bakóca",
            "value": "Bakóca"
        },
        {
            "label": "Bakonszeg",
            "value": "Bakonszeg"
        },
        {
            "label": "Bakonya",
            "value": "Bakonya"
        },
        {
            "label": "Bakonybánk",
            "value": "Bakonybánk"
        },
        {
            "label": "Bakonybél",
            "value": "Bakonybél"
        },
        {
            "label": "Bakonycsernye",
            "value": "Bakonycsernye"
        },
        {
            "label": "Bakonygyirót",
            "value": "Bakonygyirót"
        },
        {
            "label": "Bakonyjákó",
            "value": "Bakonyjákó"
        },
        {
            "label": "Bakonykoppány",
            "value": "Bakonykoppány"
        },
        {
            "label": "Bakonykúti",
            "value": "Bakonykúti"
        },
        {
            "label": "Bakonynána",
            "value": "Bakonynána"
        },
        {
            "label": "Bakonyoszlop",
            "value": "Bakonyoszlop"
        },
        {
            "label": "Bakonypéterd",
            "value": "Bakonypéterd"
        },
        {
            "label": "Bakonypölöske",
            "value": "Bakonypölöske"
        },
        {
            "label": "Bakonyság",
            "value": "Bakonyság"
        },
        {
            "label": "Bakonysárkány",
            "value": "Bakonysárkány"
        },
        {
            "label": "Bakonyszentiván",
            "value": "Bakonyszentiván"
        },
        {
            "label": "Bakonyszentkirály",
            "value": "Bakonyszentkirály"
        },
        {
            "label": "Bakonyszentlászló",
            "value": "Bakonyszentlászló"
        },
        {
            "label": "Bakonyszombathely",
            "value": "Bakonyszombathely"
        },
        {
            "label": "Bakonyszücs",
            "value": "Bakonyszücs"
        },
        {
            "label": "Bakonytamási",
            "value": "Bakonytamási"
        },
        {
            "label": "Baks",
            "value": "Baks"
        },
        {
            "label": "Baksa",
            "value": "Baksa"
        },
        {
            "label": "Baktakék",
            "value": "Baktakék"
        },
        {
            "label": "Baktalórántháza",
            "value": "Baktalórántháza"
        },
        {
            "label": "Baktüttös",
            "value": "Baktüttös"
        },
        {
            "label": "Balajt",
            "value": "Balajt"
        },
        {
            "label": "Balassagyarmat",
            "value": "Balassagyarmat"
        },
        {
            "label": "Balástya",
            "value": "Balástya"
        },
        {
            "label": "Balaton",
            "value": "Balaton"
        },
        {
            "label": "Balatonakali",
            "value": "Balatonakali"
        },
        {
            "label": "Balatonalmádi",
            "value": "Balatonalmádi"
        },
        {
            "label": "Balatonberény",
            "value": "Balatonberény"
        },
        {
            "label": "Balatonboglár",
            "value": "Balatonboglár"
        },
        {
            "label": "Balatoncsicsó",
            "value": "Balatoncsicsó"
        },
        {
            "label": "Balatonederics",
            "value": "Balatonederics"
        },
        {
            "label": "Balatonendréd",
            "value": "Balatonendréd"
        },
        {
            "label": "Balatonfenyves",
            "value": "Balatonfenyves"
        },
        {
            "label": "Balatonfőkajár",
            "value": "Balatonfőkajár"
        },
        {
            "label": "Balatonföldvár",
            "value": "Balatonföldvár"
        },
        {
            "label": "Balatonfüred",
            "value": "Balatonfüred"
        },
        {
            "label": "Balatonfűzfő",
            "value": "Balatonfűzfő"
        },
        {
            "label": "Balatongyörök",
            "value": "Balatongyörök"
        },
        {
            "label": "Balatonhenye",
            "value": "Balatonhenye"
        },
        {
            "label": "Balatonkenese",
            "value": "Balatonkenese"
        },
        {
            "label": "Balatonkeresztúr",
            "value": "Balatonkeresztúr"
        },
        {
            "label": "Balatonlelle",
            "value": "Balatonlelle"
        },
        {
            "label": "Balatonmagyaród",
            "value": "Balatonmagyaród"
        },
        {
            "label": "Balatonmáriafürdő",
            "value": "Balatonmáriafürdő"
        },
        {
            "label": "Balatonőszöd",
            "value": "Balatonőszöd"
        },
        {
            "label": "Balatonrendes",
            "value": "Balatonrendes"
        },
        {
            "label": "Balatonszabadi",
            "value": "Balatonszabadi"
        },
        {
            "label": "Balatonszárszó",
            "value": "Balatonszárszó"
        },
        {
            "label": "Balatonszemes",
            "value": "Balatonszemes"
        },
        {
            "label": "Balatonszentgyörgy",
            "value": "Balatonszentgyörgy"
        },
        {
            "label": "Balatonszepezd",
            "value": "Balatonszepezd"
        },
        {
            "label": "Balatonszőlős",
            "value": "Balatonszőlős"
        },
        {
            "label": "Balatonudvari",
            "value": "Balatonudvari"
        },
        {
            "label": "Balatonújlak",
            "value": "Balatonújlak"
        },
        {
            "label": "Balatonvilágos",
            "value": "Balatonvilágos"
        },
        {
            "label": "Balinka",
            "value": "Balinka"
        },
        {
            "label": "Balkány",
            "value": "Balkány"
        },
        {
            "label": "Ballószög",
            "value": "Ballószög"
        },
        {
            "label": "Balmazújváros",
            "value": "Balmazújváros"
        },
        {
            "label": "Balogunyom",
            "value": "Balogunyom"
        },
        {
            "label": "Balotaszállás",
            "value": "Balotaszállás"
        },
        {
            "label": "Balsa",
            "value": "Balsa"
        },
        {
            "label": "Bálványos",
            "value": "Bálványos"
        },
        {
            "label": "Bana",
            "value": "Bana"
        },
        {
            "label": "Bánd",
            "value": "Bánd"
        },
        {
            "label": "Bánfa",
            "value": "Bánfa"
        },
        {
            "label": "Bánhorváti",
            "value": "Bánhorváti"
        },
        {
            "label": "Bánk",
            "value": "Bánk"
        },
        {
            "label": "Bánokszentgyörgy",
            "value": "Bánokszentgyörgy"
        },
        {
            "label": "Bánréve",
            "value": "Bánréve"
        },
        {
            "label": "Bár",
            "value": "Bár"
        },
        {
            "label": "Barabás",
            "value": "Barabás"
        },
        {
            "label": "Baracs",
            "value": "Baracs"
        },
        {
            "label": "Baracska",
            "value": "Baracska"
        },
        {
            "label": "Báránd",
            "value": "Báránd"
        },
        {
            "label": "Baranyahídvég",
            "value": "Baranyahídvég"
        },
        {
            "label": "Baranyajenő",
            "value": "Baranyajenő"
        },
        {
            "label": "Baranyaszentgyörgy",
            "value": "Baranyaszentgyörgy"
        },
        {
            "label": "Barbacs",
            "value": "Barbacs"
        },
        {
            "label": "Barcs",
            "value": "Barcs"
        },
        {
            "label": "Bárdudvarnok",
            "value": "Bárdudvarnok"
        },
        {
            "label": "Barlahida",
            "value": "Barlahida"
        },
        {
            "label": "Bárna",
            "value": "Bárna"
        },
        {
            "label": "Barnag",
            "value": "Barnag"
        },
        {
            "label": "Bársonyos",
            "value": "Bársonyos"
        },
        {
            "label": "Basal",
            "value": "Basal"
        },
        {
            "label": "Baskó",
            "value": "Baskó"
        },
        {
            "label": "Báta",
            "value": "Báta"
        },
        {
            "label": "Bátaapáti",
            "value": "Bátaapáti"
        },
        {
            "label": "Bátaszék",
            "value": "Bátaszék"
        },
        {
            "label": "Baté",
            "value": "Baté"
        },
        {
            "label": "Bátmonostor",
            "value": "Bátmonostor"
        },
        {
            "label": "Bátonyterenye",
            "value": "Bátonyterenye"
        },
        {
            "label": "Bátor",
            "value": "Bátor"
        },
        {
            "label": "Bátorliget",
            "value": "Bátorliget"
        },
        {
            "label": "Battonya",
            "value": "Battonya"
        },
        {
            "label": "Bátya",
            "value": "Bátya"
        },
        {
            "label": "Batyk",
            "value": "Batyk"
        },
        {
            "label": "Bázakerettye",
            "value": "Bázakerettye"
        },
        {
            "label": "Bazsi",
            "value": "Bazsi"
        },
        {
            "label": "Béb",
            "value": "Béb"
        },
        {
            "label": "Becsehely",
            "value": "Becsehely"
        },
        {
            "label": "Becske",
            "value": "Becske"
        },
        {
            "label": "Becskeháza",
            "value": "Becskeháza"
        },
        {
            "label": "Becsvölgye",
            "value": "Becsvölgye"
        },
        {
            "label": "Bedegkér",
            "value": "Bedegkér"
        },
        {
            "label": "Bedő",
            "value": "Bedő"
        },
        {
            "label": "Bejcgyertyános",
            "value": "Bejcgyertyános"
        },
        {
            "label": "Békás",
            "value": "Békás"
        },
        {
            "label": "Bekecs",
            "value": "Bekecs"
        },
        {
            "label": "Békés",
            "value": "Békés"
        },
        {
            "label": "Békéscsaba",
            "value": "Békéscsaba"
        },
        {
            "label": "Békéssámson",
            "value": "Békéssámson"
        },
        {
            "label": "Békésszentandrás",
            "value": "Békésszentandrás"
        },
        {
            "label": "Bekölce",
            "value": "Bekölce"
        },
        {
            "label": "Bélapátfalva",
            "value": "Bélapátfalva"
        },
        {
            "label": "Bélavár",
            "value": "Bélavár"
        },
        {
            "label": "Belecska",
            "value": "Belecska"
        },
        {
            "label": "Beled",
            "value": "Beled"
        },
        {
            "label": "Beleg",
            "value": "Beleg"
        },
        {
            "label": "Belezna",
            "value": "Belezna"
        },
        {
            "label": "Bélmegyer",
            "value": "Bélmegyer"
        },
        {
            "label": "Beloiannisz",
            "value": "Beloiannisz"
        },
        {
            "label": "Belsősárd",
            "value": "Belsősárd"
        },
        {
            "label": "Belvárdgyula",
            "value": "Belvárdgyula"
        },
        {
            "label": "Benk",
            "value": "Benk"
        },
        {
            "label": "Bénye",
            "value": "Bénye"
        },
        {
            "label": "Bér",
            "value": "Bér"
        },
        {
            "label": "Bérbaltavár",
            "value": "Bérbaltavár"
        },
        {
            "label": "Bercel",
            "value": "Bercel"
        },
        {
            "label": "Beregdaróc",
            "value": "Beregdaróc"
        },
        {
            "label": "Beregsurány",
            "value": "Beregsurány"
        },
        {
            "label": "Berekböszörmény",
            "value": "Berekböszörmény"
        },
        {
            "label": "Berekfürdő",
            "value": "Berekfürdő"
        },
        {
            "label": "Beremend",
            "value": "Beremend"
        },
        {
            "label": "Beret",
            "value": "Beret"
        },
        {
            "label": "Berettyóújfalu",
            "value": "Berettyóújfalu"
        },
        {
            "label": "Berhida",
            "value": "Berhida"
        },
        {
            "label": "Berkenye",
            "value": "Berkenye"
        },
        {
            "label": "Berkesd",
            "value": "Berkesd"
        },
        {
            "label": "Berkesz",
            "value": "Berkesz"
        },
        {
            "label": "Bernecebaráti",
            "value": "Bernecebaráti"
        },
        {
            "label": "Berzék",
            "value": "Berzék"
        },
        {
            "label": "Berzence",
            "value": "Berzence"
        },
        {
            "label": "Besence",
            "value": "Besence"
        },
        {
            "label": "Besenyőd",
            "value": "Besenyőd"
        },
        {
            "label": "Besenyőtelek",
            "value": "Besenyőtelek"
        },
        {
            "label": "Besenyszög",
            "value": "Besenyszög"
        },
        {
            "label": "Besnyő",
            "value": "Besnyő"
        },
        {
            "label": "Beszterec",
            "value": "Beszterec"
        },
        {
            "label": "Bezedek",
            "value": "Bezedek"
        },
        {
            "label": "Bezenye",
            "value": "Bezenye"
        },
        {
            "label": "Bezeréd",
            "value": "Bezeréd"
        },
        {
            "label": "Bezi",
            "value": "Bezi"
        },
        {
            "label": "Biatorbágy",
            "value": "Biatorbágy"
        },
        {
            "label": "Bicsérd",
            "value": "Bicsérd"
        },
        {
            "label": "Bicske",
            "value": "Bicske"
        },
        {
            "label": "Bihardancsháza",
            "value": "Bihardancsháza"
        },
        {
            "label": "Biharkeresztes",
            "value": "Biharkeresztes"
        },
        {
            "label": "Biharnagybajom",
            "value": "Biharnagybajom"
        },
        {
            "label": "Bihartorda",
            "value": "Bihartorda"
        },
        {
            "label": "Biharugra",
            "value": "Biharugra"
        },
        {
            "label": "Bikács",
            "value": "Bikács"
        },
        {
            "label": "Bikal",
            "value": "Bikal"
        },
        {
            "label": "Biri",
            "value": "Biri"
        },
        {
            "label": "Birján",
            "value": "Birján"
        },
        {
            "label": "Bisse",
            "value": "Bisse"
        },
        {
            "label": "Boba",
            "value": "Boba"
        },
        {
            "label": "Bocfölde",
            "value": "Bocfölde"
        },
        {
            "label": "Boconád",
            "value": "Boconád"
        },
        {
            "label": "Bócsa",
            "value": "Bócsa"
        },
        {
            "label": "Bocska",
            "value": "Bocska"
        },
        {
            "label": "Bocskaikert",
            "value": "Bocskaikert"
        },
        {
            "label": "Boda",
            "value": "Boda"
        },
        {
            "label": "Bodajk",
            "value": "Bodajk"
        },
        {
            "label": "Bodmér",
            "value": "Bodmér"
        },
        {
            "label": "Bodolyabér",
            "value": "Bodolyabér"
        },
        {
            "label": "Bodonhely",
            "value": "Bodonhely"
        },
        {
            "label": "Bodony",
            "value": "Bodony"
        },
        {
            "label": "Bodorfa",
            "value": "Bodorfa"
        },
        {
            "label": "Bodrog",
            "value": "Bodrog"
        },
        {
            "label": "Bodroghalom",
            "value": "Bodroghalom"
        },
        {
            "label": "Bodrogkeresztúr",
            "value": "Bodrogkeresztúr"
        },
        {
            "label": "Bodrogkisfalud",
            "value": "Bodrogkisfalud"
        },
        {
            "label": "Bodrogolaszi",
            "value": "Bodrogolaszi"
        },
        {
            "label": "Bódvalenke",
            "value": "Bódvalenke"
        },
        {
            "label": "Bódvarákó",
            "value": "Bódvarákó"
        },
        {
            "label": "Bódvaszilas",
            "value": "Bódvaszilas"
        },
        {
            "label": "Bogács",
            "value": "Bogács"
        },
        {
            "label": "Bogád",
            "value": "Bogád"
        },
        {
            "label": "Bogádmindszent",
            "value": "Bogádmindszent"
        },
        {
            "label": "Bogdása",
            "value": "Bogdása"
        },
        {
            "label": "Bogyiszló",
            "value": "Bogyiszló"
        },
        {
            "label": "Bogyoszló",
            "value": "Bogyoszló"
        },
        {
            "label": "Bojt",
            "value": "Bojt"
        },
        {
            "label": "Bókaháza",
            "value": "Bókaháza"
        },
        {
            "label": "Bokod",
            "value": "Bokod"
        },
        {
            "label": "Bokor",
            "value": "Bokor"
        },
        {
            "label": "Boldog",
            "value": "Boldog"
        },
        {
            "label": "Boldogasszonyfa",
            "value": "Boldogasszonyfa"
        },
        {
            "label": "Boldogkőújfalu",
            "value": "Boldogkőújfalu"
        },
        {
            "label": "Boldogkőváralja",
            "value": "Boldogkőváralja"
        },
        {
            "label": "Boldva",
            "value": "Boldva"
        },
        {
            "label": "Bolhás",
            "value": "Bolhás"
        },
        {
            "label": "Bolhó",
            "value": "Bolhó"
        },
        {
            "label": "Bóly",
            "value": "Bóly"
        },
        {
            "label": "Boncodfölde",
            "value": "Boncodfölde"
        },
        {
            "label": "Bonnya",
            "value": "Bonnya"
        },
        {
            "label": "Bonyhád",
            "value": "Bonyhád"
        },
        {
            "label": "Bonyhádvarasd",
            "value": "Bonyhádvarasd"
        },
        {
            "label": "Bordány",
            "value": "Bordány"
        },
        {
            "label": "Borgáta",
            "value": "Borgáta"
        },
        {
            "label": "Borjád",
            "value": "Borjád"
        },
        {
            "label": "Borota",
            "value": "Borota"
        },
        {
            "label": "Borsfa",
            "value": "Borsfa"
        },
        {
            "label": "Borsodbóta",
            "value": "Borsodbóta"
        },
        {
            "label": "Borsodgeszt",
            "value": "Borsodgeszt"
        },
        {
            "label": "Borsodivánka",
            "value": "Borsodivánka"
        },
        {
            "label": "Borsodnádasd",
            "value": "Borsodnádasd"
        },
        {
            "label": "Borsodszentgyörgy",
            "value": "Borsodszentgyörgy"
        },
        {
            "label": "Borsodszirák",
            "value": "Borsodszirák"
        },
        {
            "label": "Borsosberény",
            "value": "Borsosberény"
        },
        {
            "label": "Borszörcsök",
            "value": "Borszörcsök"
        },
        {
            "label": "Borzavár",
            "value": "Borzavár"
        },
        {
            "label": "Bosta",
            "value": "Bosta"
        },
        {
            "label": "Botpalád",
            "value": "Botpalád"
        },
        {
            "label": "Botykapeterd",
            "value": "Botykapeterd"
        },
        {
            "label": "Bozsok",
            "value": "Bozsok"
        },
        {
            "label": "Bózsva",
            "value": "Bózsva"
        },
        {
            "label": "Bozzai",
            "value": "Bozzai"
        },
        {
            "label": "Bő",
            "value": "Bő"
        },
        {
            "label": "Bőcs",
            "value": "Bőcs"
        },
        {
            "label": "Böde",
            "value": "Böde"
        },
        {
            "label": "Bödeháza",
            "value": "Bödeháza"
        },
        {
            "label": "Bögöt",
            "value": "Bögöt"
        },
        {
            "label": "Bögöte",
            "value": "Bögöte"
        },
        {
            "label": "Böhönye",
            "value": "Böhönye"
        },
        {
            "label": "Bököny",
            "value": "Bököny"
        },
        {
            "label": "Bölcske",
            "value": "Bölcske"
        },
        {
            "label": "Bőny",
            "value": "Bőny"
        },
        {
            "label": "Börcs",
            "value": "Börcs"
        },
        {
            "label": "Börzönce",
            "value": "Börzönce"
        },
        {
            "label": "Bősárkány",
            "value": "Bősárkány"
        },
        {
            "label": "Bőszénfa",
            "value": "Bőszénfa"
        },
        {
            "label": "Bucsa",
            "value": "Bucsa"
        },
        {
            "label": "Bucsu",
            "value": "Bucsu"
        },
        {
            "label": "Búcsúszentlászló",
            "value": "Búcsúszentlászló"
        },
        {
            "label": "Bucsuta",
            "value": "Bucsuta"
        },
        {
            "label": "Budajenő",
            "value": "Budajenő"
        },
        {
            "label": "Budakalász",
            "value": "Budakalász"
        },
        {
            "label": "Budakeszi",
            "value": "Budakeszi"
        },
        {
            "label": "Budaörs",
            "value": "Budaörs"
        },
        {
            "label": "Budapest",
            "value": "Budapest"
        },
        {
            "label": "Bugac",
            "value": "Bugac"
        },
        {
            "label": "Bugacpusztaháza",
            "value": "Bugacpusztaháza"
        },
        {
            "label": "Bugyi",
            "value": "Bugyi"
        },
        {
            "label": "Buj",
            "value": "Buj"
        },
        {
            "label": "Buják",
            "value": "Buják"
        },
        {
            "label": "Buzsák",
            "value": "Buzsák"
        },
        {
            "label": "Bük",
            "value": "Bük"
        },
        {
            "label": "Bükkábrány",
            "value": "Bükkábrány"
        },
        {
            "label": "Bükkaranyos",
            "value": "Bükkaranyos"
        },
        {
            "label": "Bükkmogyorósd",
            "value": "Bükkmogyorósd"
        },
        {
            "label": "Bükkösd",
            "value": "Bükkösd"
        },
        {
            "label": "Bükkszék",
            "value": "Bükkszék"
        },
        {
            "label": "Bükkszenterzsébet",
            "value": "Bükkszenterzsébet"
        },
        {
            "label": "Bükkszentkereszt",
            "value": "Bükkszentkereszt"
        },
        {
            "label": "Bükkszentmárton",
            "value": "Bükkszentmárton"
        },
        {
            "label": "Bükkzsérc",
            "value": "Bükkzsérc"
        },
        {
            "label": "Bürüs",
            "value": "Bürüs"
        },
        {
            "label": "Büssü",
            "value": "Büssü"
        },
        {
            "label": "Büttös",
            "value": "Büttös"
        },
        {
            "label": "Cák",
            "value": "Cák"
        },
        {
            "label": "Cakóháza",
            "value": "Cakóháza"
        },
        {
            "label": "Cece",
            "value": "Cece"
        },
        {
            "label": "Cégénydányád",
            "value": "Cégénydányád"
        },
        {
            "label": "Cegléd",
            "value": "Cegléd"
        },
        {
            "label": "Ceglédbercel",
            "value": "Ceglédbercel"
        },
        {
            "label": "Celldömölk",
            "value": "Celldömölk"
        },
        {
            "label": "Cered",
            "value": "Cered"
        },
        {
            "label": "Chernelházadamonya",
            "value": "Chernelházadamonya"
        },
        {
            "label": "Cibakháza",
            "value": "Cibakháza"
        },
        {
            "label": "Cigánd",
            "value": "Cigánd"
        },
        {
            "label": "Cikó",
            "value": "Cikó"
        },
        {
            "label": "Cirák",
            "value": "Cirák"
        },
        {
            "label": "Csabacsűd",
            "value": "Csabacsűd"
        },
        {
            "label": "Csabaszabadi",
            "value": "Csabaszabadi"
        },
        {
            "label": "Csabdi",
            "value": "Csabdi"
        },
        {
            "label": "Csabrendek",
            "value": "Csabrendek"
        },
        {
            "label": "Csáfordjánosfa",
            "value": "Csáfordjánosfa"
        },
        {
            "label": "Csaholc",
            "value": "Csaholc"
        },
        {
            "label": "Csajág",
            "value": "Csajág"
        },
        {
            "label": "Csákány",
            "value": "Csákány"
        },
        {
            "label": "Csákánydoroszló",
            "value": "Csákánydoroszló"
        },
        {
            "label": "Csákberény",
            "value": "Csákberény"
        },
        {
            "label": "Csákvár",
            "value": "Csákvár"
        },
        {
            "label": "Csanádalberti",
            "value": "Csanádalberti"
        },
        {
            "label": "Csanádapáca",
            "value": "Csanádapáca"
        },
        {
            "label": "Csanádpalota",
            "value": "Csanádpalota"
        },
        {
            "label": "Csánig",
            "value": "Csánig"
        },
        {
            "label": "Csány",
            "value": "Csány"
        },
        {
            "label": "Csányoszró",
            "value": "Csányoszró"
        },
        {
            "label": "Csanytelek",
            "value": "Csanytelek"
        },
        {
            "label": "Csapi",
            "value": "Csapi"
        },
        {
            "label": "Csapod",
            "value": "Csapod"
        },
        {
            "label": "Csárdaszállás",
            "value": "Csárdaszállás"
        },
        {
            "label": "Csarnóta",
            "value": "Csarnóta"
        },
        {
            "label": "Csaroda",
            "value": "Csaroda"
        },
        {
            "label": "Császár",
            "value": "Császár"
        },
        {
            "label": "Császártöltés",
            "value": "Császártöltés"
        },
        {
            "label": "Császló",
            "value": "Császló"
        },
        {
            "label": "Csátalja",
            "value": "Csátalja"
        },
        {
            "label": "Csatár",
            "value": "Csatár"
        },
        {
            "label": "Csataszög",
            "value": "Csataszög"
        },
        {
            "label": "Csatka",
            "value": "Csatka"
        },
        {
            "label": "Csávoly",
            "value": "Csávoly"
        },
        {
            "label": "Csebény",
            "value": "Csebény"
        },
        {
            "label": "Csécse",
            "value": "Csécse"
        },
        {
            "label": "Csegöld",
            "value": "Csegöld"
        },
        {
            "label": "Csehbánya",
            "value": "Csehbánya"
        },
        {
            "label": "Csehi",
            "value": "Csehi"
        },
        {
            "label": "Csehimindszent",
            "value": "Csehimindszent"
        },
        {
            "label": "Csém",
            "value": "Csém"
        },
        {
            "label": "Csemő",
            "value": "Csemő"
        },
        {
            "label": "Csempeszkopács",
            "value": "Csempeszkopács"
        },
        {
            "label": "Csengele",
            "value": "Csengele"
        },
        {
            "label": "Csenger",
            "value": "Csenger"
        },
        {
            "label": "Csengersima",
            "value": "Csengersima"
        },
        {
            "label": "Csengerújfalu",
            "value": "Csengerújfalu"
        },
        {
            "label": "Csengőd",
            "value": "Csengőd"
        },
        {
            "label": "Csénye",
            "value": "Csénye"
        },
        {
            "label": "Csenyéte",
            "value": "Csenyéte"
        },
        {
            "label": "Csép",
            "value": "Csép"
        },
        {
            "label": "Csépa",
            "value": "Csépa"
        },
        {
            "label": "Csepreg",
            "value": "Csepreg"
        },
        {
            "label": "Csér",
            "value": "Csér"
        },
        {
            "label": "Cserdi",
            "value": "Cserdi"
        },
        {
            "label": "Cserénfa",
            "value": "Cserénfa"
        },
        {
            "label": "Cserépfalu",
            "value": "Cserépfalu"
        },
        {
            "label": "Cserépváralja",
            "value": "Cserépváralja"
        },
        {
            "label": "Cserháthaláp",
            "value": "Cserháthaláp"
        },
        {
            "label": "Cserhátsurány",
            "value": "Cserhátsurány"
        },
        {
            "label": "Cserhátszentiván",
            "value": "Cserhátszentiván"
        },
        {
            "label": "Cserkeszőlő",
            "value": "Cserkeszőlő"
        },
        {
            "label": "Cserkút",
            "value": "Cserkút"
        },
        {
            "label": "Csernely",
            "value": "Csernely"
        },
        {
            "label": "Cserszegtomaj",
            "value": "Cserszegtomaj"
        },
        {
            "label": "Csertalakos",
            "value": "Csertalakos"
        },
        {
            "label": "Csertő",
            "value": "Csertő"
        },
        {
            "label": "Csesznek",
            "value": "Csesznek"
        },
        {
            "label": "Csesztreg",
            "value": "Csesztreg"
        },
        {
            "label": "Csesztve",
            "value": "Csesztve"
        },
        {
            "label": "Csetény",
            "value": "Csetény"
        },
        {
            "label": "Csévharaszt",
            "value": "Csévharaszt"
        },
        {
            "label": "Csibrák",
            "value": "Csibrák"
        },
        {
            "label": "Csikéria",
            "value": "Csikéria"
        },
        {
            "label": "Csikóstőttős",
            "value": "Csikóstőttős"
        },
        {
            "label": "Csikvánd",
            "value": "Csikvánd"
        },
        {
            "label": "Csincse",
            "value": "Csincse"
        },
        {
            "label": "Csipkerek",
            "value": "Csipkerek"
        },
        {
            "label": "Csitár",
            "value": "Csitár"
        },
        {
            "label": "Csobád",
            "value": "Csobád"
        },
        {
            "label": "Csobaj",
            "value": "Csobaj"
        },
        {
            "label": "Csobánka",
            "value": "Csobánka"
        },
        {
            "label": "Csókakő",
            "value": "Csókakő"
        },
        {
            "label": "Csokonyavisonta",
            "value": "Csokonyavisonta"
        },
        {
            "label": "Csokvaomány",
            "value": "Csokvaomány"
        },
        {
            "label": "Csolnok",
            "value": "Csolnok"
        },
        {
            "label": "Csólyospálos",
            "value": "Csólyospálos"
        },
        {
            "label": "Csoma",
            "value": "Csoma"
        },
        {
            "label": "Csomád",
            "value": "Csomád"
        },
        {
            "label": "Csombárd",
            "value": "Csombárd"
        },
        {
            "label": "Csongrád",
            "value": "Csongrád"
        },
        {
            "label": "Csonkahegyhát",
            "value": "Csonkahegyhát"
        },
        {
            "label": "Csonkamindszent",
            "value": "Csonkamindszent"
        },
        {
            "label": "Csopak",
            "value": "Csopak"
        },
        {
            "label": "Csór",
            "value": "Csór"
        },
        {
            "label": "Csorna",
            "value": "Csorna"
        },
        {
            "label": "Csorvás",
            "value": "Csorvás"
        },
        {
            "label": "Csót",
            "value": "Csót"
        },
        {
            "label": "Csöde",
            "value": "Csöde"
        },
        {
            "label": "Csögle",
            "value": "Csögle"
        },
        {
            "label": "Csökmő",
            "value": "Csökmő"
        },
        {
            "label": "Csököly",
            "value": "Csököly"
        },
        {
            "label": "Csömend",
            "value": "Csömend"
        },
        {
            "label": "Csömödér",
            "value": "Csömödér"
        },
        {
            "label": "Csömör",
            "value": "Csömör"
        },
        {
            "label": "Csönge",
            "value": "Csönge"
        },
        {
            "label": "Csörnyeföld",
            "value": "Csörnyeföld"
        },
        {
            "label": "Csörög",
            "value": "Csörög"
        },
        {
            "label": "Csörötnek",
            "value": "Csörötnek"
        },
        {
            "label": "Csősz",
            "value": "Csősz"
        },
        {
            "label": "Csővár",
            "value": "Csővár"
        },
        {
            "label": "Csurgó",
            "value": "Csurgó"
        },
        {
            "label": "Csurgónagymarton",
            "value": "Csurgónagymarton"
        },
        {
            "label": "Cún",
            "value": "Cún"
        },
        {
            "label": "Dabas",
            "value": "Dabas"
        },
        {
            "label": "Dabronc",
            "value": "Dabronc"
        },
        {
            "label": "Dabrony",
            "value": "Dabrony"
        },
        {
            "label": "Dad",
            "value": "Dad"
        },
        {
            "label": "Dág",
            "value": "Dág"
        },
        {
            "label": "Dáka",
            "value": "Dáka"
        },
        {
            "label": "Dalmand",
            "value": "Dalmand"
        },
        {
            "label": "Damak",
            "value": "Damak"
        },
        {
            "label": "Dámóc",
            "value": "Dámóc"
        },
        {
            "label": "Dánszentmiklós",
            "value": "Dánszentmiklós"
        },
        {
            "label": "Dány",
            "value": "Dány"
        },
        {
            "label": "Daraboshegy",
            "value": "Daraboshegy"
        },
        {
            "label": "Darány",
            "value": "Darány"
        },
        {
            "label": "Darnó",
            "value": "Darnó"
        },
        {
            "label": "Darnózseli",
            "value": "Darnózseli"
        },
        {
            "label": "Daruszentmiklós",
            "value": "Daruszentmiklós"
        },
        {
            "label": "Darvas",
            "value": "Darvas"
        },
        {
            "label": "Dávod",
            "value": "Dávod"
        },
        {
            "label": "Debercsény",
            "value": "Debercsény"
        },
        {
            "label": "Debrecen",
            "value": "Debrecen"
        },
        {
            "label": "Debrecen-Józsa",
            "value": "Debrecen-Józsa"
        },
        {
            "label": "Debréte",
            "value": "Debréte"
        },
        {
            "label": "Decs",
            "value": "Decs"
        },
        {
            "label": "Dédestapolcsány",
            "value": "Dédestapolcsány"
        },
        {
            "label": "Dég",
            "value": "Dég"
        },
        {
            "label": "Dejtár",
            "value": "Dejtár"
        },
        {
            "label": "Délegyháza",
            "value": "Délegyháza"
        },
        {
            "label": "Demecser",
            "value": "Demecser"
        },
        {
            "label": "Demjén",
            "value": "Demjén"
        },
        {
            "label": "Dencsháza",
            "value": "Dencsháza"
        },
        {
            "label": "Dénesfa",
            "value": "Dénesfa"
        },
        {
            "label": "Derecske",
            "value": "Derecske"
        },
        {
            "label": "Derekegyház",
            "value": "Derekegyház"
        },
        {
            "label": "Deszk",
            "value": "Deszk"
        },
        {
            "label": "Detek",
            "value": "Detek"
        },
        {
            "label": "Detk",
            "value": "Detk"
        },
        {
            "label": "Dévaványa",
            "value": "Dévaványa"
        },
        {
            "label": "Devecser",
            "value": "Devecser"
        },
        {
            "label": "Dinnyeberki",
            "value": "Dinnyeberki"
        },
        {
            "label": "Diósberény",
            "value": "Diósberény"
        },
        {
            "label": "Diósd",
            "value": "Diósd"
        },
        {
            "label": "Diósjenő",
            "value": "Diósjenő"
        },
        {
            "label": "Dióskál",
            "value": "Dióskál"
        },
        {
            "label": "Diósviszló",
            "value": "Diósviszló"
        },
        {
            "label": "Doba",
            "value": "Doba"
        },
        {
            "label": "Dobogókő",
            "value": "Dobogókő"
        },
        {
            "label": "Doboz",
            "value": "Doboz"
        },
        {
            "label": "Dobri",
            "value": "Dobri"
        },
        {
            "label": "Dobronhegy",
            "value": "Dobronhegy"
        },
        {
            "label": "Dóc",
            "value": "Dóc"
        },
        {
            "label": "Domaháza",
            "value": "Domaháza"
        },
        {
            "label": "Domaszék",
            "value": "Domaszék"
        },
        {
            "label": "Dombegyház",
            "value": "Dombegyház"
        },
        {
            "label": "Dombiratos",
            "value": "Dombiratos"
        },
        {
            "label": "Dombóvár",
            "value": "Dombóvár"
        },
        {
            "label": "Dombrád",
            "value": "Dombrád"
        },
        {
            "label": "Domony",
            "value": "Domony"
        },
        {
            "label": "Domoszló",
            "value": "Domoszló"
        },
        {
            "label": "Dormánd",
            "value": "Dormánd"
        },
        {
            "label": "Dorog",
            "value": "Dorog"
        },
        {
            "label": "Dorogháza",
            "value": "Dorogháza"
        },
        {
            "label": "Dozmat",
            "value": "Dozmat"
        },
        {
            "label": "Döbörhegy",
            "value": "Döbörhegy"
        },
        {
            "label": "Döbröce",
            "value": "Döbröce"
        },
        {
            "label": "Döbrököz",
            "value": "Döbrököz"
        },
        {
            "label": "Döbrönte",
            "value": "Döbrönte"
        },
        {
            "label": "Döge",
            "value": "Döge"
        },
        {
            "label": "Dömös",
            "value": "Dömös"
        },
        {
            "label": "Dömsöd",
            "value": "Dömsöd"
        },
        {
            "label": "Dör",
            "value": "Dör"
        },
        {
            "label": "Dörgicse",
            "value": "Dörgicse"
        },
        {
            "label": "Döröske",
            "value": "Döröske"
        },
        {
            "label": "Dötk",
            "value": "Dötk"
        },
        {
            "label": "Dövény",
            "value": "Dövény"
        },
        {
            "label": "Drágszél",
            "value": "Drágszél"
        },
        {
            "label": "Drávacsehi",
            "value": "Drávacsehi"
        },
        {
            "label": "Drávacsepely",
            "value": "Drávacsepely"
        },
        {
            "label": "Drávafok",
            "value": "Drávafok"
        },
        {
            "label": "Drávagárdony",
            "value": "Drávagárdony"
        },
        {
            "label": "Drávaiványi",
            "value": "Drávaiványi"
        },
        {
            "label": "Drávakeresztúr",
            "value": "Drávakeresztúr"
        },
        {
            "label": "Drávapalkonya",
            "value": "Drávapalkonya"
        },
        {
            "label": "Drávapiski",
            "value": "Drávapiski"
        },
        {
            "label": "Drávaszabolcs",
            "value": "Drávaszabolcs"
        },
        {
            "label": "Drávaszerdahely",
            "value": "Drávaszerdahely"
        },
        {
            "label": "Drávasztára",
            "value": "Drávasztára"
        },
        {
            "label": "Drávatamási",
            "value": "Drávatamási"
        },
        {
            "label": "Drégelypalánk",
            "value": "Drégelypalánk"
        },
        {
            "label": "Dubicsány",
            "value": "Dubicsány"
        },
        {
            "label": "Dudar",
            "value": "Dudar"
        },
        {
            "label": "Duka",
            "value": "Duka"
        },
        {
            "label": "Dunaalmás",
            "value": "Dunaalmás"
        },
        {
            "label": "Dunabogdány",
            "value": "Dunabogdány"
        },
        {
            "label": "Dunaegyháza",
            "value": "Dunaegyháza"
        },
        {
            "label": "Dunafalva",
            "value": "Dunafalva"
        },
        {
            "label": "Dunaföldvár",
            "value": "Dunaföldvár"
        },
        {
            "label": "Dunaharaszti",
            "value": "Dunaharaszti"
        },
        {
            "label": "Dunakeszi",
            "value": "Dunakeszi"
        },
        {
            "label": "Dunakiliti",
            "value": "Dunakiliti"
        },
        {
            "label": "Dunapataj",
            "value": "Dunapataj"
        },
        {
            "label": "Dunaremete",
            "value": "Dunaremete"
        },
        {
            "label": "Dunaszeg",
            "value": "Dunaszeg"
        },
        {
            "label": "Dunaszekcső",
            "value": "Dunaszekcső"
        },
        {
            "label": "Dunaszentbenedek",
            "value": "Dunaszentbenedek"
        },
        {
            "label": "Dunaszentgyörgy",
            "value": "Dunaszentgyörgy"
        },
        {
            "label": "Dunaszentmiklós",
            "value": "Dunaszentmiklós"
        },
        {
            "label": "Dunaszentpál",
            "value": "Dunaszentpál"
        },
        {
            "label": "Dunasziget",
            "value": "Dunasziget"
        },
        {
            "label": "Dunatetétlen",
            "value": "Dunatetétlen"
        },
        {
            "label": "Dunaújváros",
            "value": "Dunaújváros"
        },
        {
            "label": "Dunavarsány",
            "value": "Dunavarsány"
        },
        {
            "label": "Dunavecse",
            "value": "Dunavecse"
        },
        {
            "label": "Dusnok",
            "value": "Dusnok"
        },
        {
            "label": "Dúzs",
            "value": "Dúzs"
        },
        {
            "label": "Ebergőc",
            "value": "Ebergőc"
        },
        {
            "label": "Ebes",
            "value": "Ebes"
        },
        {
            "label": "Écs",
            "value": "Écs"
        },
        {
            "label": "Ecséd",
            "value": "Ecséd"
        },
        {
            "label": "Ecseg",
            "value": "Ecseg"
        },
        {
            "label": "Ecsegfalva",
            "value": "Ecsegfalva"
        },
        {
            "label": "Ecseny",
            "value": "Ecseny"
        },
        {
            "label": "Ecser",
            "value": "Ecser"
        },
        {
            "label": "Edde",
            "value": "Edde"
        },
        {
            "label": "Edelény",
            "value": "Edelény"
        },
        {
            "label": "Edve",
            "value": "Edve"
        },
        {
            "label": "Eger",
            "value": "Eger"
        },
        {
            "label": "Egerág",
            "value": "Egerág"
        },
        {
            "label": "Egeralja",
            "value": "Egeralja"
        },
        {
            "label": "Egeraracsa",
            "value": "Egeraracsa"
        },
        {
            "label": "Egerbakta",
            "value": "Egerbakta"
        },
        {
            "label": "Egerbocs",
            "value": "Egerbocs"
        },
        {
            "label": "Egercsehi",
            "value": "Egercsehi"
        },
        {
            "label": "Egerfarmos",
            "value": "Egerfarmos"
        },
        {
            "label": "Egerlövő",
            "value": "Egerlövő"
        },
        {
            "label": "Egerszalók",
            "value": "Egerszalók"
        },
        {
            "label": "Egerszólát",
            "value": "Egerszólát"
        },
        {
            "label": "Égerszög",
            "value": "Égerszög"
        },
        {
            "label": "Egervár",
            "value": "Egervár"
        },
        {
            "label": "Egervölgy",
            "value": "Egervölgy"
        },
        {
            "label": "Egyed",
            "value": "Egyed"
        },
        {
            "label": "Egyek",
            "value": "Egyek"
        },
        {
            "label": "Egyházasdengeleg",
            "value": "Egyházasdengeleg"
        },
        {
            "label": "Egyházasfalu",
            "value": "Egyházasfalu"
        },
        {
            "label": "Egyházasgerge",
            "value": "Egyházasgerge"
        },
        {
            "label": "Egyházasharaszti",
            "value": "Egyházasharaszti"
        },
        {
            "label": "Egyházashetye",
            "value": "Egyházashetye"
        },
        {
            "label": "Egyházashollós",
            "value": "Egyházashollós"
        },
        {
            "label": "Egyházaskesző",
            "value": "Egyházaskesző"
        },
        {
            "label": "Egyházaskozár",
            "value": "Egyházaskozár"
        },
        {
            "label": "Egyházasrádóc",
            "value": "Egyházasrádóc"
        },
        {
            "label": "Elek",
            "value": "Elek"
        },
        {
            "label": "Ellend",
            "value": "Ellend"
        },
        {
            "label": "Előszállás",
            "value": "Előszállás"
        },
        {
            "label": "Emőd",
            "value": "Emőd"
        },
        {
            "label": "Encs",
            "value": "Encs"
        },
        {
            "label": "Encsencs",
            "value": "Encsencs"
        },
        {
            "label": "Endrefalva",
            "value": "Endrefalva"
        },
        {
            "label": "Endrőc",
            "value": "Endrőc"
        },
        {
            "label": "Enese",
            "value": "Enese"
        },
        {
            "label": "Enying",
            "value": "Enying"
        },
        {
            "label": "Eperjes",
            "value": "Eperjes"
        },
        {
            "label": "Eperjeske",
            "value": "Eperjeske"
        },
        {
            "label": "Eplény",
            "value": "Eplény"
        },
        {
            "label": "Epöl",
            "value": "Epöl"
        },
        {
            "label": "Ercsi",
            "value": "Ercsi"
        },
        {
            "label": "Érd",
            "value": "Érd"
        },
        {
            "label": "Erdőbénye",
            "value": "Erdőbénye"
        },
        {
            "label": "Erdőhorváti",
            "value": "Erdőhorváti"
        },
        {
            "label": "Erdőkertes",
            "value": "Erdőkertes"
        },
        {
            "label": "Erdőkövesd",
            "value": "Erdőkövesd"
        },
        {
            "label": "Erdőkürt",
            "value": "Erdőkürt"
        },
        {
            "label": "Erdősmárok",
            "value": "Erdősmárok"
        },
        {
            "label": "Erdősmecske",
            "value": "Erdősmecske"
        },
        {
            "label": "Erdőtarcsa",
            "value": "Erdőtarcsa"
        },
        {
            "label": "Erdőtelek",
            "value": "Erdőtelek"
        },
        {
            "label": "Erk",
            "value": "Erk"
        },
        {
            "label": "Érpatak",
            "value": "Érpatak"
        },
        {
            "label": "Érsekcsanád",
            "value": "Érsekcsanád"
        },
        {
            "label": "Érsekhalma",
            "value": "Érsekhalma"
        },
        {
            "label": "Érsekvadkert",
            "value": "Érsekvadkert"
        },
        {
            "label": "Értény",
            "value": "Értény"
        },
        {
            "label": "Erzsébet",
            "value": "Erzsébet"
        },
        {
            "label": "Esztár",
            "value": "Esztár"
        },
        {
            "label": "Eszteregnye",
            "value": "Eszteregnye"
        },
        {
            "label": "Esztergályhorváti",
            "value": "Esztergályhorváti"
        },
        {
            "label": "Esztergom",
            "value": "Esztergom"
        },
        {
            "label": "Ete",
            "value": "Ete"
        },
        {
            "label": "Etes",
            "value": "Etes"
        },
        {
            "label": "Etyek",
            "value": "Etyek"
        },
        {
            "label": "Fábiánháza",
            "value": "Fábiánháza"
        },
        {
            "label": "Fábiánsebestyén",
            "value": "Fábiánsebestyén"
        },
        {
            "label": "Fácánkert",
            "value": "Fácánkert"
        },
        {
            "label": "Fadd",
            "value": "Fadd"
        },
        {
            "label": "Fáj",
            "value": "Fáj"
        },
        {
            "label": "Fajsz",
            "value": "Fajsz"
        },
        {
            "label": "Fancsal",
            "value": "Fancsal"
        },
        {
            "label": "Farád",
            "value": "Farád"
        },
        {
            "label": "Farkasgyepű",
            "value": "Farkasgyepű"
        },
        {
            "label": "Farmos",
            "value": "Farmos"
        },
        {
            "label": "Fazekasboda",
            "value": "Fazekasboda"
        },
        {
            "label": "Fedémes",
            "value": "Fedémes"
        },
        {
            "label": "Fegyvernek",
            "value": "Fegyvernek"
        },
        {
            "label": "Fehérgyarmat",
            "value": "Fehérgyarmat"
        },
        {
            "label": "Fehértó",
            "value": "Fehértó"
        },
        {
            "label": "Fehérvárcsurgó",
            "value": "Fehérvárcsurgó"
        },
        {
            "label": "Feked",
            "value": "Feked"
        },
        {
            "label": "Feketeerdő",
            "value": "Feketeerdő"
        },
        {
            "label": "Felcsút",
            "value": "Felcsút"
        },
        {
            "label": "Feldebrő",
            "value": "Feldebrő"
        },
        {
            "label": "Felgyő",
            "value": "Felgyő"
        },
        {
            "label": "Felpéc",
            "value": "Felpéc"
        },
        {
            "label": "Felsőberecki",
            "value": "Felsőberecki"
        },
        {
            "label": "Felsőcsatár",
            "value": "Felsőcsatár"
        },
        {
            "label": "Felsődobsza",
            "value": "Felsődobsza"
        },
        {
            "label": "Felsőegerszeg",
            "value": "Felsőegerszeg"
        },
        {
            "label": "Felsőgagy",
            "value": "Felsőgagy"
        },
        {
            "label": "Felsőjánosfa",
            "value": "Felsőjánosfa"
        },
        {
            "label": "Felsőkelecsény",
            "value": "Felsőkelecsény"
        },
        {
            "label": "Felsőlajos",
            "value": "Felsőlajos"
        },
        {
            "label": "Felsőmarác",
            "value": "Felsőmarác"
        },
        {
            "label": "Felsőmocsolád",
            "value": "Felsőmocsolád"
        },
        {
            "label": "Felsőnána",
            "value": "Felsőnána"
        },
        {
            "label": "Felsőnyárád",
            "value": "Felsőnyárád"
        },
        {
            "label": "Felsőnyék",
            "value": "Felsőnyék"
        },
        {
            "label": "Felsőörs",
            "value": "Felsőörs"
        },
        {
            "label": "Felsőpáhok",
            "value": "Felsőpáhok"
        },
        {
            "label": "Felsőpakony",
            "value": "Felsőpakony"
        },
        {
            "label": "Felsőpetény",
            "value": "Felsőpetény"
        },
        {
            "label": "Felsőrajk",
            "value": "Felsőrajk"
        },
        {
            "label": "Felsőregmec",
            "value": "Felsőregmec"
        },
        {
            "label": "Felsőszenterzsébet",
            "value": "Felsőszenterzsébet"
        },
        {
            "label": "Felsőszentiván",
            "value": "Felsőszentiván"
        },
        {
            "label": "Felsőszentmárton",
            "value": "Felsőszentmárton"
        },
        {
            "label": "Felsőszölnök",
            "value": "Felsőszölnök"
        },
        {
            "label": "Felsőtárkány",
            "value": "Felsőtárkány"
        },
        {
            "label": "Felsőtelekes",
            "value": "Felsőtelekes"
        },
        {
            "label": "Felsőtold",
            "value": "Felsőtold"
        },
        {
            "label": "Felsővadász",
            "value": "Felsővadász"
        },
        {
            "label": "Felsőzsolca",
            "value": "Felsőzsolca"
        },
        {
            "label": "Fényeslitke",
            "value": "Fényeslitke"
        },
        {
            "label": "Fenyőfő",
            "value": "Fenyőfő"
        },
        {
            "label": "Ferencszállás",
            "value": "Ferencszállás"
        },
        {
            "label": "Fertőboz",
            "value": "Fertőboz"
        },
        {
            "label": "Fertőd",
            "value": "Fertőd"
        },
        {
            "label": "Fertőendréd",
            "value": "Fertőendréd"
        },
        {
            "label": "Fertőhomok",
            "value": "Fertőhomok"
        },
        {
            "label": "Fertőrákos",
            "value": "Fertőrákos"
        },
        {
            "label": "Fertőszentmiklós",
            "value": "Fertőszentmiklós"
        },
        {
            "label": "Fertőszéplak",
            "value": "Fertőszéplak"
        },
        {
            "label": "Fiad",
            "value": "Fiad"
        },
        {
            "label": "Filkeháza",
            "value": "Filkeháza"
        },
        {
            "label": "Fityeház",
            "value": "Fityeház"
        },
        {
            "label": "Foktő",
            "value": "Foktő"
        },
        {
            "label": "Folyás",
            "value": "Folyás"
        },
        {
            "label": "Fonó",
            "value": "Fonó"
        },
        {
            "label": "Fony",
            "value": "Fony"
        },
        {
            "label": "Fonyód",
            "value": "Fonyód"
        },
        {
            "label": "Forráskút",
            "value": "Forráskút"
        },
        {
            "label": "Forró",
            "value": "Forró"
        },
        {
            "label": "Fót",
            "value": "Fót"
        },
        {
            "label": "Földeák",
            "value": "Földeák"
        },
        {
            "label": "Földes",
            "value": "Földes"
        },
        {
            "label": "Főnyed",
            "value": "Főnyed"
        },
        {
            "label": "Fulókércs",
            "value": "Fulókércs"
        },
        {
            "label": "Furta",
            "value": "Furta"
        },
        {
            "label": "Füle",
            "value": "Füle"
        },
        {
            "label": "Fülesd",
            "value": "Fülesd"
        },
        {
            "label": "Fülöp",
            "value": "Fülöp"
        },
        {
            "label": "Fülöpháza",
            "value": "Fülöpháza"
        },
        {
            "label": "Fülöpjakab",
            "value": "Fülöpjakab"
        },
        {
            "label": "Fülöpszállás",
            "value": "Fülöpszállás"
        },
        {
            "label": "Fülpösdaróc",
            "value": "Fülpösdaróc"
        },
        {
            "label": "Fürged",
            "value": "Fürged"
        },
        {
            "label": "Füzér",
            "value": "Füzér"
        },
        {
            "label": "Füzérkajata",
            "value": "Füzérkajata"
        },
        {
            "label": "Füzérkomlós",
            "value": "Füzérkomlós"
        },
        {
            "label": "Füzérradvány",
            "value": "Füzérradvány"
        },
        {
            "label": "Füzesabony",
            "value": "Füzesabony"
        },
        {
            "label": "Füzesgyarmat",
            "value": "Füzesgyarmat"
        },
        {
            "label": "Fűzvölgy",
            "value": "Fűzvölgy"
        },
        {
            "label": "Gáborján",
            "value": "Gáborján"
        },
        {
            "label": "Gáborjánháza",
            "value": "Gáborjánháza"
        },
        {
            "label": "Gacsály",
            "value": "Gacsály"
        },
        {
            "label": "Gadács",
            "value": "Gadács"
        },
        {
            "label": "Gadány",
            "value": "Gadány"
        },
        {
            "label": "Gadna",
            "value": "Gadna"
        },
        {
            "label": "Gádoros",
            "value": "Gádoros"
        },
        {
            "label": "Gagyapáti",
            "value": "Gagyapáti"
        },
        {
            "label": "Gagybátor",
            "value": "Gagybátor"
        },
        {
            "label": "Gagyvendégi",
            "value": "Gagyvendégi"
        },
        {
            "label": "Galambok",
            "value": "Galambok"
        },
        {
            "label": "Galgaguta",
            "value": "Galgaguta"
        },
        {
            "label": "Galgagyörk",
            "value": "Galgagyörk"
        },
        {
            "label": "Galgahévíz",
            "value": "Galgahévíz"
        },
        {
            "label": "Galgamácsa",
            "value": "Galgamácsa"
        },
        {
            "label": "Gálosfa",
            "value": "Gálosfa"
        },
        {
            "label": "Galvács",
            "value": "Galvács"
        },
        {
            "label": "Gamás",
            "value": "Gamás"
        },
        {
            "label": "Ganna",
            "value": "Ganna"
        },
        {
            "label": "Gánt",
            "value": "Gánt"
        },
        {
            "label": "Gara",
            "value": "Gara"
        },
        {
            "label": "Garáb",
            "value": "Garáb"
        },
        {
            "label": "Garabonc",
            "value": "Garabonc"
        },
        {
            "label": "Garadna",
            "value": "Garadna"
        },
        {
            "label": "Garbolc",
            "value": "Garbolc"
        },
        {
            "label": "Gárdony",
            "value": "Gárdony"
        },
        {
            "label": "Garé",
            "value": "Garé"
        },
        {
            "label": "Gasztony",
            "value": "Gasztony"
        },
        {
            "label": "Gátér",
            "value": "Gátér"
        },
        {
            "label": "Gávavencsellő",
            "value": "Gávavencsellő"
        },
        {
            "label": "Géberjén",
            "value": "Géberjén"
        },
        {
            "label": "Gecse",
            "value": "Gecse"
        },
        {
            "label": "Géderlak",
            "value": "Géderlak"
        },
        {
            "label": "Gégény",
            "value": "Gégény"
        },
        {
            "label": "Gelej",
            "value": "Gelej"
        },
        {
            "label": "Gelénes",
            "value": "Gelénes"
        },
        {
            "label": "Gellénháza",
            "value": "Gellénháza"
        },
        {
            "label": "Gelse",
            "value": "Gelse"
        },
        {
            "label": "Gelsesziget",
            "value": "Gelsesziget"
        },
        {
            "label": "Gemzse",
            "value": "Gemzse"
        },
        {
            "label": "Gencsapáti",
            "value": "Gencsapáti"
        },
        {
            "label": "Gérce",
            "value": "Gérce"
        },
        {
            "label": "Gerde",
            "value": "Gerde"
        },
        {
            "label": "Gerendás",
            "value": "Gerendás"
        },
        {
            "label": "Gerényes",
            "value": "Gerényes"
        },
        {
            "label": "Geresdlak",
            "value": "Geresdlak"
        },
        {
            "label": "Gerjen",
            "value": "Gerjen"
        },
        {
            "label": "Gersekarát",
            "value": "Gersekarát"
        },
        {
            "label": "Geszt",
            "value": "Geszt"
        },
        {
            "label": "Gesztely",
            "value": "Gesztely"
        },
        {
            "label": "Geszteréd",
            "value": "Geszteréd"
        },
        {
            "label": "Gétye",
            "value": "Gétye"
        },
        {
            "label": "Gic",
            "value": "Gic"
        },
        {
            "label": "Gige",
            "value": "Gige"
        },
        {
            "label": "Gilvánfa",
            "value": "Gilvánfa"
        },
        {
            "label": "Girincs",
            "value": "Girincs"
        },
        {
            "label": "Gógánfa",
            "value": "Gógánfa"
        },
        {
            "label": "Golop",
            "value": "Golop"
        },
        {
            "label": "Gomba",
            "value": "Gomba"
        },
        {
            "label": "Gombosszeg",
            "value": "Gombosszeg"
        },
        {
            "label": "Gór",
            "value": "Gór"
        },
        {
            "label": "Gordisa",
            "value": "Gordisa"
        },
        {
            "label": "Gosztola",
            "value": "Gosztola"
        },
        {
            "label": "Göd",
            "value": "Göd"
        },
        {
            "label": "Gödöllő",
            "value": "Gödöllő"
        },
        {
            "label": "Gödre",
            "value": "Gödre"
        },
        {
            "label": "Gölle",
            "value": "Gölle"
        },
        {
            "label": "Gömörszőlős",
            "value": "Gömörszőlős"
        },
        {
            "label": "Gönc",
            "value": "Gönc"
        },
        {
            "label": "Göncruszka",
            "value": "Göncruszka"
        },
        {
            "label": "Gönyű",
            "value": "Gönyű"
        },
        {
            "label": "Görbeháza",
            "value": "Görbeháza"
        },
        {
            "label": "Görcsöny",
            "value": "Görcsöny"
        },
        {
            "label": "Görcsönydoboka",
            "value": "Görcsönydoboka"
        },
        {
            "label": "Görgeteg",
            "value": "Görgeteg"
        },
        {
            "label": "Gősfa",
            "value": "Gősfa"
        },
        {
            "label": "Grábóc",
            "value": "Grábóc"
        },
        {
            "label": "Gulács",
            "value": "Gulács"
        },
        {
            "label": "Gutorfölde",
            "value": "Gutorfölde"
        },
        {
            "label": "Gyál",
            "value": "Gyál"
        },
        {
            "label": "Gyalóka",
            "value": "Gyalóka"
        },
        {
            "label": "Gyanógeregye",
            "value": "Gyanógeregye"
        },
        {
            "label": "Gyarmat",
            "value": "Gyarmat"
        },
        {
            "label": "Gyékényes",
            "value": "Gyékényes"
        },
        {
            "label": "Gyenesdiás",
            "value": "Gyenesdiás"
        },
        {
            "label": "Gyepükaján",
            "value": "Gyepükaján"
        },
        {
            "label": "Gyermely",
            "value": "Gyermely"
        },
        {
            "label": "Gyód",
            "value": "Gyód"
        },
        {
            "label": "Gyomaendrőd",
            "value": "Gyomaendrőd"
        },
        {
            "label": "Gyóró",
            "value": "Gyóró"
        },
        {
            "label": "Gyömöre",
            "value": "Gyömöre"
        },
        {
            "label": "Gyömrő",
            "value": "Gyömrő"
        },
        {
            "label": "Gyöngyfa",
            "value": "Gyöngyfa"
        },
        {
            "label": "Gyöngyös",
            "value": "Gyöngyös"
        },
        {
            "label": "Gyöngyösfalu",
            "value": "Gyöngyösfalu"
        },
        {
            "label": "Gyöngyöshalász",
            "value": "Gyöngyöshalász"
        },
        {
            "label": "Gyöngyösmellék",
            "value": "Gyöngyösmellék"
        },
        {
            "label": "Gyöngyösoroszi",
            "value": "Gyöngyösoroszi"
        },
        {
            "label": "Gyöngyöspata",
            "value": "Gyöngyöspata"
        },
        {
            "label": "Gyöngyössolymos",
            "value": "Gyöngyössolymos"
        },
        {
            "label": "Gyöngyöstarján",
            "value": "Gyöngyöstarján"
        },
        {
            "label": "Gyönk",
            "value": "Gyönk"
        },
        {
            "label": "Győr",
            "value": "Győr"
        },
        {
            "label": "Győrasszonyfa",
            "value": "Győrasszonyfa"
        },
        {
            "label": "Györe",
            "value": "Györe"
        },
        {
            "label": "Györgytarló",
            "value": "Györgytarló"
        },
        {
            "label": "Györköny",
            "value": "Györköny"
        },
        {
            "label": "Győrladamér",
            "value": "Győrladamér"
        },
        {
            "label": "Győröcske",
            "value": "Győröcske"
        },
        {
            "label": "Győrság",
            "value": "Győrság"
        },
        {
            "label": "Győrsövényház",
            "value": "Győrsövényház"
        },
        {
            "label": "Győrszemere",
            "value": "Győrszemere"
        },
        {
            "label": "Győrtelek",
            "value": "Győrtelek"
        },
        {
            "label": "Győrújbarát",
            "value": "Győrújbarát"
        },
        {
            "label": "Győrújfalu",
            "value": "Győrújfalu"
        },
        {
            "label": "Győrvár",
            "value": "Győrvár"
        },
        {
            "label": "Győrzámoly",
            "value": "Győrzámoly"
        },
        {
            "label": "Gyugy",
            "value": "Gyugy"
        },
        {
            "label": "Gyula",
            "value": "Gyula"
        },
        {
            "label": "Gyulaháza",
            "value": "Gyulaháza"
        },
        {
            "label": "Gyulaj",
            "value": "Gyulaj"
        },
        {
            "label": "Gyulakeszi",
            "value": "Gyulakeszi"
        },
        {
            "label": "Gyúró",
            "value": "Gyúró"
        },
        {
            "label": "Gyügye",
            "value": "Gyügye"
        },
        {
            "label": "Gyüre",
            "value": "Gyüre"
        },
        {
            "label": "Gyűrűs",
            "value": "Gyűrűs"
        },
        {
            "label": "Hács",
            "value": "Hács"
        },
        {
            "label": "Hagyárosbörönd",
            "value": "Hagyárosbörönd"
        },
        {
            "label": "Hahót",
            "value": "Hahót"
        },
        {
            "label": "Hajdúbagos",
            "value": "Hajdúbagos"
        },
        {
            "label": "Hajdúböszörmény",
            "value": "Hajdúböszörmény"
        },
        {
            "label": "Hajdúdorog",
            "value": "Hajdúdorog"
        },
        {
            "label": "Hajdúhadház",
            "value": "Hajdúhadház"
        },
        {
            "label": "Hajdúnánás",
            "value": "Hajdúnánás"
        },
        {
            "label": "Hajdúsámson",
            "value": "Hajdúsámson"
        },
        {
            "label": "Hajdúszoboszló",
            "value": "Hajdúszoboszló"
        },
        {
            "label": "Hajdúszovát",
            "value": "Hajdúszovát"
        },
        {
            "label": "Hajmás",
            "value": "Hajmás"
        },
        {
            "label": "Hajmáskér",
            "value": "Hajmáskér"
        },
        {
            "label": "Hajós",
            "value": "Hajós"
        },
        {
            "label": "Halastó",
            "value": "Halastó"
        },
        {
            "label": "Halászi",
            "value": "Halászi"
        },
        {
            "label": "Halásztelek",
            "value": "Halásztelek"
        },
        {
            "label": "Halimba",
            "value": "Halimba"
        },
        {
            "label": "Halmaj",
            "value": "Halmaj"
        },
        {
            "label": "Halmajugra",
            "value": "Halmajugra"
        },
        {
            "label": "Halogy",
            "value": "Halogy"
        },
        {
            "label": "Hangács",
            "value": "Hangács"
        },
        {
            "label": "Hangony",
            "value": "Hangony"
        },
        {
            "label": "Hantos",
            "value": "Hantos"
        },
        {
            "label": "Harasztifalu",
            "value": "Harasztifalu"
        },
        {
            "label": "Harc",
            "value": "Harc"
        },
        {
            "label": "Harka",
            "value": "Harka"
        },
        {
            "label": "Harkakötöny",
            "value": "Harkakötöny"
        },
        {
            "label": "Harkány",
            "value": "Harkány"
        },
        {
            "label": "Háromfa",
            "value": "Háromfa"
        },
        {
            "label": "Háromhuta",
            "value": "Háromhuta"
        },
        {
            "label": "Harsány",
            "value": "Harsány"
        },
        {
            "label": "Hárskút",
            "value": "Hárskút"
        },
        {
            "label": "Harta",
            "value": "Harta"
        },
        {
            "label": "Hásságy",
            "value": "Hásságy"
        },
        {
            "label": "Hatvan",
            "value": "Hatvan"
        },
        {
            "label": "Hédervár",
            "value": "Hédervár"
        },
        {
            "label": "Hedrehely",
            "value": "Hedrehely"
        },
        {
            "label": "Hegyesd",
            "value": "Hegyesd"
        },
        {
            "label": "Hegyeshalom",
            "value": "Hegyeshalom"
        },
        {
            "label": "Hegyfalu",
            "value": "Hegyfalu"
        },
        {
            "label": "Hegyháthodász",
            "value": "Hegyháthodász"
        },
        {
            "label": "Hegyhátmaróc",
            "value": "Hegyhátmaróc"
        },
        {
            "label": "Hegyhátsál",
            "value": "Hegyhátsál"
        },
        {
            "label": "Hegyhátszentjakab",
            "value": "Hegyhátszentjakab"
        },
        {
            "label": "Hegyhátszentmárton",
            "value": "Hegyhátszentmárton"
        },
        {
            "label": "Hegyhátszentpéter",
            "value": "Hegyhátszentpéter"
        },
        {
            "label": "Hegykő",
            "value": "Hegykő"
        },
        {
            "label": "Hegymagas",
            "value": "Hegymagas"
        },
        {
            "label": "Hegymeg",
            "value": "Hegymeg"
        },
        {
            "label": "Hegyszentmárton",
            "value": "Hegyszentmárton"
        },
        {
            "label": "Héhalom",
            "value": "Héhalom"
        },
        {
            "label": "Hejce",
            "value": "Hejce"
        },
        {
            "label": "Hejőbába",
            "value": "Hejőbába"
        },
        {
            "label": "Hejőkeresztúr",
            "value": "Hejőkeresztúr"
        },
        {
            "label": "Hejőkürt",
            "value": "Hejőkürt"
        },
        {
            "label": "Hejőpapi",
            "value": "Hejőpapi"
        },
        {
            "label": "Hejőszalonta",
            "value": "Hejőszalonta"
        },
        {
            "label": "Helesfa",
            "value": "Helesfa"
        },
        {
            "label": "Helvécia",
            "value": "Helvécia"
        },
        {
            "label": "Hencida",
            "value": "Hencida"
        },
        {
            "label": "Hencse",
            "value": "Hencse"
        },
        {
            "label": "Herceghalom",
            "value": "Herceghalom"
        },
        {
            "label": "Hercegkút",
            "value": "Hercegkút"
        },
        {
            "label": "Hercegszántó",
            "value": "Hercegszántó"
        },
        {
            "label": "Heréd",
            "value": "Heréd"
        },
        {
            "label": "Héreg",
            "value": "Héreg"
        },
        {
            "label": "Herencsény",
            "value": "Herencsény"
        },
        {
            "label": "Herend",
            "value": "Herend"
        },
        {
            "label": "Heresznye",
            "value": "Heresznye"
        },
        {
            "label": "Hermánszeg",
            "value": "Hermánszeg"
        },
        {
            "label": "Hernád",
            "value": "Hernád"
        },
        {
            "label": "Hernádbűd",
            "value": "Hernádbűd"
        },
        {
            "label": "Hernádcéce",
            "value": "Hernádcéce"
        },
        {
            "label": "Hernádkak",
            "value": "Hernádkak"
        },
        {
            "label": "Hernádkércs",
            "value": "Hernádkércs"
        },
        {
            "label": "Hernádnémeti",
            "value": "Hernádnémeti"
        },
        {
            "label": "Hernádpetri",
            "value": "Hernádpetri"
        },
        {
            "label": "Hernádszentandrás",
            "value": "Hernádszentandrás"
        },
        {
            "label": "Hernádszurdok",
            "value": "Hernádszurdok"
        },
        {
            "label": "Hernádvécse",
            "value": "Hernádvécse"
        },
        {
            "label": "Hernyék",
            "value": "Hernyék"
        },
        {
            "label": "Hét",
            "value": "Hét"
        },
        {
            "label": "Hetefejércse",
            "value": "Hetefejércse"
        },
        {
            "label": "Hetes",
            "value": "Hetes"
        },
        {
            "label": "Hetvehely",
            "value": "Hetvehely"
        },
        {
            "label": "Hetyefő",
            "value": "Hetyefő"
        },
        {
            "label": "Heves",
            "value": "Heves"
        },
        {
            "label": "Hevesaranyos",
            "value": "Hevesaranyos"
        },
        {
            "label": "Hevesvezekény",
            "value": "Hevesvezekény"
        },
        {
            "label": "Hévíz",
            "value": "Hévíz"
        },
        {
            "label": "Hévízgyörk",
            "value": "Hévízgyörk"
        },
        {
            "label": "Hidas",
            "value": "Hidas"
        },
        {
            "label": "Hidasnémeti",
            "value": "Hidasnémeti"
        },
        {
            "label": "Hidegkút",
            "value": "Hidegkút"
        },
        {
            "label": "Hidegség",
            "value": "Hidegség"
        },
        {
            "label": "Hidvégardó",
            "value": "Hidvégardó"
        },
        {
            "label": "Himesháza",
            "value": "Himesháza"
        },
        {
            "label": "Himod",
            "value": "Himod"
        },
        {
            "label": "Hirics",
            "value": "Hirics"
        },
        {
            "label": "Hobol",
            "value": "Hobol"
        },
        {
            "label": "Hodász",
            "value": "Hodász"
        },
        {
            "label": "Hódmezővásárhely",
            "value": "Hódmezővásárhely"
        },
        {
            "label": "Hollád",
            "value": "Hollád"
        },
        {
            "label": "Hollóháza",
            "value": "Hollóháza"
        },
        {
            "label": "Hollókő",
            "value": "Hollókő"
        },
        {
            "label": "Homokbödöge",
            "value": "Homokbödöge"
        },
        {
            "label": "Homokkomárom",
            "value": "Homokkomárom"
        },
        {
            "label": "Homokmégy",
            "value": "Homokmégy"
        },
        {
            "label": "Homokszentgyörgy",
            "value": "Homokszentgyörgy"
        },
        {
            "label": "Homorúd",
            "value": "Homorúd"
        },
        {
            "label": "Homrogd",
            "value": "Homrogd"
        },
        {
            "label": "Hont",
            "value": "Hont"
        },
        {
            "label": "Horpács",
            "value": "Horpács"
        },
        {
            "label": "Hort",
            "value": "Hort"
        },
        {
            "label": "Hortobágy",
            "value": "Hortobágy"
        },
        {
            "label": "Horváthertelend",
            "value": "Horváthertelend"
        },
        {
            "label": "Horvátlövő",
            "value": "Horvátlövő"
        },
        {
            "label": "Horvátzsidány",
            "value": "Horvátzsidány"
        },
        {
            "label": "Hosszúhetény",
            "value": "Hosszúhetény"
        },
        {
            "label": "Hosszúpályi",
            "value": "Hosszúpályi"
        },
        {
            "label": "Hosszúpereszteg",
            "value": "Hosszúpereszteg"
        },
        {
            "label": "Hosszúvíz",
            "value": "Hosszúvíz"
        },
        {
            "label": "Hosszúvölgy",
            "value": "Hosszúvölgy"
        },
        {
            "label": "Hosztót",
            "value": "Hosztót"
        },
        {
            "label": "Hottó",
            "value": "Hottó"
        },
        {
            "label": "Hőgyész",
            "value": "Hőgyész"
        },
        {
            "label": "Hövej",
            "value": "Hövej"
        },
        {
            "label": "Hugyag",
            "value": "Hugyag"
        },
        {
            "label": "Hunya",
            "value": "Hunya"
        },
        {
            "label": "Hunyadfalva",
            "value": "Hunyadfalva"
        },
        {
            "label": "Husztót",
            "value": "Husztót"
        },
        {
            "label": "Ibafa",
            "value": "Ibafa"
        },
        {
            "label": "Iborfia",
            "value": "Iborfia"
        },
        {
            "label": "Ibrány",
            "value": "Ibrány"
        },
        {
            "label": "Igal",
            "value": "Igal"
        },
        {
            "label": "Igar",
            "value": "Igar"
        },
        {
            "label": "Igrici",
            "value": "Igrici"
        },
        {
            "label": "Iharos",
            "value": "Iharos"
        },
        {
            "label": "Iharosberény",
            "value": "Iharosberény"
        },
        {
            "label": "Ikervár",
            "value": "Ikervár"
        },
        {
            "label": "Iklad",
            "value": "Iklad"
        },
        {
            "label": "Iklanberény",
            "value": "Iklanberény"
        },
        {
            "label": "Iklódbördőce",
            "value": "Iklódbördőce"
        },
        {
            "label": "Ikrény",
            "value": "Ikrény"
        },
        {
            "label": "Iliny",
            "value": "Iliny"
        },
        {
            "label": "Ilk",
            "value": "Ilk"
        },
        {
            "label": "Illocska",
            "value": "Illocska"
        },
        {
            "label": "Imola",
            "value": "Imola"
        },
        {
            "label": "Imrehegy",
            "value": "Imrehegy"
        },
        {
            "label": "Ináncs",
            "value": "Ináncs"
        },
        {
            "label": "Inárcs",
            "value": "Inárcs"
        },
        {
            "label": "Inke",
            "value": "Inke"
        },
        {
            "label": "Ipacsfa",
            "value": "Ipacsfa"
        },
        {
            "label": "Ipolydamásd",
            "value": "Ipolydamásd"
        },
        {
            "label": "Ipolytarnóc",
            "value": "Ipolytarnóc"
        },
        {
            "label": "Ipolytölgyes",
            "value": "Ipolytölgyes"
        },
        {
            "label": "Ipolyvece",
            "value": "Ipolyvece"
        },
        {
            "label": "Iregszemcse",
            "value": "Iregszemcse"
        },
        {
            "label": "Irota",
            "value": "Irota"
        },
        {
            "label": "Isaszeg",
            "value": "Isaszeg"
        },
        {
            "label": "Ispánk",
            "value": "Ispánk"
        },
        {
            "label": "Istenmezeje",
            "value": "Istenmezeje"
        },
        {
            "label": "Istvándi",
            "value": "Istvándi"
        },
        {
            "label": "Iszkaszentgyörgy",
            "value": "Iszkaszentgyörgy"
        },
        {
            "label": "Iszkáz",
            "value": "Iszkáz"
        },
        {
            "label": "Isztimér",
            "value": "Isztimér"
        },
        {
            "label": "Ivád",
            "value": "Ivád"
        },
        {
            "label": "Iván",
            "value": "Iván"
        },
        {
            "label": "Ivánbattyán",
            "value": "Ivánbattyán"
        },
        {
            "label": "Ivánc",
            "value": "Ivánc"
        },
        {
            "label": "Iváncsa",
            "value": "Iváncsa"
        },
        {
            "label": "Ivándárda",
            "value": "Ivándárda"
        },
        {
            "label": "Izmény",
            "value": "Izmény"
        },
        {
            "label": "Izsák",
            "value": "Izsák"
        },
        {
            "label": "Izsófalva",
            "value": "Izsófalva"
        },
        {
            "label": "Jágónak",
            "value": "Jágónak"
        },
        {
            "label": "Ják",
            "value": "Ják"
        },
        {
            "label": "Jakabszállás",
            "value": "Jakabszállás"
        },
        {
            "label": "Jákfa",
            "value": "Jákfa"
        },
        {
            "label": "Jákfalva",
            "value": "Jákfalva"
        },
        {
            "label": "Jákó",
            "value": "Jákó"
        },
        {
            "label": "Jánd",
            "value": "Jánd"
        },
        {
            "label": "Jánkmajtis",
            "value": "Jánkmajtis"
        },
        {
            "label": "Jánoshalma",
            "value": "Jánoshalma"
        },
        {
            "label": "Jánosháza",
            "value": "Jánosháza"
        },
        {
            "label": "Jánoshida",
            "value": "Jánoshida"
        },
        {
            "label": "Jánossomorja",
            "value": "Jánossomorja"
        },
        {
            "label": "Járdánháza",
            "value": "Járdánháza"
        },
        {
            "label": "Jármi",
            "value": "Jármi"
        },
        {
            "label": "Jásd",
            "value": "Jásd"
        },
        {
            "label": "Jászágó",
            "value": "Jászágó"
        },
        {
            "label": "Jászalsószentgyörgy",
            "value": "Jászalsószentgyörgy"
        },
        {
            "label": "Jászapáti",
            "value": "Jászapáti"
        },
        {
            "label": "Jászárokszállás",
            "value": "Jászárokszállás"
        },
        {
            "label": "Jászberény",
            "value": "Jászberény"
        },
        {
            "label": "Jászboldogháza",
            "value": "Jászboldogháza"
        },
        {
            "label": "Jászdózsa",
            "value": "Jászdózsa"
        },
        {
            "label": "Jászfelsőszentgyörgy",
            "value": "Jászfelsőszentgyörgy"
        },
        {
            "label": "Jászfényszaru",
            "value": "Jászfényszaru"
        },
        {
            "label": "Jászivány",
            "value": "Jászivány"
        },
        {
            "label": "Jászjákóhalma",
            "value": "Jászjákóhalma"
        },
        {
            "label": "Jászkarajenő",
            "value": "Jászkarajenő"
        },
        {
            "label": "Jászkisér",
            "value": "Jászkisér"
        },
        {
            "label": "Jászladány",
            "value": "Jászladány"
        },
        {
            "label": "Jászszentandrás",
            "value": "Jászszentandrás"
        },
        {
            "label": "Jászszentlászló",
            "value": "Jászszentlászló"
        },
        {
            "label": "Jásztelek",
            "value": "Jásztelek"
        },
        {
            "label": "Jéke",
            "value": "Jéke"
        },
        {
            "label": "Jenő",
            "value": "Jenő"
        },
        {
            "label": "Jobaháza",
            "value": "Jobaháza"
        },
        {
            "label": "Jobbágyi",
            "value": "Jobbágyi"
        },
        {
            "label": "Jósvafő",
            "value": "Jósvafő"
        },
        {
            "label": "Juta",
            "value": "Juta"
        },
        {
            "label": "Kaba",
            "value": "Kaba"
        },
        {
            "label": "Kacorlak",
            "value": "Kacorlak"
        },
        {
            "label": "Kács",
            "value": "Kács"
        },
        {
            "label": "Kacsóta",
            "value": "Kacsóta"
        },
        {
            "label": "Kadarkút",
            "value": "Kadarkút"
        },
        {
            "label": "Kajárpéc",
            "value": "Kajárpéc"
        },
        {
            "label": "Kajászó",
            "value": "Kajászó"
        },
        {
            "label": "Kajdacs",
            "value": "Kajdacs"
        },
        {
            "label": "Kakasd",
            "value": "Kakasd"
        },
        {
            "label": "Kákics",
            "value": "Kákics"
        },
        {
            "label": "Kakucs",
            "value": "Kakucs"
        },
        {
            "label": "Kál",
            "value": "Kál"
        },
        {
            "label": "Kalaznó",
            "value": "Kalaznó"
        },
        {
            "label": "Káld",
            "value": "Káld"
        },
        {
            "label": "Kálló",
            "value": "Kálló"
        },
        {
            "label": "Kallósd",
            "value": "Kallósd"
        },
        {
            "label": "Kállósemjén",
            "value": "Kállósemjén"
        },
        {
            "label": "Kálmáncsa",
            "value": "Kálmáncsa"
        },
        {
            "label": "Kálmánháza",
            "value": "Kálmánháza"
        },
        {
            "label": "Kálócfa",
            "value": "Kálócfa"
        },
        {
            "label": "Kalocsa",
            "value": "Kalocsa"
        },
        {
            "label": "Káloz",
            "value": "Káloz"
        },
        {
            "label": "Kám",
            "value": "Kám"
        },
        {
            "label": "Kamond",
            "value": "Kamond"
        },
        {
            "label": "Kamut",
            "value": "Kamut"
        },
        {
            "label": "Kánó",
            "value": "Kánó"
        },
        {
            "label": "Kántorjánosi",
            "value": "Kántorjánosi"
        },
        {
            "label": "Kány",
            "value": "Kány"
        },
        {
            "label": "Kánya",
            "value": "Kánya"
        },
        {
            "label": "Kányavár",
            "value": "Kányavár"
        },
        {
            "label": "Kapolcs",
            "value": "Kapolcs"
        },
        {
            "label": "Kápolna",
            "value": "Kápolna"
        },
        {
            "label": "Kápolnásnyék",
            "value": "Kápolnásnyék"
        },
        {
            "label": "Kapoly",
            "value": "Kapoly"
        },
        {
            "label": "Kaposfő",
            "value": "Kaposfő"
        },
        {
            "label": "Kaposgyarmat",
            "value": "Kaposgyarmat"
        },
        {
            "label": "Kaposhomok",
            "value": "Kaposhomok"
        },
        {
            "label": "Kaposkeresztúr",
            "value": "Kaposkeresztúr"
        },
        {
            "label": "Kaposmérő",
            "value": "Kaposmérő"
        },
        {
            "label": "Kapospula",
            "value": "Kapospula"
        },
        {
            "label": "Kaposszekcső",
            "value": "Kaposszekcső"
        },
        {
            "label": "Kaposszerdahely",
            "value": "Kaposszerdahely"
        },
        {
            "label": "Kaposújlak",
            "value": "Kaposújlak"
        },
        {
            "label": "Kaposvár",
            "value": "Kaposvár"
        },
        {
            "label": "Káptalanfa",
            "value": "Káptalanfa"
        },
        {
            "label": "Káptalantóti",
            "value": "Káptalantóti"
        },
        {
            "label": "Kapuvár",
            "value": "Kapuvár"
        },
        {
            "label": "Kára",
            "value": "Kára"
        },
        {
            "label": "Karácsond",
            "value": "Karácsond"
        },
        {
            "label": "Karád",
            "value": "Karád"
        },
        {
            "label": "Karakó",
            "value": "Karakó"
        },
        {
            "label": "Karakószörcsök",
            "value": "Karakószörcsök"
        },
        {
            "label": "Karancsalja",
            "value": "Karancsalja"
        },
        {
            "label": "Karancsberény",
            "value": "Karancsberény"
        },
        {
            "label": "Karancskeszi",
            "value": "Karancskeszi"
        },
        {
            "label": "Karancslapujtő",
            "value": "Karancslapujtő"
        },
        {
            "label": "Karancsság",
            "value": "Karancsság"
        },
        {
            "label": "Kárász",
            "value": "Kárász"
        },
        {
            "label": "Karcag",
            "value": "Karcag"
        },
        {
            "label": "Karcsa",
            "value": "Karcsa"
        },
        {
            "label": "Kardos",
            "value": "Kardos"
        },
        {
            "label": "Kardoskút",
            "value": "Kardoskút"
        },
        {
            "label": "Karmacs",
            "value": "Karmacs"
        },
        {
            "label": "Károlyháza",
            "value": "Károlyháza"
        },
        {
            "label": "Karos",
            "value": "Karos"
        },
        {
            "label": "Kartal",
            "value": "Kartal"
        },
        {
            "label": "Kásád",
            "value": "Kásád"
        },
        {
            "label": "Kaskantyú",
            "value": "Kaskantyú"
        },
        {
            "label": "Kastélyosdombó",
            "value": "Kastélyosdombó"
        },
        {
            "label": "Kaszaper",
            "value": "Kaszaper"
        },
        {
            "label": "Kaszó",
            "value": "Kaszó"
        },
        {
            "label": "Katádfa",
            "value": "Katádfa"
        },
        {
            "label": "Katafa",
            "value": "Katafa"
        },
        {
            "label": "Kátoly",
            "value": "Kátoly"
        },
        {
            "label": "Katymár",
            "value": "Katymár"
        },
        {
            "label": "Káva",
            "value": "Káva"
        },
        {
            "label": "Kávás",
            "value": "Kávás"
        },
        {
            "label": "Kazár",
            "value": "Kazár"
        },
        {
            "label": "Kazincbarcika",
            "value": "Kazincbarcika"
        },
        {
            "label": "Kázsmárk",
            "value": "Kázsmárk"
        },
        {
            "label": "Kazsok",
            "value": "Kazsok"
        },
        {
            "label": "Kecel",
            "value": "Kecel"
        },
        {
            "label": "Kecskéd",
            "value": "Kecskéd"
        },
        {
            "label": "Kecskemét",
            "value": "Kecskemét"
        },
        {
            "label": "Kehidakustány",
            "value": "Kehidakustány"
        },
        {
            "label": "Kék",
            "value": "Kék"
        },
        {
            "label": "Kékcse",
            "value": "Kékcse"
        },
        {
            "label": "Kéked",
            "value": "Kéked"
        },
        {
            "label": "Kékesd",
            "value": "Kékesd"
        },
        {
            "label": "Kékkút",
            "value": "Kékkút"
        },
        {
            "label": "Kelebia",
            "value": "Kelebia"
        },
        {
            "label": "Keléd",
            "value": "Keléd"
        },
        {
            "label": "Kelemér",
            "value": "Kelemér"
        },
        {
            "label": "Kéleshalom",
            "value": "Kéleshalom"
        },
        {
            "label": "Kelevíz",
            "value": "Kelevíz"
        },
        {
            "label": "Kemecse",
            "value": "Kemecse"
        },
        {
            "label": "Kemence",
            "value": "Kemence"
        },
        {
            "label": "Kemendollár",
            "value": "Kemendollár"
        },
        {
            "label": "Kemeneshőgyész",
            "value": "Kemeneshőgyész"
        },
        {
            "label": "Kemeneskápolna",
            "value": "Kemeneskápolna"
        },
        {
            "label": "Kemenesmagasi",
            "value": "Kemenesmagasi"
        },
        {
            "label": "Kemenesmihályfa",
            "value": "Kemenesmihályfa"
        },
        {
            "label": "Kemenespálfa",
            "value": "Kemenespálfa"
        },
        {
            "label": "Kemenessömjén",
            "value": "Kemenessömjén"
        },
        {
            "label": "Kemenesszentmárton",
            "value": "Kemenesszentmárton"
        },
        {
            "label": "Kemenesszentpéter",
            "value": "Kemenesszentpéter"
        },
        {
            "label": "Keménfa",
            "value": "Keménfa"
        },
        {
            "label": "Kémes",
            "value": "Kémes"
        },
        {
            "label": "Kemestaródfa",
            "value": "Kemestaródfa"
        },
        {
            "label": "Kemse",
            "value": "Kemse"
        },
        {
            "label": "Kenderes",
            "value": "Kenderes"
        },
        {
            "label": "Kenéz",
            "value": "Kenéz"
        },
        {
            "label": "Kenézlő",
            "value": "Kenézlő"
        },
        {
            "label": "Kengyel",
            "value": "Kengyel"
        },
        {
            "label": "Kenyeri",
            "value": "Kenyeri"
        },
        {
            "label": "Kercaszomor",
            "value": "Kercaszomor"
        },
        {
            "label": "Kercseliget",
            "value": "Kercseliget"
        },
        {
            "label": "Kerecsend",
            "value": "Kerecsend"
        },
        {
            "label": "Kerecseny",
            "value": "Kerecseny"
        },
        {
            "label": "Kerekegyháza",
            "value": "Kerekegyháza"
        },
        {
            "label": "Kereki",
            "value": "Kereki"
        },
        {
            "label": "Kerékteleki",
            "value": "Kerékteleki"
        },
        {
            "label": "Kerepes",
            "value": "Kerepes"
        },
        {
            "label": "Keresztéte",
            "value": "Keresztéte"
        },
        {
            "label": "Kerkabarabás",
            "value": "Kerkabarabás"
        },
        {
            "label": "Kerkafalva",
            "value": "Kerkafalva"
        },
        {
            "label": "Kerkakutas",
            "value": "Kerkakutas"
        },
        {
            "label": "Kerkáskápolna",
            "value": "Kerkáskápolna"
        },
        {
            "label": "Kerkaszentkirály",
            "value": "Kerkaszentkirály"
        },
        {
            "label": "Kerkateskánd",
            "value": "Kerkateskánd"
        },
        {
            "label": "Kérsemjén",
            "value": "Kérsemjén"
        },
        {
            "label": "Kerta",
            "value": "Kerta"
        },
        {
            "label": "Kertészsziget",
            "value": "Kertészsziget"
        },
        {
            "label": "Keszeg",
            "value": "Keszeg"
        },
        {
            "label": "Kesznyéten",
            "value": "Kesznyéten"
        },
        {
            "label": "Keszőhidegkút",
            "value": "Keszőhidegkút"
        },
        {
            "label": "Keszthely",
            "value": "Keszthely"
        },
        {
            "label": "Kesztölc",
            "value": "Kesztölc"
        },
        {
            "label": "Keszü",
            "value": "Keszü"
        },
        {
            "label": "Kétbodony",
            "value": "Kétbodony"
        },
        {
            "label": "Kétegyháza",
            "value": "Kétegyháza"
        },
        {
            "label": "Kéthely",
            "value": "Kéthely"
        },
        {
            "label": "Kétpó",
            "value": "Kétpó"
        },
        {
            "label": "Kétsoprony",
            "value": "Kétsoprony"
        },
        {
            "label": "Kétújfalu",
            "value": "Kétújfalu"
        },
        {
            "label": "Kétvölgy",
            "value": "Kétvölgy"
        },
        {
            "label": "Kéty",
            "value": "Kéty"
        },
        {
            "label": "Kevermes",
            "value": "Kevermes"
        },
        {
            "label": "Kilimán",
            "value": "Kilimán"
        },
        {
            "label": "Kimle",
            "value": "Kimle"
        },
        {
            "label": "Kincsesbánya",
            "value": "Kincsesbánya"
        },
        {
            "label": "Királd",
            "value": "Királd"
        },
        {
            "label": "Királyegyháza",
            "value": "Királyegyháza"
        },
        {
            "label": "Királyhegyes",
            "value": "Királyhegyes"
        },
        {
            "label": "Királyszentistván",
            "value": "Királyszentistván"
        },
        {
            "label": "Kisapáti",
            "value": "Kisapáti"
        },
        {
            "label": "Kisapostag",
            "value": "Kisapostag"
        },
        {
            "label": "Kisar",
            "value": "Kisar"
        },
        {
            "label": "Kisasszond",
            "value": "Kisasszond"
        },
        {
            "label": "Kisasszonyfa",
            "value": "Kisasszonyfa"
        },
        {
            "label": "Kisbabot",
            "value": "Kisbabot"
        },
        {
            "label": "Kisbágyon",
            "value": "Kisbágyon"
        },
        {
            "label": "Kisbajcs",
            "value": "Kisbajcs"
        },
        {
            "label": "Kisbajom",
            "value": "Kisbajom"
        },
        {
            "label": "Kisbárapáti",
            "value": "Kisbárapáti"
        },
        {
            "label": "Kisbárkány",
            "value": "Kisbárkány"
        },
        {
            "label": "Kisbér",
            "value": "Kisbér"
        },
        {
            "label": "Kisberény",
            "value": "Kisberény"
        },
        {
            "label": "Kisberzseny",
            "value": "Kisberzseny"
        },
        {
            "label": "Kisbeszterce",
            "value": "Kisbeszterce"
        },
        {
            "label": "Kisbodak",
            "value": "Kisbodak"
        },
        {
            "label": "Kisbucsa",
            "value": "Kisbucsa"
        },
        {
            "label": "Kisbudmér",
            "value": "Kisbudmér"
        },
        {
            "label": "Kiscsécs",
            "value": "Kiscsécs"
        },
        {
            "label": "Kiscsehi",
            "value": "Kiscsehi"
        },
        {
            "label": "Kiscsősz",
            "value": "Kiscsősz"
        },
        {
            "label": "Kisdér",
            "value": "Kisdér"
        },
        {
            "label": "Kisdobsza",
            "value": "Kisdobsza"
        },
        {
            "label": "Kisdombegyház",
            "value": "Kisdombegyház"
        },
        {
            "label": "Kisdorog",
            "value": "Kisdorog"
        },
        {
            "label": "Kisecset",
            "value": "Kisecset"
        },
        {
            "label": "Kisfalud",
            "value": "Kisfalud"
        },
        {
            "label": "Kisfüzes",
            "value": "Kisfüzes"
        },
        {
            "label": "Kisgörbő",
            "value": "Kisgörbő"
        },
        {
            "label": "Kisgyalán",
            "value": "Kisgyalán"
        },
        {
            "label": "Kisgyőr",
            "value": "Kisgyőr"
        },
        {
            "label": "Kishajmás",
            "value": "Kishajmás"
        },
        {
            "label": "Kisharsány",
            "value": "Kisharsány"
        },
        {
            "label": "Kishartyán",
            "value": "Kishartyán"
        },
        {
            "label": "Kisherend",
            "value": "Kisherend"
        },
        {
            "label": "Kishódos",
            "value": "Kishódos"
        },
        {
            "label": "Kishuta",
            "value": "Kishuta"
        },
        {
            "label": "Kisigmánd",
            "value": "Kisigmánd"
        },
        {
            "label": "Kisjakabfalva",
            "value": "Kisjakabfalva"
        },
        {
            "label": "Kiskassa",
            "value": "Kiskassa"
        },
        {
            "label": "Kiskinizs",
            "value": "Kiskinizs"
        },
        {
            "label": "Kiskorpád",
            "value": "Kiskorpád"
        },
        {
            "label": "Kisköre",
            "value": "Kisköre"
        },
        {
            "label": "Kiskőrös",
            "value": "Kiskőrös"
        },
        {
            "label": "Kiskunfélegyháza",
            "value": "Kiskunfélegyháza"
        },
        {
            "label": "Kiskunhalas",
            "value": "Kiskunhalas"
        },
        {
            "label": "Kiskunlacháza",
            "value": "Kiskunlacháza"
        },
        {
            "label": "Kiskunmajsa",
            "value": "Kiskunmajsa"
        },
        {
            "label": "Kiskutas",
            "value": "Kiskutas"
        },
        {
            "label": "Kisláng",
            "value": "Kisláng"
        },
        {
            "label": "Kisléta",
            "value": "Kisléta"
        },
        {
            "label": "Kislippó",
            "value": "Kislippó"
        },
        {
            "label": "Kislőd",
            "value": "Kislőd"
        },
        {
            "label": "Kismányok",
            "value": "Kismányok"
        },
        {
            "label": "Kismarja",
            "value": "Kismarja"
        },
        {
            "label": "Kismaros",
            "value": "Kismaros"
        },
        {
            "label": "Kisnamény",
            "value": "Kisnamény"
        },
        {
            "label": "Kisnána",
            "value": "Kisnána"
        },
        {
            "label": "Kisnémedi",
            "value": "Kisnémedi"
        },
        {
            "label": "Kisnyárád",
            "value": "Kisnyárád"
        },
        {
            "label": "Kisoroszi",
            "value": "Kisoroszi"
        },
        {
            "label": "Kispalád",
            "value": "Kispalád"
        },
        {
            "label": "Kispáli",
            "value": "Kispáli"
        },
        {
            "label": "Kispirit",
            "value": "Kispirit"
        },
        {
            "label": "Kisrákos",
            "value": "Kisrákos"
        },
        {
            "label": "Kisrécse",
            "value": "Kisrécse"
        },
        {
            "label": "Kisrozvágy",
            "value": "Kisrozvágy"
        },
        {
            "label": "Kissikátor",
            "value": "Kissikátor"
        },
        {
            "label": "Kissomlyó",
            "value": "Kissomlyó"
        },
        {
            "label": "Kisszállás",
            "value": "Kisszállás"
        },
        {
            "label": "Kisszékely",
            "value": "Kisszékely"
        },
        {
            "label": "Kisszekeres",
            "value": "Kisszekeres"
        },
        {
            "label": "Kisszentmárton",
            "value": "Kisszentmárton"
        },
        {
            "label": "Kissziget",
            "value": "Kissziget"
        },
        {
            "label": "Kisszőlős",
            "value": "Kisszőlős"
        },
        {
            "label": "Kistamási",
            "value": "Kistamási"
        },
        {
            "label": "Kistapolca",
            "value": "Kistapolca"
        },
        {
            "label": "Kistarcsa",
            "value": "Kistarcsa"
        },
        {
            "label": "Kistelek",
            "value": "Kistelek"
        },
        {
            "label": "Kistokaj",
            "value": "Kistokaj"
        },
        {
            "label": "Kistolmács",
            "value": "Kistolmács"
        },
        {
            "label": "Kistormás",
            "value": "Kistormás"
        },
        {
            "label": "Kistótfalu",
            "value": "Kistótfalu"
        },
        {
            "label": "Kisújszállás",
            "value": "Kisújszállás"
        },
        {
            "label": "Kisunyom",
            "value": "Kisunyom"
        },
        {
            "label": "Kisvárda",
            "value": "Kisvárda"
        },
        {
            "label": "Kisvarsány",
            "value": "Kisvarsány"
        },
        {
            "label": "Kisvásárhely",
            "value": "Kisvásárhely"
        },
        {
            "label": "Kisvaszar",
            "value": "Kisvaszar"
        },
        {
            "label": "Kisvejke",
            "value": "Kisvejke"
        },
        {
            "label": "Kiszombor",
            "value": "Kiszombor"
        },
        {
            "label": "Kiszsidány",
            "value": "Kiszsidány"
        },
        {
            "label": "Klárafalva",
            "value": "Klárafalva"
        },
        {
            "label": "Kocs",
            "value": "Kocs"
        },
        {
            "label": "Kocsér",
            "value": "Kocsér"
        },
        {
            "label": "Kocsola",
            "value": "Kocsola"
        },
        {
            "label": "Kocsord",
            "value": "Kocsord"
        },
        {
            "label": "Kóka",
            "value": "Kóka"
        },
        {
            "label": "Kokad",
            "value": "Kokad"
        },
        {
            "label": "Kolontár",
            "value": "Kolontár"
        },
        {
            "label": "Komádi",
            "value": "Komádi"
        },
        {
            "label": "Komárom",
            "value": "Komárom"
        },
        {
            "label": "Komjáti",
            "value": "Komjáti"
        },
        {
            "label": "Komló",
            "value": "Komló"
        },
        {
            "label": "Komlódtótfalu",
            "value": "Komlódtótfalu"
        },
        {
            "label": "Komlósd",
            "value": "Komlósd"
        },
        {
            "label": "Komlóska",
            "value": "Komlóska"
        },
        {
            "label": "Komoró",
            "value": "Komoró"
        },
        {
            "label": "Kompolt",
            "value": "Kompolt"
        },
        {
            "label": "Kondó",
            "value": "Kondó"
        },
        {
            "label": "Kondorfa",
            "value": "Kondorfa"
        },
        {
            "label": "Kondoros",
            "value": "Kondoros"
        },
        {
            "label": "Kóny",
            "value": "Kóny"
        },
        {
            "label": "Konyár",
            "value": "Konyár"
        },
        {
            "label": "Kópháza",
            "value": "Kópháza"
        },
        {
            "label": "Koppányszántó",
            "value": "Koppányszántó"
        },
        {
            "label": "Korlát",
            "value": "Korlát"
        },
        {
            "label": "Koroncó",
            "value": "Koroncó"
        },
        {
            "label": "Kórós",
            "value": "Kórós"
        },
        {
            "label": "Kosd",
            "value": "Kosd"
        },
        {
            "label": "Kóspallag",
            "value": "Kóspallag"
        },
        {
            "label": "Kótaj",
            "value": "Kótaj"
        },
        {
            "label": "Kovácshida",
            "value": "Kovácshida"
        },
        {
            "label": "Kovácsszénája",
            "value": "Kovácsszénája"
        },
        {
            "label": "Kovácsvágás",
            "value": "Kovácsvágás"
        },
        {
            "label": "Kozárd",
            "value": "Kozárd"
        },
        {
            "label": "Kozármisleny",
            "value": "Kozármisleny"
        },
        {
            "label": "Kozmadombja",
            "value": "Kozmadombja"
        },
        {
            "label": "Köblény",
            "value": "Köblény"
        },
        {
            "label": "Köcsk",
            "value": "Köcsk"
        },
        {
            "label": "Kökény",
            "value": "Kökény"
        },
        {
            "label": "Kőkút",
            "value": "Kőkút"
        },
        {
            "label": "Kölcse",
            "value": "Kölcse"
        },
        {
            "label": "Kölesd",
            "value": "Kölesd"
        },
        {
            "label": "Kölked",
            "value": "Kölked"
        },
        {
            "label": "Kömlő",
            "value": "Kömlő"
        },
        {
            "label": "Kömlőd",
            "value": "Kömlőd"
        },
        {
            "label": "Kömörő",
            "value": "Kömörő"
        },
        {
            "label": "Kömpöc",
            "value": "Kömpöc"
        },
        {
            "label": "Körmend",
            "value": "Körmend"
        },
        {
            "label": "Környe",
            "value": "Környe"
        },
        {
            "label": "Köröm",
            "value": "Köröm"
        },
        {
            "label": "Kőröshegy",
            "value": "Kőröshegy"
        },
        {
            "label": "Körösladány",
            "value": "Körösladány"
        },
        {
            "label": "Körösnagyharsány",
            "value": "Körösnagyharsány"
        },
        {
            "label": "Körösszakál",
            "value": "Körösszakál"
        },
        {
            "label": "Körösszegapáti",
            "value": "Körösszegapáti"
        },
        {
            "label": "Köröstarcsa",
            "value": "Köröstarcsa"
        },
        {
            "label": "Kőröstetétlen",
            "value": "Kőröstetétlen"
        },
        {
            "label": "Körösújfalu",
            "value": "Körösújfalu"
        },
        {
            "label": "Kőszárhegy",
            "value": "Kőszárhegy"
        },
        {
            "label": "Kőszeg",
            "value": "Kőszeg"
        },
        {
            "label": "Kőszegdoroszló",
            "value": "Kőszegdoroszló"
        },
        {
            "label": "Kőszegpaty",
            "value": "Kőszegpaty"
        },
        {
            "label": "Kőszegszerdahely",
            "value": "Kőszegszerdahely"
        },
        {
            "label": "Kötcse",
            "value": "Kötcse"
        },
        {
            "label": "Kötegyán",
            "value": "Kötegyán"
        },
        {
            "label": "Kőtelek",
            "value": "Kőtelek"
        },
        {
            "label": "Kővágóörs",
            "value": "Kővágóörs"
        },
        {
            "label": "Kővágószőlős",
            "value": "Kővágószőlős"
        },
        {
            "label": "Kővágótöttös",
            "value": "Kővágótöttös"
        },
        {
            "label": "Kövegy",
            "value": "Kövegy"
        },
        {
            "label": "Köveskál",
            "value": "Köveskál"
        },
        {
            "label": "Krasznokvajda",
            "value": "Krasznokvajda"
        },
        {
            "label": "Kulcs",
            "value": "Kulcs"
        },
        {
            "label": "Kunadacs",
            "value": "Kunadacs"
        },
        {
            "label": "Kunágota",
            "value": "Kunágota"
        },
        {
            "label": "Kunbaja",
            "value": "Kunbaja"
        },
        {
            "label": "Kunbaracs",
            "value": "Kunbaracs"
        },
        {
            "label": "Kuncsorba",
            "value": "Kuncsorba"
        },
        {
            "label": "Kunfehértó",
            "value": "Kunfehértó"
        },
        {
            "label": "Kunhegyes",
            "value": "Kunhegyes"
        },
        {
            "label": "Kunmadaras",
            "value": "Kunmadaras"
        },
        {
            "label": "Kunpeszér",
            "value": "Kunpeszér"
        },
        {
            "label": "Kunszállás",
            "value": "Kunszállás"
        },
        {
            "label": "Kunszentmárton",
            "value": "Kunszentmárton"
        },
        {
            "label": "Kunszentmiklós",
            "value": "Kunszentmiklós"
        },
        {
            "label": "Kunsziget",
            "value": "Kunsziget"
        },
        {
            "label": "Kup",
            "value": "Kup"
        },
        {
            "label": "Kupa",
            "value": "Kupa"
        },
        {
            "label": "Kurd",
            "value": "Kurd"
        },
        {
            "label": "Kurityán",
            "value": "Kurityán"
        },
        {
            "label": "Kustánszeg",
            "value": "Kustánszeg"
        },
        {
            "label": "Kutas",
            "value": "Kutas"
        },
        {
            "label": "Kutasó",
            "value": "Kutasó"
        },
        {
            "label": "Kübekháza",
            "value": "Kübekháza"
        },
        {
            "label": "Külsősárd",
            "value": "Külsősárd"
        },
        {
            "label": "Külsővat",
            "value": "Külsővat"
        },
        {
            "label": "Küngös",
            "value": "Küngös"
        },
        {
            "label": "Lábatlan",
            "value": "Lábatlan"
        },
        {
            "label": "Lábod",
            "value": "Lábod"
        },
        {
            "label": "Lácacséke",
            "value": "Lácacséke"
        },
        {
            "label": "Lad",
            "value": "Lad"
        },
        {
            "label": "Ladánybene",
            "value": "Ladánybene"
        },
        {
            "label": "Ládbesenyő",
            "value": "Ládbesenyő"
        },
        {
            "label": "Lajoskomárom",
            "value": "Lajoskomárom"
        },
        {
            "label": "Lajosmizse",
            "value": "Lajosmizse"
        },
        {
            "label": "Lak",
            "value": "Lak"
        },
        {
            "label": "Lakhegy",
            "value": "Lakhegy"
        },
        {
            "label": "Lakitelek",
            "value": "Lakitelek"
        },
        {
            "label": "Lakócsa",
            "value": "Lakócsa"
        },
        {
            "label": "Lánycsók",
            "value": "Lánycsók"
        },
        {
            "label": "Lápafő",
            "value": "Lápafő"
        },
        {
            "label": "Lapáncsa",
            "value": "Lapáncsa"
        },
        {
            "label": "Laskod",
            "value": "Laskod"
        },
        {
            "label": "Lasztonya",
            "value": "Lasztonya"
        },
        {
            "label": "Látrány",
            "value": "Látrány"
        },
        {
            "label": "Lázi",
            "value": "Lázi"
        },
        {
            "label": "Leányfalu",
            "value": "Leányfalu"
        },
        {
            "label": "Leányvár",
            "value": "Leányvár"
        },
        {
            "label": "Lébény",
            "value": "Lébény"
        },
        {
            "label": "Legénd",
            "value": "Legénd"
        },
        {
            "label": "Legyesbénye",
            "value": "Legyesbénye"
        },
        {
            "label": "Léh",
            "value": "Léh"
        },
        {
            "label": "Lénárddaróc",
            "value": "Lénárddaróc"
        },
        {
            "label": "Lendvadedes",
            "value": "Lendvadedes"
        },
        {
            "label": "Lendvajakabfa",
            "value": "Lendvajakabfa"
        },
        {
            "label": "Lengyel",
            "value": "Lengyel"
        },
        {
            "label": "Lengyeltóti",
            "value": "Lengyeltóti"
        },
        {
            "label": "Lenti",
            "value": "Lenti"
        },
        {
            "label": "Lepsény",
            "value": "Lepsény"
        },
        {
            "label": "Lesencefalu",
            "value": "Lesencefalu"
        },
        {
            "label": "Lesenceistvánd",
            "value": "Lesenceistvánd"
        },
        {
            "label": "Lesencetomaj",
            "value": "Lesencetomaj"
        },
        {
            "label": "Létavértes",
            "value": "Létavértes"
        },
        {
            "label": "Letenye",
            "value": "Letenye"
        },
        {
            "label": "Letkés",
            "value": "Letkés"
        },
        {
            "label": "Levél",
            "value": "Levél"
        },
        {
            "label": "Levelek",
            "value": "Levelek"
        },
        {
            "label": "Libickozma",
            "value": "Libickozma"
        },
        {
            "label": "Lickóvadamos",
            "value": "Lickóvadamos"
        },
        {
            "label": "Liget",
            "value": "Liget"
        },
        {
            "label": "Ligetfalva",
            "value": "Ligetfalva"
        },
        {
            "label": "Lipót",
            "value": "Lipót"
        },
        {
            "label": "Lippó",
            "value": "Lippó"
        },
        {
            "label": "Liptód",
            "value": "Liptód"
        },
        {
            "label": "Lispeszentadorján",
            "value": "Lispeszentadorján"
        },
        {
            "label": "Liszó",
            "value": "Liszó"
        },
        {
            "label": "Litér",
            "value": "Litér"
        },
        {
            "label": "Litka",
            "value": "Litka"
        },
        {
            "label": "Litke",
            "value": "Litke"
        },
        {
            "label": "Lócs",
            "value": "Lócs"
        },
        {
            "label": "Lókút",
            "value": "Lókút"
        },
        {
            "label": "Lónya",
            "value": "Lónya"
        },
        {
            "label": "Lórév",
            "value": "Lórév"
        },
        {
            "label": "Lothárd",
            "value": "Lothárd"
        },
        {
            "label": "Lovas",
            "value": "Lovas"
        },
        {
            "label": "Lovasberény",
            "value": "Lovasberény"
        },
        {
            "label": "Lovászhetény",
            "value": "Lovászhetény"
        },
        {
            "label": "Lovászi",
            "value": "Lovászi"
        },
        {
            "label": "Lovászpatona",
            "value": "Lovászpatona"
        },
        {
            "label": "Lőkösháza",
            "value": "Lőkösháza"
        },
        {
            "label": "Lőrinci",
            "value": "Lőrinci"
        },
        {
            "label": "Lövő",
            "value": "Lövő"
        },
        {
            "label": "Lövőpetri",
            "value": "Lövőpetri"
        },
        {
            "label": "Lucfalva",
            "value": "Lucfalva"
        },
        {
            "label": "Ludányhalászi",
            "value": "Ludányhalászi"
        },
        {
            "label": "Ludas",
            "value": "Ludas"
        },
        {
            "label": "Lukácsháza",
            "value": "Lukácsháza"
        },
        {
            "label": "Lulla",
            "value": "Lulla"
        },
        {
            "label": "Lúzsok",
            "value": "Lúzsok"
        },
        {
            "label": "Mád",
            "value": "Mád"
        },
        {
            "label": "Madaras",
            "value": "Madaras"
        },
        {
            "label": "Madocsa",
            "value": "Madocsa"
        },
        {
            "label": "Maglóca",
            "value": "Maglóca"
        },
        {
            "label": "Maglód",
            "value": "Maglód"
        },
        {
            "label": "Mágocs",
            "value": "Mágocs"
        },
        {
            "label": "Magosliget",
            "value": "Magosliget"
        },
        {
            "label": "Magy",
            "value": "Magy"
        },
        {
            "label": "Magyaralmás",
            "value": "Magyaralmás"
        },
        {
            "label": "Magyaratád",
            "value": "Magyaratád"
        },
        {
            "label": "Magyarbánhegyes",
            "value": "Magyarbánhegyes"
        },
        {
            "label": "Magyarbóly",
            "value": "Magyarbóly"
        },
        {
            "label": "Magyarcsanád",
            "value": "Magyarcsanád"
        },
        {
            "label": "Magyardombegyház",
            "value": "Magyardombegyház"
        },
        {
            "label": "Magyaregregy",
            "value": "Magyaregregy"
        },
        {
            "label": "Magyaregres",
            "value": "Magyaregres"
        },
        {
            "label": "Magyarföld",
            "value": "Magyarföld"
        },
        {
            "label": "Magyargéc",
            "value": "Magyargéc"
        },
        {
            "label": "Magyargencs",
            "value": "Magyargencs"
        },
        {
            "label": "Magyarhertelend",
            "value": "Magyarhertelend"
        },
        {
            "label": "Magyarhomorog",
            "value": "Magyarhomorog"
        },
        {
            "label": "Magyarkeresztúr",
            "value": "Magyarkeresztúr"
        },
        {
            "label": "Magyarkeszi",
            "value": "Magyarkeszi"
        },
        {
            "label": "Magyarlak",
            "value": "Magyarlak"
        },
        {
            "label": "Magyarlukafa",
            "value": "Magyarlukafa"
        },
        {
            "label": "Magyarmecske",
            "value": "Magyarmecske"
        },
        {
            "label": "Magyarnádalja",
            "value": "Magyarnádalja"
        },
        {
            "label": "Magyarnándor",
            "value": "Magyarnándor"
        },
        {
            "label": "Magyarpolány",
            "value": "Magyarpolány"
        },
        {
            "label": "Magyarsarlós",
            "value": "Magyarsarlós"
        },
        {
            "label": "Magyarszecsőd",
            "value": "Magyarszecsőd"
        },
        {
            "label": "Magyarszék",
            "value": "Magyarszék"
        },
        {
            "label": "Magyarszentmiklós",
            "value": "Magyarszentmiklós"
        },
        {
            "label": "Magyarszerdahely",
            "value": "Magyarszerdahely"
        },
        {
            "label": "Magyarszombatfa",
            "value": "Magyarszombatfa"
        },
        {
            "label": "Magyartelek",
            "value": "Magyartelek"
        },
        {
            "label": "Majosháza",
            "value": "Majosháza"
        },
        {
            "label": "Majs",
            "value": "Majs"
        },
        {
            "label": "Makád",
            "value": "Makád"
        },
        {
            "label": "Makkoshotyka",
            "value": "Makkoshotyka"
        },
        {
            "label": "Maklár",
            "value": "Maklár"
        },
        {
            "label": "Makó",
            "value": "Makó"
        },
        {
            "label": "Malomsok",
            "value": "Malomsok"
        },
        {
            "label": "Mályi",
            "value": "Mályi"
        },
        {
            "label": "Mályinka",
            "value": "Mályinka"
        },
        {
            "label": "Mánd",
            "value": "Mánd"
        },
        {
            "label": "Mándok",
            "value": "Mándok"
        },
        {
            "label": "Mánfa",
            "value": "Mánfa"
        },
        {
            "label": "Mány",
            "value": "Mány"
        },
        {
            "label": "Maráza",
            "value": "Maráza"
        },
        {
            "label": "Marcalgergelyi",
            "value": "Marcalgergelyi"
        },
        {
            "label": "Marcali",
            "value": "Marcali"
        },
        {
            "label": "Marcaltő",
            "value": "Marcaltő"
        },
        {
            "label": "Márfa",
            "value": "Márfa"
        },
        {
            "label": "Máriahalom",
            "value": "Máriahalom"
        },
        {
            "label": "Máriakálnok",
            "value": "Máriakálnok"
        },
        {
            "label": "Máriakéménd",
            "value": "Máriakéménd"
        },
        {
            "label": "Márianosztra",
            "value": "Márianosztra"
        },
        {
            "label": "Máriapócs",
            "value": "Máriapócs"
        },
        {
            "label": "Markaz",
            "value": "Markaz"
        },
        {
            "label": "Márkháza",
            "value": "Márkháza"
        },
        {
            "label": "Márkó",
            "value": "Márkó"
        },
        {
            "label": "Markóc",
            "value": "Markóc"
        },
        {
            "label": "Markotabödöge",
            "value": "Markotabödöge"
        },
        {
            "label": "Maróc",
            "value": "Maróc"
        },
        {
            "label": "Marócsa",
            "value": "Marócsa"
        },
        {
            "label": "Márok",
            "value": "Márok"
        },
        {
            "label": "Márokföld",
            "value": "Márokföld"
        },
        {
            "label": "Márokpapi",
            "value": "Márokpapi"
        },
        {
            "label": "Maroslele",
            "value": "Maroslele"
        },
        {
            "label": "Mártély",
            "value": "Mártély"
        },
        {
            "label": "Martfű",
            "value": "Martfű"
        },
        {
            "label": "Martonfa",
            "value": "Martonfa"
        },
        {
            "label": "Martonvásár",
            "value": "Martonvásár"
        },
        {
            "label": "Martonyi",
            "value": "Martonyi"
        },
        {
            "label": "Mátészalka",
            "value": "Mátészalka"
        },
        {
            "label": "Mátételke",
            "value": "Mátételke"
        },
        {
            "label": "Mátraballa",
            "value": "Mátraballa"
        },
        {
            "label": "Mátraderecske",
            "value": "Mátraderecske"
        },
        {
            "label": "Mátramindszent",
            "value": "Mátramindszent"
        },
        {
            "label": "Mátranovák",
            "value": "Mátranovák"
        },
        {
            "label": "Mátraszele",
            "value": "Mátraszele"
        },
        {
            "label": "Mátraszentimre",
            "value": "Mátraszentimre"
        },
        {
            "label": "Mátraszőlős",
            "value": "Mátraszőlős"
        },
        {
            "label": "Mátraterenye",
            "value": "Mátraterenye"
        },
        {
            "label": "Mátraverebély",
            "value": "Mátraverebély"
        },
        {
            "label": "Matty",
            "value": "Matty"
        },
        {
            "label": "Mátyásdomb",
            "value": "Mátyásdomb"
        },
        {
            "label": "Mátyus",
            "value": "Mátyus"
        },
        {
            "label": "Máza",
            "value": "Máza"
        },
        {
            "label": "Mecseknádasd",
            "value": "Mecseknádasd"
        },
        {
            "label": "Mecsekpölöske",
            "value": "Mecsekpölöske"
        },
        {
            "label": "Mecsér",
            "value": "Mecsér"
        },
        {
            "label": "Medgyesbodzás",
            "value": "Medgyesbodzás"
        },
        {
            "label": "Medgyesegyháza",
            "value": "Medgyesegyháza"
        },
        {
            "label": "Medina",
            "value": "Medina"
        },
        {
            "label": "Meggyeskovácsi",
            "value": "Meggyeskovácsi"
        },
        {
            "label": "Megyaszó",
            "value": "Megyaszó"
        },
        {
            "label": "Megyehíd",
            "value": "Megyehíd"
        },
        {
            "label": "Megyer",
            "value": "Megyer"
        },
        {
            "label": "Méhkerék",
            "value": "Méhkerék"
        },
        {
            "label": "Méhtelek",
            "value": "Méhtelek"
        },
        {
            "label": "Mekényes",
            "value": "Mekényes"
        },
        {
            "label": "Mélykút",
            "value": "Mélykút"
        },
        {
            "label": "Mencshely",
            "value": "Mencshely"
        },
        {
            "label": "Mende",
            "value": "Mende"
        },
        {
            "label": "Méra",
            "value": "Méra"
        },
        {
            "label": "Merenye",
            "value": "Merenye"
        },
        {
            "label": "Mérges",
            "value": "Mérges"
        },
        {
            "label": "Mérk",
            "value": "Mérk"
        },
        {
            "label": "Mernye",
            "value": "Mernye"
        },
        {
            "label": "Mersevát",
            "value": "Mersevát"
        },
        {
            "label": "Mesterháza",
            "value": "Mesterháza"
        },
        {
            "label": "Mesteri",
            "value": "Mesteri"
        },
        {
            "label": "Mesterszállás",
            "value": "Mesterszállás"
        },
        {
            "label": "Meszes",
            "value": "Meszes"
        },
        {
            "label": "Meszlen",
            "value": "Meszlen"
        },
        {
            "label": "Mesztegnyő",
            "value": "Mesztegnyő"
        },
        {
            "label": "Mezőberény",
            "value": "Mezőberény"
        },
        {
            "label": "Mezőcsát",
            "value": "Mezőcsát"
        },
        {
            "label": "Mezőcsokonya",
            "value": "Mezőcsokonya"
        },
        {
            "label": "Meződ",
            "value": "Meződ"
        },
        {
            "label": "Mezőfalva",
            "value": "Mezőfalva"
        },
        {
            "label": "Mezőgyán",
            "value": "Mezőgyán"
        },
        {
            "label": "Mezőhegyes",
            "value": "Mezőhegyes"
        },
        {
            "label": "Mezőhék",
            "value": "Mezőhék"
        },
        {
            "label": "Mezőkeresztes",
            "value": "Mezőkeresztes"
        },
        {
            "label": "Mezőkomárom",
            "value": "Mezőkomárom"
        },
        {
            "label": "Mezőkovácsháza",
            "value": "Mezőkovácsháza"
        },
        {
            "label": "Mezőkövesd",
            "value": "Mezőkövesd"
        },
        {
            "label": "Mezőladány",
            "value": "Mezőladány"
        },
        {
            "label": "Mezőlak",
            "value": "Mezőlak"
        },
        {
            "label": "Mezőnagymihály",
            "value": "Mezőnagymihály"
        },
        {
            "label": "Mezőnyárád",
            "value": "Mezőnyárád"
        },
        {
            "label": "Mezőörs",
            "value": "Mezőörs"
        },
        {
            "label": "Mezőpeterd",
            "value": "Mezőpeterd"
        },
        {
            "label": "Mezősas",
            "value": "Mezősas"
        },
        {
            "label": "Mezőszemere",
            "value": "Mezőszemere"
        },
        {
            "label": "Mezőszentgyörgy",
            "value": "Mezőszentgyörgy"
        },
        {
            "label": "Mezőszilas",
            "value": "Mezőszilas"
        },
        {
            "label": "Mezőtárkány",
            "value": "Mezőtárkány"
        },
        {
            "label": "Mezőtúr",
            "value": "Mezőtúr"
        },
        {
            "label": "Mezőzombor",
            "value": "Mezőzombor"
        },
        {
            "label": "Miháld",
            "value": "Miháld"
        },
        {
            "label": "Mihályfa",
            "value": "Mihályfa"
        },
        {
            "label": "Mihálygerge",
            "value": "Mihálygerge"
        },
        {
            "label": "Mihályháza",
            "value": "Mihályháza"
        },
        {
            "label": "Mihályi",
            "value": "Mihályi"
        },
        {
            "label": "Mike",
            "value": "Mike"
        },
        {
            "label": "Mikebuda",
            "value": "Mikebuda"
        },
        {
            "label": "Mikekarácsonyfa",
            "value": "Mikekarácsonyfa"
        },
        {
            "label": "Mikepércs",
            "value": "Mikepércs"
        },
        {
            "label": "Miklósi",
            "value": "Miklósi"
        },
        {
            "label": "Mikófalva",
            "value": "Mikófalva"
        },
        {
            "label": "Mikóháza",
            "value": "Mikóháza"
        },
        {
            "label": "Mikosszéplak",
            "value": "Mikosszéplak"
        },
        {
            "label": "Milejszeg",
            "value": "Milejszeg"
        },
        {
            "label": "Milota",
            "value": "Milota"
        },
        {
            "label": "Mindszent",
            "value": "Mindszent"
        },
        {
            "label": "Mindszentgodisa",
            "value": "Mindszentgodisa"
        },
        {
            "label": "Mindszentkálla",
            "value": "Mindszentkálla"
        },
        {
            "label": "Misefa",
            "value": "Misefa"
        },
        {
            "label": "Miske",
            "value": "Miske"
        },
        {
            "label": "Miskolc",
            "value": "Miskolc"
        },
        {
            "label": "Miszla",
            "value": "Miszla"
        },
        {
            "label": "Mocsa",
            "value": "Mocsa"
        },
        {
            "label": "Mogyoród",
            "value": "Mogyoród"
        },
        {
            "label": "Mogyorósbánya",
            "value": "Mogyorósbánya"
        },
        {
            "label": "Mogyoróska",
            "value": "Mogyoróska"
        },
        {
            "label": "Moha",
            "value": "Moha"
        },
        {
            "label": "Mohács",
            "value": "Mohács"
        },
        {
            "label": "Mohora",
            "value": "Mohora"
        },
        {
            "label": "Molnári",
            "value": "Molnári"
        },
        {
            "label": "Molnaszecsőd",
            "value": "Molnaszecsőd"
        },
        {
            "label": "Molvány",
            "value": "Molvány"
        },
        {
            "label": "Monaj",
            "value": "Monaj"
        },
        {
            "label": "Monok",
            "value": "Monok"
        },
        {
            "label": "Monor",
            "value": "Monor"
        },
        {
            "label": "Mónosbél",
            "value": "Mónosbél"
        },
        {
            "label": "Monostorapáti",
            "value": "Monostorapáti"
        },
        {
            "label": "Monostorpályi",
            "value": "Monostorpályi"
        },
        {
            "label": "Monoszló",
            "value": "Monoszló"
        },
        {
            "label": "Monyoród",
            "value": "Monyoród"
        },
        {
            "label": "Mór",
            "value": "Mór"
        },
        {
            "label": "Mórágy",
            "value": "Mórágy"
        },
        {
            "label": "Mórahalom",
            "value": "Mórahalom"
        },
        {
            "label": "Móricgát",
            "value": "Móricgát"
        },
        {
            "label": "Mórichida",
            "value": "Mórichida"
        },
        {
            "label": "Mosdós",
            "value": "Mosdós"
        },
        {
            "label": "Mosonmagyaróvár",
            "value": "Mosonmagyaróvár"
        },
        {
            "label": "Mosonszentmiklós",
            "value": "Mosonszentmiklós"
        },
        {
            "label": "Mosonszolnok",
            "value": "Mosonszolnok"
        },
        {
            "label": "Mozsgó",
            "value": "Mozsgó"
        },
        {
            "label": "Mőcsény",
            "value": "Mőcsény"
        },
        {
            "label": "Mucsfa",
            "value": "Mucsfa"
        },
        {
            "label": "Mucsi",
            "value": "Mucsi"
        },
        {
            "label": "Múcsony",
            "value": "Múcsony"
        },
        {
            "label": "Muhi",
            "value": "Muhi"
        },
        {
            "label": "Murakeresztúr",
            "value": "Murakeresztúr"
        },
        {
            "label": "Murarátka",
            "value": "Murarátka"
        },
        {
            "label": "Muraszemenye",
            "value": "Muraszemenye"
        },
        {
            "label": "Murga",
            "value": "Murga"
        },
        {
            "label": "Murony",
            "value": "Murony"
        },
        {
            "label": "Nábrád",
            "value": "Nábrád"
        },
        {
            "label": "Nadap",
            "value": "Nadap"
        },
        {
            "label": "Nádasd",
            "value": "Nádasd"
        },
        {
            "label": "Nádasdladány",
            "value": "Nádasdladány"
        },
        {
            "label": "Nádudvar",
            "value": "Nádudvar"
        },
        {
            "label": "Nágocs",
            "value": "Nágocs"
        },
        {
            "label": "Nagyacsád",
            "value": "Nagyacsád"
        },
        {
            "label": "Nagyalásony",
            "value": "Nagyalásony"
        },
        {
            "label": "Nagyar",
            "value": "Nagyar"
        },
        {
            "label": "Nagyatád",
            "value": "Nagyatád"
        },
        {
            "label": "Nagybajcs",
            "value": "Nagybajcs"
        },
        {
            "label": "Nagybajom",
            "value": "Nagybajom"
        },
        {
            "label": "Nagybakónak",
            "value": "Nagybakónak"
        },
        {
            "label": "Nagybánhegyes",
            "value": "Nagybánhegyes"
        },
        {
            "label": "Nagybaracska",
            "value": "Nagybaracska"
        },
        {
            "label": "Nagybarca",
            "value": "Nagybarca"
        },
        {
            "label": "Nagybárkány",
            "value": "Nagybárkány"
        },
        {
            "label": "Nagyberény",
            "value": "Nagyberény"
        },
        {
            "label": "Nagyberki",
            "value": "Nagyberki"
        },
        {
            "label": "Nagybörzsöny",
            "value": "Nagybörzsöny"
        },
        {
            "label": "Nagybudmér",
            "value": "Nagybudmér"
        },
        {
            "label": "Nagycenk",
            "value": "Nagycenk"
        },
        {
            "label": "Nagycsány",
            "value": "Nagycsány"
        },
        {
            "label": "Nagycsécs",
            "value": "Nagycsécs"
        },
        {
            "label": "Nagycsepely",
            "value": "Nagycsepely"
        },
        {
            "label": "Nagycserkesz",
            "value": "Nagycserkesz"
        },
        {
            "label": "Nagydém",
            "value": "Nagydém"
        },
        {
            "label": "Nagydobos",
            "value": "Nagydobos"
        },
        {
            "label": "Nagydobsza",
            "value": "Nagydobsza"
        },
        {
            "label": "Nagydorog",
            "value": "Nagydorog"
        },
        {
            "label": "Nagyecsed",
            "value": "Nagyecsed"
        },
        {
            "label": "Nagyér",
            "value": "Nagyér"
        },
        {
            "label": "Nagyesztergár",
            "value": "Nagyesztergár"
        },
        {
            "label": "Nagyfüged",
            "value": "Nagyfüged"
        },
        {
            "label": "Nagygeresd",
            "value": "Nagygeresd"
        },
        {
            "label": "Nagygörbő",
            "value": "Nagygörbő"
        },
        {
            "label": "Nagygyimót",
            "value": "Nagygyimót"
        },
        {
            "label": "Nagyhajmás",
            "value": "Nagyhajmás"
        },
        {
            "label": "Nagyhalász",
            "value": "Nagyhalász"
        },
        {
            "label": "Nagyharsány",
            "value": "Nagyharsány"
        },
        {
            "label": "Nagyhegyes",
            "value": "Nagyhegyes"
        },
        {
            "label": "Nagyhódos",
            "value": "Nagyhódos"
        },
        {
            "label": "Nagyhuta",
            "value": "Nagyhuta"
        },
        {
            "label": "Nagyigmánd",
            "value": "Nagyigmánd"
        },
        {
            "label": "Nagyiván",
            "value": "Nagyiván"
        },
        {
            "label": "Nagykálló",
            "value": "Nagykálló"
        },
        {
            "label": "Nagykamarás",
            "value": "Nagykamarás"
        },
        {
            "label": "Nagykanizsa",
            "value": "Nagykanizsa"
        },
        {
            "label": "Nagykapornak",
            "value": "Nagykapornak"
        },
        {
            "label": "Nagykarácsony",
            "value": "Nagykarácsony"
        },
        {
            "label": "Nagykáta",
            "value": "Nagykáta"
        },
        {
            "label": "Nagykereki",
            "value": "Nagykereki"
        },
        {
            "label": "Nagykinizs",
            "value": "Nagykinizs"
        },
        {
            "label": "Nagykónyi",
            "value": "Nagykónyi"
        },
        {
            "label": "Nagykorpád",
            "value": "Nagykorpád"
        },
        {
            "label": "Nagykovácsi",
            "value": "Nagykovácsi"
        },
        {
            "label": "Nagykozár",
            "value": "Nagykozár"
        },
        {
            "label": "Nagykökényes",
            "value": "Nagykökényes"
        },
        {
            "label": "Nagykölked",
            "value": "Nagykölked"
        },
        {
            "label": "Nagykőrös",
            "value": "Nagykőrös"
        },
        {
            "label": "Nagykörű",
            "value": "Nagykörű"
        },
        {
            "label": "Nagykutas",
            "value": "Nagykutas"
        },
        {
            "label": "Nagylak",
            "value": "Nagylak"
        },
        {
            "label": "Nagylengyel",
            "value": "Nagylengyel"
        },
        {
            "label": "Nagylóc",
            "value": "Nagylóc"
        },
        {
            "label": "Nagylók",
            "value": "Nagylók"
        },
        {
            "label": "Nagylózs",
            "value": "Nagylózs"
        },
        {
            "label": "Nagymágocs",
            "value": "Nagymágocs"
        },
        {
            "label": "Nagymányok",
            "value": "Nagymányok"
        },
        {
            "label": "Nagymaros",
            "value": "Nagymaros"
        },
        {
            "label": "Nagymizdó",
            "value": "Nagymizdó"
        },
        {
            "label": "Nagynyárád",
            "value": "Nagynyárád"
        },
        {
            "label": "Nagyoroszi",
            "value": "Nagyoroszi"
        },
        {
            "label": "Nagypáli",
            "value": "Nagypáli"
        },
        {
            "label": "Nagypall",
            "value": "Nagypall"
        },
        {
            "label": "Nagypeterd",
            "value": "Nagypeterd"
        },
        {
            "label": "Nagypirit",
            "value": "Nagypirit"
        },
        {
            "label": "Nagyrábé",
            "value": "Nagyrábé"
        },
        {
            "label": "Nagyrada",
            "value": "Nagyrada"
        },
        {
            "label": "Nagyrákos",
            "value": "Nagyrákos"
        },
        {
            "label": "Nagyrécse",
            "value": "Nagyrécse"
        },
        {
            "label": "Nagyréde",
            "value": "Nagyréde"
        },
        {
            "label": "Nagyrév",
            "value": "Nagyrév"
        },
        {
            "label": "Nagyrozvágy",
            "value": "Nagyrozvágy"
        },
        {
            "label": "Nagysáp",
            "value": "Nagysáp"
        },
        {
            "label": "Nagysimonyi",
            "value": "Nagysimonyi"
        },
        {
            "label": "Nagyszakácsi",
            "value": "Nagyszakácsi"
        },
        {
            "label": "Nagyszékely",
            "value": "Nagyszékely"
        },
        {
            "label": "Nagyszekeres",
            "value": "Nagyszekeres"
        },
        {
            "label": "Nagyszénás",
            "value": "Nagyszénás"
        },
        {
            "label": "Nagyszentjános",
            "value": "Nagyszentjános"
        },
        {
            "label": "Nagyszokoly",
            "value": "Nagyszokoly"
        },
        {
            "label": "Nagytálya",
            "value": "Nagytálya"
        },
        {
            "label": "Nagytarcsa",
            "value": "Nagytarcsa"
        },
        {
            "label": "Nagytevel",
            "value": "Nagytevel"
        },
        {
            "label": "Nagytilaj",
            "value": "Nagytilaj"
        },
        {
            "label": "Nagytótfalu",
            "value": "Nagytótfalu"
        },
        {
            "label": "Nagytőke",
            "value": "Nagytőke"
        },
        {
            "label": "Nagyút",
            "value": "Nagyút"
        },
        {
            "label": "Nagyvarsány",
            "value": "Nagyvarsány"
        },
        {
            "label": "Nagyváty",
            "value": "Nagyváty"
        },
        {
            "label": "Nagyvázsony",
            "value": "Nagyvázsony"
        },
        {
            "label": "Nagyvejke",
            "value": "Nagyvejke"
        },
        {
            "label": "Nagyveleg",
            "value": "Nagyveleg"
        },
        {
            "label": "Nagyvenyim",
            "value": "Nagyvenyim"
        },
        {
            "label": "Nagyvisnyó",
            "value": "Nagyvisnyó"
        },
        {
            "label": "Nak",
            "value": "Nak"
        },
        {
            "label": "Napkor",
            "value": "Napkor"
        },
        {
            "label": "Nárai",
            "value": "Nárai"
        },
        {
            "label": "Narda",
            "value": "Narda"
        },
        {
            "label": "Naszály",
            "value": "Naszály"
        },
        {
            "label": "Négyes",
            "value": "Négyes"
        },
        {
            "label": "Nekézseny",
            "value": "Nekézseny"
        },
        {
            "label": "Nemesapáti",
            "value": "Nemesapáti"
        },
        {
            "label": "Nemesbikk",
            "value": "Nemesbikk"
        },
        {
            "label": "Nemesborzova",
            "value": "Nemesborzova"
        },
        {
            "label": "Nemesbőd",
            "value": "Nemesbőd"
        },
        {
            "label": "Nemesbük",
            "value": "Nemesbük"
        },
        {
            "label": "Nemescsó",
            "value": "Nemescsó"
        },
        {
            "label": "Nemesdéd",
            "value": "Nemesdéd"
        },
        {
            "label": "Nemesgörzsöny",
            "value": "Nemesgörzsöny"
        },
        {
            "label": "Nemesgulács",
            "value": "Nemesgulács"
        },
        {
            "label": "Nemeshany",
            "value": "Nemeshany"
        },
        {
            "label": "Nemeshetés",
            "value": "Nemeshetés"
        },
        {
            "label": "Nemeske",
            "value": "Nemeske"
        },
        {
            "label": "Nemeskér",
            "value": "Nemeskér"
        },
        {
            "label": "Nemeskeresztúr",
            "value": "Nemeskeresztúr"
        },
        {
            "label": "Nemeskisfalud",
            "value": "Nemeskisfalud"
        },
        {
            "label": "Nemeskocs",
            "value": "Nemeskocs"
        },
        {
            "label": "Nemeskolta",
            "value": "Nemeskolta"
        },
        {
            "label": "Nemesládony",
            "value": "Nemesládony"
        },
        {
            "label": "Nemesmedves",
            "value": "Nemesmedves"
        },
        {
            "label": "Nemesnádudvar",
            "value": "Nemesnádudvar"
        },
        {
            "label": "Nemesnép",
            "value": "Nemesnép"
        },
        {
            "label": "Nemespátró",
            "value": "Nemespátró"
        },
        {
            "label": "Nemesrádó",
            "value": "Nemesrádó"
        },
        {
            "label": "Nemesrempehollós",
            "value": "Nemesrempehollós"
        },
        {
            "label": "Nemessándorháza",
            "value": "Nemessándorháza"
        },
        {
            "label": "Nemesszalók",
            "value": "Nemesszalók"
        },
        {
            "label": "Nemesszentandrás",
            "value": "Nemesszentandrás"
        },
        {
            "label": "Nemesvámos",
            "value": "Nemesvámos"
        },
        {
            "label": "Nemesvid",
            "value": "Nemesvid"
        },
        {
            "label": "Nemesvita",
            "value": "Nemesvita"
        },
        {
            "label": "Németbánya",
            "value": "Németbánya"
        },
        {
            "label": "Németfalu",
            "value": "Németfalu"
        },
        {
            "label": "Németkér",
            "value": "Németkér"
        },
        {
            "label": "Nemti",
            "value": "Nemti"
        },
        {
            "label": "Neszmély",
            "value": "Neszmély"
        },
        {
            "label": "Nézsa",
            "value": "Nézsa"
        },
        {
            "label": "Nick",
            "value": "Nick"
        },
        {
            "label": "Nikla",
            "value": "Nikla"
        },
        {
            "label": "Nógrád",
            "value": "Nógrád"
        },
        {
            "label": "Nógrádkövesd",
            "value": "Nógrádkövesd"
        },
        {
            "label": "Nógrádmarcal",
            "value": "Nógrádmarcal"
        },
        {
            "label": "Nógrádmegyer",
            "value": "Nógrádmegyer"
        },
        {
            "label": "Nógrádsáp",
            "value": "Nógrádsáp"
        },
        {
            "label": "Nógrádsipek",
            "value": "Nógrádsipek"
        },
        {
            "label": "Nógrádszakál",
            "value": "Nógrádszakál"
        },
        {
            "label": "Nóráp",
            "value": "Nóráp"
        },
        {
            "label": "Noszlop",
            "value": "Noszlop"
        },
        {
            "label": "Noszvaj",
            "value": "Noszvaj"
        },
        {
            "label": "Nova",
            "value": "Nova"
        },
        {
            "label": "Novaj",
            "value": "Novaj"
        },
        {
            "label": "Novajidrány",
            "value": "Novajidrány"
        },
        {
            "label": "Nőtincs",
            "value": "Nőtincs"
        },
        {
            "label": "Nyalka",
            "value": "Nyalka"
        },
        {
            "label": "Nyárád",
            "value": "Nyárád"
        },
        {
            "label": "Nyáregyháza",
            "value": "Nyáregyháza"
        },
        {
            "label": "Nyárlőrinc",
            "value": "Nyárlőrinc"
        },
        {
            "label": "Nyársapát",
            "value": "Nyársapát"
        },
        {
            "label": "Nyékládháza",
            "value": "Nyékládháza"
        },
        {
            "label": "Nyergesújfalu",
            "value": "Nyergesújfalu"
        },
        {
            "label": "Nyésta",
            "value": "Nyésta"
        },
        {
            "label": "Nyim",
            "value": "Nyim"
        },
        {
            "label": "Nyírábrány",
            "value": "Nyírábrány"
        },
        {
            "label": "Nyíracsád",
            "value": "Nyíracsád"
        },
        {
            "label": "Nyirád",
            "value": "Nyirád"
        },
        {
            "label": "Nyíradony",
            "value": "Nyíradony"
        },
        {
            "label": "Nyírbátor",
            "value": "Nyírbátor"
        },
        {
            "label": "Nyírbéltek",
            "value": "Nyírbéltek"
        },
        {
            "label": "Nyírbogát",
            "value": "Nyírbogát"
        },
        {
            "label": "Nyírbogdány",
            "value": "Nyírbogdány"
        },
        {
            "label": "Nyírcsaholy",
            "value": "Nyírcsaholy"
        },
        {
            "label": "Nyírcsászári",
            "value": "Nyírcsászári"
        },
        {
            "label": "Nyírderzs",
            "value": "Nyírderzs"
        },
        {
            "label": "Nyíregyháza",
            "value": "Nyíregyháza"
        },
        {
            "label": "Nyírgelse",
            "value": "Nyírgelse"
        },
        {
            "label": "Nyírgyulaj",
            "value": "Nyírgyulaj"
        },
        {
            "label": "Nyíri",
            "value": "Nyíri"
        },
        {
            "label": "Nyíribrony",
            "value": "Nyíribrony"
        },
        {
            "label": "Nyírjákó",
            "value": "Nyírjákó"
        },
        {
            "label": "Nyírkarász",
            "value": "Nyírkarász"
        },
        {
            "label": "Nyírkáta",
            "value": "Nyírkáta"
        },
        {
            "label": "Nyírkércs",
            "value": "Nyírkércs"
        },
        {
            "label": "Nyírlövő",
            "value": "Nyírlövő"
        },
        {
            "label": "Nyírlugos",
            "value": "Nyírlugos"
        },
        {
            "label": "Nyírmada",
            "value": "Nyírmada"
        },
        {
            "label": "Nyírmártonfalva",
            "value": "Nyírmártonfalva"
        },
        {
            "label": "Nyírmeggyes",
            "value": "Nyírmeggyes"
        },
        {
            "label": "Nyírmihálydi",
            "value": "Nyírmihálydi"
        },
        {
            "label": "Nyírparasznya",
            "value": "Nyírparasznya"
        },
        {
            "label": "Nyírpazony",
            "value": "Nyírpazony"
        },
        {
            "label": "Nyírpilis",
            "value": "Nyírpilis"
        },
        {
            "label": "Nyírtass",
            "value": "Nyírtass"
        },
        {
            "label": "Nyírtelek",
            "value": "Nyírtelek"
        },
        {
            "label": "Nyírtét",
            "value": "Nyírtét"
        },
        {
            "label": "Nyírtura",
            "value": "Nyírtura"
        },
        {
            "label": "Nyírvasvári",
            "value": "Nyírvasvári"
        },
        {
            "label": "Nyomár",
            "value": "Nyomár"
        },
        {
            "label": "Nyőgér",
            "value": "Nyőgér"
        },
        {
            "label": "Nyugotszenterzsébet",
            "value": "Nyugotszenterzsébet"
        },
        {
            "label": "Nyúl",
            "value": "Nyúl"
        },
        {
            "label": "Óbánya",
            "value": "Óbánya"
        },
        {
            "label": "Óbudavár",
            "value": "Óbudavár"
        },
        {
            "label": "Ócsa",
            "value": "Ócsa"
        },
        {
            "label": "Ócsárd",
            "value": "Ócsárd"
        },
        {
            "label": "Ófalu",
            "value": "Ófalu"
        },
        {
            "label": "Ófehértó",
            "value": "Ófehértó"
        },
        {
            "label": "Óföldeák",
            "value": "Óföldeák"
        },
        {
            "label": "Óhíd",
            "value": "Óhíd"
        },
        {
            "label": "Okány",
            "value": "Okány"
        },
        {
            "label": "Okorág",
            "value": "Okorág"
        },
        {
            "label": "Okorvölgy",
            "value": "Okorvölgy"
        },
        {
            "label": "Olasz",
            "value": "Olasz"
        },
        {
            "label": "Olaszfa",
            "value": "Olaszfa"
        },
        {
            "label": "Olaszfalu",
            "value": "Olaszfalu"
        },
        {
            "label": "Olaszliszka",
            "value": "Olaszliszka"
        },
        {
            "label": "Olcsva",
            "value": "Olcsva"
        },
        {
            "label": "Olcsvaapáti",
            "value": "Olcsvaapáti"
        },
        {
            "label": "Old",
            "value": "Old"
        },
        {
            "label": "Ólmod",
            "value": "Ólmod"
        },
        {
            "label": "Oltárc",
            "value": "Oltárc"
        },
        {
            "label": "Onga",
            "value": "Onga"
        },
        {
            "label": "Ónod",
            "value": "Ónod"
        },
        {
            "label": "Ópályi",
            "value": "Ópályi"
        },
        {
            "label": "Ópusztaszer",
            "value": "Ópusztaszer"
        },
        {
            "label": "Orbányosfa",
            "value": "Orbányosfa"
        },
        {
            "label": "Orci",
            "value": "Orci"
        },
        {
            "label": "Ordacsehi",
            "value": "Ordacsehi"
        },
        {
            "label": "Ordas",
            "value": "Ordas"
        },
        {
            "label": "Orfalu",
            "value": "Orfalu"
        },
        {
            "label": "Orfű",
            "value": "Orfű"
        },
        {
            "label": "Orgovány",
            "value": "Orgovány"
        },
        {
            "label": "Ormándlak",
            "value": "Ormándlak"
        },
        {
            "label": "Ormosbánya",
            "value": "Ormosbánya"
        },
        {
            "label": "Orosháza",
            "value": "Orosháza"
        },
        {
            "label": "Oroszi",
            "value": "Oroszi"
        },
        {
            "label": "Oroszlány",
            "value": "Oroszlány"
        },
        {
            "label": "Oroszló",
            "value": "Oroszló"
        },
        {
            "label": "Orosztony",
            "value": "Orosztony"
        },
        {
            "label": "Ortaháza",
            "value": "Ortaháza"
        },
        {
            "label": "Osli",
            "value": "Osli"
        },
        {
            "label": "Ostffyasszonyfa",
            "value": "Ostffyasszonyfa"
        },
        {
            "label": "Ostoros",
            "value": "Ostoros"
        },
        {
            "label": "Oszkó",
            "value": "Oszkó"
        },
        {
            "label": "Oszlár",
            "value": "Oszlár"
        },
        {
            "label": "Osztopán",
            "value": "Osztopán"
        },
        {
            "label": "Ózd",
            "value": "Ózd"
        },
        {
            "label": "Ózdfalu",
            "value": "Ózdfalu"
        },
        {
            "label": "Ozmánbük",
            "value": "Ozmánbük"
        },
        {
            "label": "Ozora",
            "value": "Ozora"
        },
        {
            "label": "Öcs",
            "value": "Öcs"
        },
        {
            "label": "Őcsény",
            "value": "Őcsény"
        },
        {
            "label": "Öcsöd",
            "value": "Öcsöd"
        },
        {
            "label": "Ököritófülpös",
            "value": "Ököritófülpös"
        },
        {
            "label": "Ölbő",
            "value": "Ölbő"
        },
        {
            "label": "Ömböly",
            "value": "Ömböly"
        },
        {
            "label": "Őr",
            "value": "Őr"
        },
        {
            "label": "Őrbottyán",
            "value": "Őrbottyán"
        },
        {
            "label": "Öregcsertő",
            "value": "Öregcsertő"
        },
        {
            "label": "Öreglak",
            "value": "Öreglak"
        },
        {
            "label": "Őrhalom",
            "value": "Őrhalom"
        },
        {
            "label": "Őrimagyarósd",
            "value": "Őrimagyarósd"
        },
        {
            "label": "Őriszentpéter",
            "value": "Őriszentpéter"
        },
        {
            "label": "Örkény",
            "value": "Örkény"
        },
        {
            "label": "Örményes",
            "value": "Örményes"
        },
        {
            "label": "Örménykút",
            "value": "Örménykút"
        },
        {
            "label": "Őrtilos",
            "value": "Őrtilos"
        },
        {
            "label": "Örvényes",
            "value": "Örvényes"
        },
        {
            "label": "Ősagárd",
            "value": "Ősagárd"
        },
        {
            "label": "Ősi",
            "value": "Ősi"
        },
        {
            "label": "Öskü",
            "value": "Öskü"
        },
        {
            "label": "Öttevény",
            "value": "Öttevény"
        },
        {
            "label": "Öttömös",
            "value": "Öttömös"
        },
        {
            "label": "Ötvöskónyi",
            "value": "Ötvöskónyi"
        },
        {
            "label": "Pácin",
            "value": "Pácin"
        },
        {
            "label": "Pacsa",
            "value": "Pacsa"
        },
        {
            "label": "Pácsony",
            "value": "Pácsony"
        },
        {
            "label": "Padár",
            "value": "Padár"
        },
        {
            "label": "Páhi",
            "value": "Páhi"
        },
        {
            "label": "Páka",
            "value": "Páka"
        },
        {
            "label": "Pakod",
            "value": "Pakod"
        },
        {
            "label": "Pákozd",
            "value": "Pákozd"
        },
        {
            "label": "Paks",
            "value": "Paks"
        },
        {
            "label": "Palé",
            "value": "Palé"
        },
        {
            "label": "Pálfa",
            "value": "Pálfa"
        },
        {
            "label": "Pálfiszeg",
            "value": "Pálfiszeg"
        },
        {
            "label": "Pálháza",
            "value": "Pálháza"
        },
        {
            "label": "Páli",
            "value": "Páli"
        },
        {
            "label": "Palkonya",
            "value": "Palkonya"
        },
        {
            "label": "Pálmajor",
            "value": "Pálmajor"
        },
        {
            "label": "Pálmonostora",
            "value": "Pálmonostora"
        },
        {
            "label": "Palotabozsok",
            "value": "Palotabozsok"
        },
        {
            "label": "Palotás",
            "value": "Palotás"
        },
        {
            "label": "Paloznak",
            "value": "Paloznak"
        },
        {
            "label": "Pamlény",
            "value": "Pamlény"
        },
        {
            "label": "Pamuk",
            "value": "Pamuk"
        },
        {
            "label": "Pánd",
            "value": "Pánd"
        },
        {
            "label": "Pankasz",
            "value": "Pankasz"
        },
        {
            "label": "Pannonhalma",
            "value": "Pannonhalma"
        },
        {
            "label": "Pányok",
            "value": "Pányok"
        },
        {
            "label": "Panyola",
            "value": "Panyola"
        },
        {
            "label": "Pap",
            "value": "Pap"
        },
        {
            "label": "Pápa",
            "value": "Pápa"
        },
        {
            "label": "Pápadereske",
            "value": "Pápadereske"
        },
        {
            "label": "Pápakovácsi",
            "value": "Pápakovácsi"
        },
        {
            "label": "Pápasalamon",
            "value": "Pápasalamon"
        },
        {
            "label": "Pápateszér",
            "value": "Pápateszér"
        },
        {
            "label": "Papkeszi",
            "value": "Papkeszi"
        },
        {
            "label": "Pápoc",
            "value": "Pápoc"
        },
        {
            "label": "Papos",
            "value": "Papos"
        },
        {
            "label": "Páprád",
            "value": "Páprád"
        },
        {
            "label": "Parád",
            "value": "Parád"
        },
        {
            "label": "Parádsasvár",
            "value": "Parádsasvár"
        },
        {
            "label": "Parasznya",
            "value": "Parasznya"
        },
        {
            "label": "Paszab",
            "value": "Paszab"
        },
        {
            "label": "Pásztó",
            "value": "Pásztó"
        },
        {
            "label": "Pásztori",
            "value": "Pásztori"
        },
        {
            "label": "Pat",
            "value": "Pat"
        },
        {
            "label": "Patak",
            "value": "Patak"
        },
        {
            "label": "Patalom",
            "value": "Patalom"
        },
        {
            "label": "Patapoklosi",
            "value": "Patapoklosi"
        },
        {
            "label": "Patca",
            "value": "Patca"
        },
        {
            "label": "Pátka",
            "value": "Pátka"
        },
        {
            "label": "Patosfa",
            "value": "Patosfa"
        },
        {
            "label": "Pátroha",
            "value": "Pátroha"
        },
        {
            "label": "Patvarc",
            "value": "Patvarc"
        },
        {
            "label": "Páty",
            "value": "Páty"
        },
        {
            "label": "Pátyod",
            "value": "Pátyod"
        },
        {
            "label": "Pázmánd",
            "value": "Pázmánd"
        },
        {
            "label": "Pázmándfalu",
            "value": "Pázmándfalu"
        },
        {
            "label": "Pécel",
            "value": "Pécel"
        },
        {
            "label": "Pecöl",
            "value": "Pecöl"
        },
        {
            "label": "Pécs",
            "value": "Pécs"
        },
        {
            "label": "Pécsbagota",
            "value": "Pécsbagota"
        },
        {
            "label": "Pécsdevecser",
            "value": "Pécsdevecser"
        },
        {
            "label": "Pécsely",
            "value": "Pécsely"
        },
        {
            "label": "Pécsudvard",
            "value": "Pécsudvard"
        },
        {
            "label": "Pécsvárad",
            "value": "Pécsvárad"
        },
        {
            "label": "Pellérd",
            "value": "Pellérd"
        },
        {
            "label": "Pély",
            "value": "Pély"
        },
        {
            "label": "Penc",
            "value": "Penc"
        },
        {
            "label": "Penészlek",
            "value": "Penészlek"
        },
        {
            "label": "Penyige",
            "value": "Penyige"
        },
        {
            "label": "Pénzesgyőr",
            "value": "Pénzesgyőr"
        },
        {
            "label": "Pér",
            "value": "Pér"
        },
        {
            "label": "Perbál",
            "value": "Perbál"
        },
        {
            "label": "Pere",
            "value": "Pere"
        },
        {
            "label": "Perecse",
            "value": "Perecse"
        },
        {
            "label": "Pereked",
            "value": "Pereked"
        },
        {
            "label": "Perenye",
            "value": "Perenye"
        },
        {
            "label": "Peresznye",
            "value": "Peresznye"
        },
        {
            "label": "Pereszteg",
            "value": "Pereszteg"
        },
        {
            "label": "Perkáta",
            "value": "Perkáta"
        },
        {
            "label": "Perkupa",
            "value": "Perkupa"
        },
        {
            "label": "Perőcsény",
            "value": "Perőcsény"
        },
        {
            "label": "Peterd",
            "value": "Peterd"
        },
        {
            "label": "Péterhida",
            "value": "Péterhida"
        },
        {
            "label": "Péteri",
            "value": "Péteri"
        },
        {
            "label": "Pétervására",
            "value": "Pétervására"
        },
        {
            "label": "Pétfürdő",
            "value": "Pétfürdő"
        },
        {
            "label": "Pethőhenye",
            "value": "Pethőhenye"
        },
        {
            "label": "Petneháza",
            "value": "Petneháza"
        },
        {
            "label": "Petőfibánya",
            "value": "Petőfibánya"
        },
        {
            "label": "Petőfiszállás",
            "value": "Petőfiszállás"
        },
        {
            "label": "Petőháza",
            "value": "Petőháza"
        },
        {
            "label": "Petőmihályfa",
            "value": "Petőmihályfa"
        },
        {
            "label": "Petrikeresztúr",
            "value": "Petrikeresztúr"
        },
        {
            "label": "Petrivente",
            "value": "Petrivente"
        },
        {
            "label": "Pettend",
            "value": "Pettend"
        },
        {
            "label": "Piliny",
            "value": "Piliny"
        },
        {
            "label": "Pilis",
            "value": "Pilis"
        },
        {
            "label": "Pilisborosjenő",
            "value": "Pilisborosjenő"
        },
        {
            "label": "Piliscsaba",
            "value": "Piliscsaba"
        },
        {
            "label": "Piliscsév",
            "value": "Piliscsév"
        },
        {
            "label": "Pilisjászfalu",
            "value": "Pilisjászfalu"
        },
        {
            "label": "Pilismarót",
            "value": "Pilismarót"
        },
        {
            "label": "Pilisszántó",
            "value": "Pilisszántó"
        },
        {
            "label": "Pilisszentiván",
            "value": "Pilisszentiván"
        },
        {
            "label": "Pilisszentkereszt",
            "value": "Pilisszentkereszt"
        },
        {
            "label": "Pilisszentlászló",
            "value": "Pilisszentlászló"
        },
        {
            "label": "Pilisvörösvár",
            "value": "Pilisvörösvár"
        },
        {
            "label": "Pincehely",
            "value": "Pincehely"
        },
        {
            "label": "Pinkamindszent",
            "value": "Pinkamindszent"
        },
        {
            "label": "Pinnye",
            "value": "Pinnye"
        },
        {
            "label": "Piricse",
            "value": "Piricse"
        },
        {
            "label": "Pirtó",
            "value": "Pirtó"
        },
        {
            "label": "Piskó",
            "value": "Piskó"
        },
        {
            "label": "Pitvaros",
            "value": "Pitvaros"
        },
        {
            "label": "Pócsa",
            "value": "Pócsa"
        },
        {
            "label": "Pocsaj",
            "value": "Pocsaj"
        },
        {
            "label": "Pócsmegyer",
            "value": "Pócsmegyer"
        },
        {
            "label": "Pócspetri",
            "value": "Pócspetri"
        },
        {
            "label": "Pogány",
            "value": "Pogány"
        },
        {
            "label": "Pogányszentpéter",
            "value": "Pogányszentpéter"
        },
        {
            "label": "Pókaszepetk",
            "value": "Pókaszepetk"
        },
        {
            "label": "Polány",
            "value": "Polány"
        },
        {
            "label": "Polgár",
            "value": "Polgár"
        },
        {
            "label": "Polgárdi",
            "value": "Polgárdi"
        },
        {
            "label": "Pomáz",
            "value": "Pomáz"
        },
        {
            "label": "Porcsalma",
            "value": "Porcsalma"
        },
        {
            "label": "Pornóapáti",
            "value": "Pornóapáti"
        },
        {
            "label": "Poroszló",
            "value": "Poroszló"
        },
        {
            "label": "Porpác",
            "value": "Porpác"
        },
        {
            "label": "Porrog",
            "value": "Porrog"
        },
        {
            "label": "Porrogszentkirály",
            "value": "Porrogszentkirály"
        },
        {
            "label": "Porrogszentpál",
            "value": "Porrogszentpál"
        },
        {
            "label": "Pórszombat",
            "value": "Pórszombat"
        },
        {
            "label": "Porva",
            "value": "Porva"
        },
        {
            "label": "Pósfa",
            "value": "Pósfa"
        },
        {
            "label": "Potony",
            "value": "Potony"
        },
        {
            "label": "Potyond",
            "value": "Potyond"
        },
        {
            "label": "Pölöske",
            "value": "Pölöske"
        },
        {
            "label": "Pölöskefő",
            "value": "Pölöskefő"
        },
        {
            "label": "Pörböly",
            "value": "Pörböly"
        },
        {
            "label": "Pördefölde",
            "value": "Pördefölde"
        },
        {
            "label": "Pötréte",
            "value": "Pötréte"
        },
        {
            "label": "Prügy",
            "value": "Prügy"
        },
        {
            "label": "Pula",
            "value": "Pula"
        },
        {
            "label": "Pusztaapáti",
            "value": "Pusztaapáti"
        },
        {
            "label": "Pusztaberki",
            "value": "Pusztaberki"
        },
        {
            "label": "Pusztacsalád",
            "value": "Pusztacsalád"
        },
        {
            "label": "Pusztacsó",
            "value": "Pusztacsó"
        },
        {
            "label": "Pusztadobos",
            "value": "Pusztadobos"
        },
        {
            "label": "Pusztaederics",
            "value": "Pusztaederics"
        },
        {
            "label": "Pusztafalu",
            "value": "Pusztafalu"
        },
        {
            "label": "Pusztaföldvár",
            "value": "Pusztaföldvár"
        },
        {
            "label": "Pusztahencse",
            "value": "Pusztahencse"
        },
        {
            "label": "Pusztakovácsi",
            "value": "Pusztakovácsi"
        },
        {
            "label": "Pusztamagyaród",
            "value": "Pusztamagyaród"
        },
        {
            "label": "Pusztamérges",
            "value": "Pusztamérges"
        },
        {
            "label": "Pusztamiske",
            "value": "Pusztamiske"
        },
        {
            "label": "Pusztamonostor",
            "value": "Pusztamonostor"
        },
        {
            "label": "Pusztaottlaka",
            "value": "Pusztaottlaka"
        },
        {
            "label": "Pusztaradvány",
            "value": "Pusztaradvány"
        },
        {
            "label": "Pusztaszabolcs",
            "value": "Pusztaszabolcs"
        },
        {
            "label": "Pusztaszemes",
            "value": "Pusztaszemes"
        },
        {
            "label": "Pusztaszentlászló",
            "value": "Pusztaszentlászló"
        },
        {
            "label": "Pusztaszer",
            "value": "Pusztaszer"
        },
        {
            "label": "Pusztavacs",
            "value": "Pusztavacs"
        },
        {
            "label": "Pusztavám",
            "value": "Pusztavám"
        },
        {
            "label": "Pusztazámor",
            "value": "Pusztazámor"
        },
        {
            "label": "Putnok",
            "value": "Putnok"
        },
        {
            "label": "Püski",
            "value": "Püski"
        },
        {
            "label": "Püspökhatvan",
            "value": "Püspökhatvan"
        },
        {
            "label": "Püspökladány",
            "value": "Püspökladány"
        },
        {
            "label": "Püspökmolnári",
            "value": "Püspökmolnári"
        },
        {
            "label": "Püspökszilágy",
            "value": "Püspökszilágy"
        },
        {
            "label": "Rábacsanak",
            "value": "Rábacsanak"
        },
        {
            "label": "Rábacsécsény",
            "value": "Rábacsécsény"
        },
        {
            "label": "Rábagyarmat",
            "value": "Rábagyarmat"
        },
        {
            "label": "Rábahídvég",
            "value": "Rábahídvég"
        },
        {
            "label": "Rábakecöl",
            "value": "Rábakecöl"
        },
        {
            "label": "Rábapatona",
            "value": "Rábapatona"
        },
        {
            "label": "Rábapaty",
            "value": "Rábapaty"
        },
        {
            "label": "Rábapordány",
            "value": "Rábapordány"
        },
        {
            "label": "Rábasebes",
            "value": "Rábasebes"
        },
        {
            "label": "Rábaszentandrás",
            "value": "Rábaszentandrás"
        },
        {
            "label": "Rábaszentmihály",
            "value": "Rábaszentmihály"
        },
        {
            "label": "Rábaszentmiklós",
            "value": "Rábaszentmiklós"
        },
        {
            "label": "Rábatamási",
            "value": "Rábatamási"
        },
        {
            "label": "Rábatöttös",
            "value": "Rábatöttös"
        },
        {
            "label": "Rábcakapi",
            "value": "Rábcakapi"
        },
        {
            "label": "Rácalmás",
            "value": "Rácalmás"
        },
        {
            "label": "Ráckeresztúr",
            "value": "Ráckeresztúr"
        },
        {
            "label": "Ráckeve",
            "value": "Ráckeve"
        },
        {
            "label": "Rád",
            "value": "Rád"
        },
        {
            "label": "Rádfalva",
            "value": "Rádfalva"
        },
        {
            "label": "Rádóckölked",
            "value": "Rádóckölked"
        },
        {
            "label": "Radostyán",
            "value": "Radostyán"
        },
        {
            "label": "Ragály",
            "value": "Ragály"
        },
        {
            "label": "Rajka",
            "value": "Rajka"
        },
        {
            "label": "Rakaca",
            "value": "Rakaca"
        },
        {
            "label": "Rakacaszend",
            "value": "Rakacaszend"
        },
        {
            "label": "Rakamaz",
            "value": "Rakamaz"
        },
        {
            "label": "Rákóczibánya",
            "value": "Rákóczibánya"
        },
        {
            "label": "Rákóczifalva",
            "value": "Rákóczifalva"
        },
        {
            "label": "Rákócziújfalu",
            "value": "Rákócziújfalu"
        },
        {
            "label": "Ráksi",
            "value": "Ráksi"
        },
        {
            "label": "Ramocsa",
            "value": "Ramocsa"
        },
        {
            "label": "Ramocsaháza",
            "value": "Ramocsaháza"
        },
        {
            "label": "Rápolt",
            "value": "Rápolt"
        },
        {
            "label": "Raposka",
            "value": "Raposka"
        },
        {
            "label": "Rásonysápberencs",
            "value": "Rásonysápberencs"
        },
        {
            "label": "Rátka",
            "value": "Rátka"
        },
        {
            "label": "Rátót",
            "value": "Rátót"
        },
        {
            "label": "Ravazd",
            "value": "Ravazd"
        },
        {
            "label": "Recsk",
            "value": "Recsk"
        },
        {
            "label": "Réde",
            "value": "Réde"
        },
        {
            "label": "Rédics",
            "value": "Rédics"
        },
        {
            "label": "Regéc",
            "value": "Regéc"
        },
        {
            "label": "Regenye",
            "value": "Regenye"
        },
        {
            "label": "Regöly",
            "value": "Regöly"
        },
        {
            "label": "Rém",
            "value": "Rém"
        },
        {
            "label": "Remeteszőlős",
            "value": "Remeteszőlős"
        },
        {
            "label": "Répáshuta",
            "value": "Répáshuta"
        },
        {
            "label": "Répcelak",
            "value": "Répcelak"
        },
        {
            "label": "Répceszemere",
            "value": "Répceszemere"
        },
        {
            "label": "Répceszentgyörgy",
            "value": "Répceszentgyörgy"
        },
        {
            "label": "Répcevis",
            "value": "Répcevis"
        },
        {
            "label": "Resznek",
            "value": "Resznek"
        },
        {
            "label": "Rétalap",
            "value": "Rétalap"
        },
        {
            "label": "Rétközberencs",
            "value": "Rétközberencs"
        },
        {
            "label": "Rétság",
            "value": "Rétság"
        },
        {
            "label": "Révfülöp",
            "value": "Révfülöp"
        },
        {
            "label": "Révleányvár",
            "value": "Révleányvár"
        },
        {
            "label": "Rezi",
            "value": "Rezi"
        },
        {
            "label": "Ricse",
            "value": "Ricse"
        },
        {
            "label": "Rigács",
            "value": "Rigács"
        },
        {
            "label": "Rigyác",
            "value": "Rigyác"
        },
        {
            "label": "Rimóc",
            "value": "Rimóc"
        },
        {
            "label": "Rinyabesenyő",
            "value": "Rinyabesenyő"
        },
        {
            "label": "Rinyakovácsi",
            "value": "Rinyakovácsi"
        },
        {
            "label": "Rinyaszentkirály",
            "value": "Rinyaszentkirály"
        },
        {
            "label": "Rinyaújlak",
            "value": "Rinyaújlak"
        },
        {
            "label": "Rinyaújnép",
            "value": "Rinyaújnép"
        },
        {
            "label": "Rohod",
            "value": "Rohod"
        },
        {
            "label": "Románd",
            "value": "Románd"
        },
        {
            "label": "Romhány",
            "value": "Romhány"
        },
        {
            "label": "Romonya",
            "value": "Romonya"
        },
        {
            "label": "Rózsafa",
            "value": "Rózsafa"
        },
        {
            "label": "Rozsály",
            "value": "Rozsály"
        },
        {
            "label": "Rózsaszentmárton",
            "value": "Rózsaszentmárton"
        },
        {
            "label": "Röjtökmuzsaj",
            "value": "Röjtökmuzsaj"
        },
        {
            "label": "Rönök",
            "value": "Rönök"
        },
        {
            "label": "Röszke",
            "value": "Röszke"
        },
        {
            "label": "Rudabánya",
            "value": "Rudabánya"
        },
        {
            "label": "Rudolftelep",
            "value": "Rudolftelep"
        },
        {
            "label": "Rum",
            "value": "Rum"
        },
        {
            "label": "Ruzsa",
            "value": "Ruzsa"
        },
        {
            "label": "Ságújfalu",
            "value": "Ságújfalu"
        },
        {
            "label": "Ságvár",
            "value": "Ságvár"
        },
        {
            "label": "Sajóbábony",
            "value": "Sajóbábony"
        },
        {
            "label": "Sajóecseg",
            "value": "Sajóecseg"
        },
        {
            "label": "Sajógalgóc",
            "value": "Sajógalgóc"
        },
        {
            "label": "Sajóhídvég",
            "value": "Sajóhídvég"
        },
        {
            "label": "Sajóivánka",
            "value": "Sajóivánka"
        },
        {
            "label": "Sajókápolna",
            "value": "Sajókápolna"
        },
        {
            "label": "Sajókaza",
            "value": "Sajókaza"
        },
        {
            "label": "Sajókeresztúr",
            "value": "Sajókeresztúr"
        },
        {
            "label": "Sajólád",
            "value": "Sajólád"
        },
        {
            "label": "Sajólászlófalva",
            "value": "Sajólászlófalva"
        },
        {
            "label": "Sajómercse",
            "value": "Sajómercse"
        },
        {
            "label": "Sajónémeti",
            "value": "Sajónémeti"
        },
        {
            "label": "Sajóörös",
            "value": "Sajóörös"
        },
        {
            "label": "Sajópálfala",
            "value": "Sajópálfala"
        },
        {
            "label": "Sajópetri",
            "value": "Sajópetri"
        },
        {
            "label": "Sajópüspöki",
            "value": "Sajópüspöki"
        },
        {
            "label": "Sajósenye",
            "value": "Sajósenye"
        },
        {
            "label": "Sajószentpéter",
            "value": "Sajószentpéter"
        },
        {
            "label": "Sajószöged",
            "value": "Sajószöged"
        },
        {
            "label": "Sajóvámos",
            "value": "Sajóvámos"
        },
        {
            "label": "Sajóvelezd",
            "value": "Sajóvelezd"
        },
        {
            "label": "Sajtoskál",
            "value": "Sajtoskál"
        },
        {
            "label": "Salföld",
            "value": "Salföld"
        },
        {
            "label": "Salgótarján",
            "value": "Salgótarján"
        },
        {
            "label": "Salköveskút",
            "value": "Salköveskút"
        },
        {
            "label": "Salomvár",
            "value": "Salomvár"
        },
        {
            "label": "Sály",
            "value": "Sály"
        },
        {
            "label": "Sámod",
            "value": "Sámod"
        },
        {
            "label": "Sámsonháza",
            "value": "Sámsonháza"
        },
        {
            "label": "Sand",
            "value": "Sand"
        },
        {
            "label": "Sándorfalva",
            "value": "Sándorfalva"
        },
        {
            "label": "Sántos",
            "value": "Sántos"
        },
        {
            "label": "Sáp",
            "value": "Sáp"
        },
        {
            "label": "Sáránd",
            "value": "Sáránd"
        },
        {
            "label": "Sárazsadány",
            "value": "Sárazsadány"
        },
        {
            "label": "Sárbogárd",
            "value": "Sárbogárd"
        },
        {
            "label": "Sáregres",
            "value": "Sáregres"
        },
        {
            "label": "Sárfimizdó",
            "value": "Sárfimizdó"
        },
        {
            "label": "Sárhida",
            "value": "Sárhida"
        },
        {
            "label": "Sárisáp",
            "value": "Sárisáp"
        },
        {
            "label": "Sarkad",
            "value": "Sarkad"
        },
        {
            "label": "Sarkadkeresztúr",
            "value": "Sarkadkeresztúr"
        },
        {
            "label": "Sárkeresztes",
            "value": "Sárkeresztes"
        },
        {
            "label": "Sárkeresztúr",
            "value": "Sárkeresztúr"
        },
        {
            "label": "Sárkeszi",
            "value": "Sárkeszi"
        },
        {
            "label": "Sármellék",
            "value": "Sármellék"
        },
        {
            "label": "Sárok",
            "value": "Sárok"
        },
        {
            "label": "Sárosd",
            "value": "Sárosd"
        },
        {
            "label": "Sárospatak",
            "value": "Sárospatak"
        },
        {
            "label": "Sárpilis",
            "value": "Sárpilis"
        },
        {
            "label": "Sárrétudvari",
            "value": "Sárrétudvari"
        },
        {
            "label": "Sarród",
            "value": "Sarród"
        },
        {
            "label": "Sárszentágota",
            "value": "Sárszentágota"
        },
        {
            "label": "Sárszentlőrinc",
            "value": "Sárszentlőrinc"
        },
        {
            "label": "Sárszentmihály",
            "value": "Sárszentmihály"
        },
        {
            "label": "Sarud",
            "value": "Sarud"
        },
        {
            "label": "Sárvár",
            "value": "Sárvár"
        },
        {
            "label": "Sásd",
            "value": "Sásd"
        },
        {
            "label": "Sáska",
            "value": "Sáska"
        },
        {
            "label": "Sáta",
            "value": "Sáta"
        },
        {
            "label": "Sátoraljaújhely",
            "value": "Sátoraljaújhely"
        },
        {
            "label": "Sátorhely",
            "value": "Sátorhely"
        },
        {
            "label": "Sávoly",
            "value": "Sávoly"
        },
        {
            "label": "Sé",
            "value": "Sé"
        },
        {
            "label": "Segesd",
            "value": "Segesd"
        },
        {
            "label": "Sellye",
            "value": "Sellye"
        },
        {
            "label": "Selyeb",
            "value": "Selyeb"
        },
        {
            "label": "Semjén",
            "value": "Semjén"
        },
        {
            "label": "Semjénháza",
            "value": "Semjénháza"
        },
        {
            "label": "Sénye",
            "value": "Sénye"
        },
        {
            "label": "Sényő",
            "value": "Sényő"
        },
        {
            "label": "Seregélyes",
            "value": "Seregélyes"
        },
        {
            "label": "Serényfalva",
            "value": "Serényfalva"
        },
        {
            "label": "Sérsekszőlős",
            "value": "Sérsekszőlős"
        },
        {
            "label": "Sikátor",
            "value": "Sikátor"
        },
        {
            "label": "Siklós",
            "value": "Siklós"
        },
        {
            "label": "Siklósbodony",
            "value": "Siklósbodony"
        },
        {
            "label": "Siklósnagyfalu",
            "value": "Siklósnagyfalu"
        },
        {
            "label": "Sima",
            "value": "Sima"
        },
        {
            "label": "Simaság",
            "value": "Simaság"
        },
        {
            "label": "Simonfa",
            "value": "Simonfa"
        },
        {
            "label": "Simontornya",
            "value": "Simontornya"
        },
        {
            "label": "Sióagárd",
            "value": "Sióagárd"
        },
        {
            "label": "Siófok",
            "value": "Siófok"
        },
        {
            "label": "Siójut",
            "value": "Siójut"
        },
        {
            "label": "Sirok",
            "value": "Sirok"
        },
        {
            "label": "Sitke",
            "value": "Sitke"
        },
        {
            "label": "Sobor",
            "value": "Sobor"
        },
        {
            "label": "Sokorópátka",
            "value": "Sokorópátka"
        },
        {
            "label": "Solt",
            "value": "Solt"
        },
        {
            "label": "Soltszentimre",
            "value": "Soltszentimre"
        },
        {
            "label": "Soltvadkert",
            "value": "Soltvadkert"
        },
        {
            "label": "Sóly",
            "value": "Sóly"
        },
        {
            "label": "Solymár",
            "value": "Solymár"
        },
        {
            "label": "Som",
            "value": "Som"
        },
        {
            "label": "Somberek",
            "value": "Somberek"
        },
        {
            "label": "Somlójenő",
            "value": "Somlójenő"
        },
        {
            "label": "Somlószőlős",
            "value": "Somlószőlős"
        },
        {
            "label": "Somlóvásárhely",
            "value": "Somlóvásárhely"
        },
        {
            "label": "Somlóvecse",
            "value": "Somlóvecse"
        },
        {
            "label": "Somodor",
            "value": "Somodor"
        },
        {
            "label": "Somogyacsa",
            "value": "Somogyacsa"
        },
        {
            "label": "Somogyapáti",
            "value": "Somogyapáti"
        },
        {
            "label": "Somogyaracs",
            "value": "Somogyaracs"
        },
        {
            "label": "Somogyaszaló",
            "value": "Somogyaszaló"
        },
        {
            "label": "Somogybabod",
            "value": "Somogybabod"
        },
        {
            "label": "Somogybükkösd",
            "value": "Somogybükkösd"
        },
        {
            "label": "Somogycsicsó",
            "value": "Somogycsicsó"
        },
        {
            "label": "Somogydöröcske",
            "value": "Somogydöröcske"
        },
        {
            "label": "Somogyegres",
            "value": "Somogyegres"
        },
        {
            "label": "Somogyfajsz",
            "value": "Somogyfajsz"
        },
        {
            "label": "Somogygeszti",
            "value": "Somogygeszti"
        },
        {
            "label": "Somogyhárságy",
            "value": "Somogyhárságy"
        },
        {
            "label": "Somogyhatvan",
            "value": "Somogyhatvan"
        },
        {
            "label": "Somogyjád",
            "value": "Somogyjád"
        },
        {
            "label": "Somogymeggyes",
            "value": "Somogymeggyes"
        },
        {
            "label": "Somogysámson",
            "value": "Somogysámson"
        },
        {
            "label": "Somogysárd",
            "value": "Somogysárd"
        },
        {
            "label": "Somogysimonyi",
            "value": "Somogysimonyi"
        },
        {
            "label": "Somogyszentpál",
            "value": "Somogyszentpál"
        },
        {
            "label": "Somogyszil",
            "value": "Somogyszil"
        },
        {
            "label": "Somogyszob",
            "value": "Somogyszob"
        },
        {
            "label": "Somogytúr",
            "value": "Somogytúr"
        },
        {
            "label": "Somogyudvarhely",
            "value": "Somogyudvarhely"
        },
        {
            "label": "Somogyvámos",
            "value": "Somogyvámos"
        },
        {
            "label": "Somogyvár",
            "value": "Somogyvár"
        },
        {
            "label": "Somogyviszló",
            "value": "Somogyviszló"
        },
        {
            "label": "Somogyzsitfa",
            "value": "Somogyzsitfa"
        },
        {
            "label": "Sonkád",
            "value": "Sonkád"
        },
        {
            "label": "Soponya",
            "value": "Soponya"
        },
        {
            "label": "Sopron",
            "value": "Sopron"
        },
        {
            "label": "Sopronhorpács",
            "value": "Sopronhorpács"
        },
        {
            "label": "Sopronkövesd",
            "value": "Sopronkövesd"
        },
        {
            "label": "Sopronnémeti",
            "value": "Sopronnémeti"
        },
        {
            "label": "Sorkifalud",
            "value": "Sorkifalud"
        },
        {
            "label": "Sorkikápolna",
            "value": "Sorkikápolna"
        },
        {
            "label": "Sormás",
            "value": "Sormás"
        },
        {
            "label": "Sorokpolány",
            "value": "Sorokpolány"
        },
        {
            "label": "Sóshartyán",
            "value": "Sóshartyán"
        },
        {
            "label": "Sóskút",
            "value": "Sóskút"
        },
        {
            "label": "Sóstófalva",
            "value": "Sóstófalva"
        },
        {
            "label": "Sósvertike",
            "value": "Sósvertike"
        },
        {
            "label": "Sótony",
            "value": "Sótony"
        },
        {
            "label": "Söjtör",
            "value": "Söjtör"
        },
        {
            "label": "Söpte",
            "value": "Söpte"
        },
        {
            "label": "Söréd",
            "value": "Söréd"
        },
        {
            "label": "Sukoró",
            "value": "Sukoró"
        },
        {
            "label": "Sumony",
            "value": "Sumony"
        },
        {
            "label": "Súr",
            "value": "Súr"
        },
        {
            "label": "Surd",
            "value": "Surd"
        },
        {
            "label": "Sükösd",
            "value": "Sükösd"
        },
        {
            "label": "Sülysáp",
            "value": "Sülysáp"
        },
        {
            "label": "Sümeg",
            "value": "Sümeg"
        },
        {
            "label": "Sümegcsehi",
            "value": "Sümegcsehi"
        },
        {
            "label": "Sümegprága",
            "value": "Sümegprága"
        },
        {
            "label": "Süttő",
            "value": "Süttő"
        },
        {
            "label": "Szabadbattyán",
            "value": "Szabadbattyán"
        },
        {
            "label": "Szabadegyháza",
            "value": "Szabadegyháza"
        },
        {
            "label": "Szabadhídvég",
            "value": "Szabadhídvég"
        },
        {
            "label": "Szabadi",
            "value": "Szabadi"
        },
        {
            "label": "Szabadkígyós",
            "value": "Szabadkígyós"
        },
        {
            "label": "Szabadszállás",
            "value": "Szabadszállás"
        },
        {
            "label": "Szabadszentkirály",
            "value": "Szabadszentkirály"
        },
        {
            "label": "Szabás",
            "value": "Szabás"
        },
        {
            "label": "Szabolcs",
            "value": "Szabolcs"
        },
        {
            "label": "Szabolcsbáka",
            "value": "Szabolcsbáka"
        },
        {
            "label": "Szabolcsveresmart",
            "value": "Szabolcsveresmart"
        },
        {
            "label": "Szada",
            "value": "Szada"
        },
        {
            "label": "Szágy",
            "value": "Szágy"
        },
        {
            "label": "Szajk",
            "value": "Szajk"
        },
        {
            "label": "Szajla",
            "value": "Szajla"
        },
        {
            "label": "Szajol",
            "value": "Szajol"
        },
        {
            "label": "Szakácsi",
            "value": "Szakácsi"
        },
        {
            "label": "Szakadát",
            "value": "Szakadát"
        },
        {
            "label": "Szakáld",
            "value": "Szakáld"
        },
        {
            "label": "Szakály",
            "value": "Szakály"
        },
        {
            "label": "Szakcs",
            "value": "Szakcs"
        },
        {
            "label": "Szakmár",
            "value": "Szakmár"
        },
        {
            "label": "Szaknyér",
            "value": "Szaknyér"
        },
        {
            "label": "Szakoly",
            "value": "Szakoly"
        },
        {
            "label": "Szakony",
            "value": "Szakony"
        },
        {
            "label": "Szakonyfalu",
            "value": "Szakonyfalu"
        },
        {
            "label": "Szákszend",
            "value": "Szákszend"
        },
        {
            "label": "Szalafő",
            "value": "Szalafő"
        },
        {
            "label": "Szalánta",
            "value": "Szalánta"
        },
        {
            "label": "Szalapa",
            "value": "Szalapa"
        },
        {
            "label": "Szalaszend",
            "value": "Szalaszend"
        },
        {
            "label": "Szalatnak",
            "value": "Szalatnak"
        },
        {
            "label": "Szálka",
            "value": "Szálka"
        },
        {
            "label": "Szalkszentmárton",
            "value": "Szalkszentmárton"
        },
        {
            "label": "Szalmatercs",
            "value": "Szalmatercs"
        },
        {
            "label": "Szalonna",
            "value": "Szalonna"
        },
        {
            "label": "Szamosangyalos",
            "value": "Szamosangyalos"
        },
        {
            "label": "Szamosbecs",
            "value": "Szamosbecs"
        },
        {
            "label": "Szamoskér",
            "value": "Szamoskér"
        },
        {
            "label": "Szamossályi",
            "value": "Szamossályi"
        },
        {
            "label": "Szamosszeg",
            "value": "Szamosszeg"
        },
        {
            "label": "Szamostatárfalva",
            "value": "Szamostatárfalva"
        },
        {
            "label": "Szamosújlak",
            "value": "Szamosújlak"
        },
        {
            "label": "Szanda",
            "value": "Szanda"
        },
        {
            "label": "Szank",
            "value": "Szank"
        },
        {
            "label": "Szántód",
            "value": "Szántód"
        },
        {
            "label": "Szany",
            "value": "Szany"
        },
        {
            "label": "Szápár",
            "value": "Szápár"
        },
        {
            "label": "Szaporca",
            "value": "Szaporca"
        },
        {
            "label": "Szár",
            "value": "Szár"
        },
        {
            "label": "Szárász",
            "value": "Szárász"
        },
        {
            "label": "Szárazd",
            "value": "Szárazd"
        },
        {
            "label": "Szárföld",
            "value": "Szárföld"
        },
        {
            "label": "Szárliget",
            "value": "Szárliget"
        },
        {
            "label": "Szarvas",
            "value": "Szarvas"
        },
        {
            "label": "Szarvasgede",
            "value": "Szarvasgede"
        },
        {
            "label": "Szarvaskend",
            "value": "Szarvaskend"
        },
        {
            "label": "Szarvaskő",
            "value": "Szarvaskő"
        },
        {
            "label": "Szászberek",
            "value": "Szászberek"
        },
        {
            "label": "Szászfa",
            "value": "Szászfa"
        },
        {
            "label": "Szászvár",
            "value": "Szászvár"
        },
        {
            "label": "Szatmárcseke",
            "value": "Szatmárcseke"
        },
        {
            "label": "Szátok",
            "value": "Szátok"
        },
        {
            "label": "Szatta",
            "value": "Szatta"
        },
        {
            "label": "Szatymaz",
            "value": "Szatymaz"
        },
        {
            "label": "Szava",
            "value": "Szava"
        },
        {
            "label": "Százhalombatta",
            "value": "Százhalombatta"
        },
        {
            "label": "Szebény",
            "value": "Szebény"
        },
        {
            "label": "Szécsénke",
            "value": "Szécsénke"
        },
        {
            "label": "Szécsény",
            "value": "Szécsény"
        },
        {
            "label": "Szécsényfelfalu",
            "value": "Szécsényfelfalu"
        },
        {
            "label": "Szécsisziget",
            "value": "Szécsisziget"
        },
        {
            "label": "Szederkény",
            "value": "Szederkény"
        },
        {
            "label": "Szedres",
            "value": "Szedres"
        },
        {
            "label": "Szeged",
            "value": "Szeged"
        },
        {
            "label": "Szegerdő",
            "value": "Szegerdő"
        },
        {
            "label": "Szeghalom",
            "value": "Szeghalom"
        },
        {
            "label": "Szegi",
            "value": "Szegi"
        },
        {
            "label": "Szegilong",
            "value": "Szegilong"
        },
        {
            "label": "Szegvár",
            "value": "Szegvár"
        },
        {
            "label": "Székely",
            "value": "Székely"
        },
        {
            "label": "Székelyszabar",
            "value": "Székelyszabar"
        },
        {
            "label": "Székesfehérvár",
            "value": "Székesfehérvár"
        },
        {
            "label": "Székkutas",
            "value": "Székkutas"
        },
        {
            "label": "Szekszárd",
            "value": "Szekszárd"
        },
        {
            "label": "Szeleste",
            "value": "Szeleste"
        },
        {
            "label": "Szelevény",
            "value": "Szelevény"
        },
        {
            "label": "Szellő",
            "value": "Szellő"
        },
        {
            "label": "Szemely",
            "value": "Szemely"
        },
        {
            "label": "Szemenye",
            "value": "Szemenye"
        },
        {
            "label": "Szemere",
            "value": "Szemere"
        },
        {
            "label": "Szendehely",
            "value": "Szendehely"
        },
        {
            "label": "Szendrő",
            "value": "Szendrő"
        },
        {
            "label": "Szendrőlád",
            "value": "Szendrőlád"
        },
        {
            "label": "Szenna",
            "value": "Szenna"
        },
        {
            "label": "Szenta",
            "value": "Szenta"
        },
        {
            "label": "Szentantalfa",
            "value": "Szentantalfa"
        },
        {
            "label": "Szentbalázs",
            "value": "Szentbalázs"
        },
        {
            "label": "Szentbékkálla",
            "value": "Szentbékkálla"
        },
        {
            "label": "Szentborbás",
            "value": "Szentborbás"
        },
        {
            "label": "Szentdénes",
            "value": "Szentdénes"
        },
        {
            "label": "Szentdomonkos",
            "value": "Szentdomonkos"
        },
        {
            "label": "Szente",
            "value": "Szente"
        },
        {
            "label": "Szentegát",
            "value": "Szentegát"
        },
        {
            "label": "Szentendre",
            "value": "Szentendre"
        },
        {
            "label": "Szentes",
            "value": "Szentes"
        },
        {
            "label": "Szentgál",
            "value": "Szentgál"
        },
        {
            "label": "Szentgáloskér",
            "value": "Szentgáloskér"
        },
        {
            "label": "Szentgotthárd",
            "value": "Szentgotthárd"
        },
        {
            "label": "Szentgyörgyvár",
            "value": "Szentgyörgyvár"
        },
        {
            "label": "Szentgyörgyvölgy",
            "value": "Szentgyörgyvölgy"
        },
        {
            "label": "Szentimrefalva",
            "value": "Szentimrefalva"
        },
        {
            "label": "Szentistván",
            "value": "Szentistván"
        },
        {
            "label": "Szentistvánbaksa",
            "value": "Szentistvánbaksa"
        },
        {
            "label": "Szentjakabfa",
            "value": "Szentjakabfa"
        },
        {
            "label": "Szentkatalin",
            "value": "Szentkatalin"
        },
        {
            "label": "Szentkirály",
            "value": "Szentkirály"
        },
        {
            "label": "Szentkirályszabadja",
            "value": "Szentkirályszabadja"
        },
        {
            "label": "Szentkozmadombja",
            "value": "Szentkozmadombja"
        },
        {
            "label": "Szentlászló",
            "value": "Szentlászló"
        },
        {
            "label": "Szentliszló",
            "value": "Szentliszló"
        },
        {
            "label": "Szentlőrinc",
            "value": "Szentlőrinc"
        },
        {
            "label": "Szentlőrinckáta",
            "value": "Szentlőrinckáta"
        },
        {
            "label": "Szentmargitfalva",
            "value": "Szentmargitfalva"
        },
        {
            "label": "Szentmártonkáta",
            "value": "Szentmártonkáta"
        },
        {
            "label": "Szentpéterfa",
            "value": "Szentpéterfa"
        },
        {
            "label": "Szentpéterfölde",
            "value": "Szentpéterfölde"
        },
        {
            "label": "Szentpéterszeg",
            "value": "Szentpéterszeg"
        },
        {
            "label": "Szentpéterúr",
            "value": "Szentpéterúr"
        },
        {
            "label": "Szenyér",
            "value": "Szenyér"
        },
        {
            "label": "Szepetnek",
            "value": "Szepetnek"
        },
        {
            "label": "Szerecseny",
            "value": "Szerecseny"
        },
        {
            "label": "Szeremle",
            "value": "Szeremle"
        },
        {
            "label": "Szerencs",
            "value": "Szerencs"
        },
        {
            "label": "Szerep",
            "value": "Szerep"
        },
        {
            "label": "Szergény",
            "value": "Szergény"
        },
        {
            "label": "Szigetbecse",
            "value": "Szigetbecse"
        },
        {
            "label": "Szigetcsép",
            "value": "Szigetcsép"
        },
        {
            "label": "Szigethalom",
            "value": "Szigethalom"
        },
        {
            "label": "Szigetmonostor",
            "value": "Szigetmonostor"
        },
        {
            "label": "Szigetszentmárton",
            "value": "Szigetszentmárton"
        },
        {
            "label": "Szigetszentmiklós",
            "value": "Szigetszentmiklós"
        },
        {
            "label": "Szigetújfalu",
            "value": "Szigetújfalu"
        },
        {
            "label": "Szigetvár",
            "value": "Szigetvár"
        },
        {
            "label": "Szigliget",
            "value": "Szigliget"
        },
        {
            "label": "Szihalom",
            "value": "Szihalom"
        },
        {
            "label": "Szijártóháza",
            "value": "Szijártóháza"
        },
        {
            "label": "Szikszó",
            "value": "Szikszó"
        },
        {
            "label": "Szil",
            "value": "Szil"
        },
        {
            "label": "Szilágy",
            "value": "Szilágy"
        },
        {
            "label": "Szilaspogony",
            "value": "Szilaspogony"
        },
        {
            "label": "Szilsárkány",
            "value": "Szilsárkány"
        },
        {
            "label": "Szilvágy",
            "value": "Szilvágy"
        },
        {
            "label": "Szilvás",
            "value": "Szilvás"
        },
        {
            "label": "Szilvásszentmárton",
            "value": "Szilvásszentmárton"
        },
        {
            "label": "Szilvásvárad",
            "value": "Szilvásvárad"
        },
        {
            "label": "Szin",
            "value": "Szin"
        },
        {
            "label": "Szinpetri",
            "value": "Szinpetri"
        },
        {
            "label": "Szirák",
            "value": "Szirák"
        },
        {
            "label": "Szirmabesenyő",
            "value": "Szirmabesenyő"
        },
        {
            "label": "Szob",
            "value": "Szob"
        },
        {
            "label": "Szokolya",
            "value": "Szokolya"
        },
        {
            "label": "Szólád",
            "value": "Szólád"
        },
        {
            "label": "Szolnok",
            "value": "Szolnok"
        },
        {
            "label": "Szombathely",
            "value": "Szombathely"
        },
        {
            "label": "Szomód",
            "value": "Szomód"
        },
        {
            "label": "Szomolya",
            "value": "Szomolya"
        },
        {
            "label": "Szomor",
            "value": "Szomor"
        },
        {
            "label": "Szorgalmatos",
            "value": "Szorgalmatos"
        },
        {
            "label": "Szorosad",
            "value": "Szorosad"
        },
        {
            "label": "Szőc",
            "value": "Szőc"
        },
        {
            "label": "Szőce",
            "value": "Szőce"
        },
        {
            "label": "Sződ",
            "value": "Sződ"
        },
        {
            "label": "Sződliget",
            "value": "Sződliget"
        },
        {
            "label": "Szögliget",
            "value": "Szögliget"
        },
        {
            "label": "Szőke",
            "value": "Szőke"
        },
        {
            "label": "Szőkéd",
            "value": "Szőkéd"
        },
        {
            "label": "Szőkedencs",
            "value": "Szőkedencs"
        },
        {
            "label": "Szőlősardó",
            "value": "Szőlősardó"
        },
        {
            "label": "Szőlősgyörök",
            "value": "Szőlősgyörök"
        },
        {
            "label": "Szörény",
            "value": "Szörény"
        },
        {
            "label": "Szúcs",
            "value": "Szúcs"
        },
        {
            "label": "Szuha",
            "value": "Szuha"
        },
        {
            "label": "Szuhafő",
            "value": "Szuhafő"
        },
        {
            "label": "Szuhakálló",
            "value": "Szuhakálló"
        },
        {
            "label": "Szuhogy",
            "value": "Szuhogy"
        },
        {
            "label": "Szulimán",
            "value": "Szulimán"
        },
        {
            "label": "Szulok",
            "value": "Szulok"
        },
        {
            "label": "Szurdokpüspöki",
            "value": "Szurdokpüspöki"
        },
        {
            "label": "Szűcsi",
            "value": "Szűcsi"
        },
        {
            "label": "Szügy",
            "value": "Szügy"
        },
        {
            "label": "Szűr",
            "value": "Szűr"
        },
        {
            "label": "Tab",
            "value": "Tab"
        },
        {
            "label": "Tabajd",
            "value": "Tabajd"
        },
        {
            "label": "Tabdi",
            "value": "Tabdi"
        },
        {
            "label": "Táborfalva",
            "value": "Táborfalva"
        },
        {
            "label": "Tác",
            "value": "Tác"
        },
        {
            "label": "Tagyon",
            "value": "Tagyon"
        },
        {
            "label": "Tahitótfalu",
            "value": "Tahitótfalu"
        },
        {
            "label": "Takácsi",
            "value": "Takácsi"
        },
        {
            "label": "Tákos",
            "value": "Tákos"
        },
        {
            "label": "Taksony",
            "value": "Taksony"
        },
        {
            "label": "Taktabáj",
            "value": "Taktabáj"
        },
        {
            "label": "Taktaharkány",
            "value": "Taktaharkány"
        },
        {
            "label": "Taktakenéz",
            "value": "Taktakenéz"
        },
        {
            "label": "Taktaszada",
            "value": "Taktaszada"
        },
        {
            "label": "Taliándörögd",
            "value": "Taliándörögd"
        },
        {
            "label": "Tállya",
            "value": "Tállya"
        },
        {
            "label": "Tamási",
            "value": "Tamási"
        },
        {
            "label": "Tanakajd",
            "value": "Tanakajd"
        },
        {
            "label": "Táp",
            "value": "Táp"
        },
        {
            "label": "Tápióbicske",
            "value": "Tápióbicske"
        },
        {
            "label": "Tápiógyörgye",
            "value": "Tápiógyörgye"
        },
        {
            "label": "Tápióság",
            "value": "Tápióság"
        },
        {
            "label": "Tápiószecső",
            "value": "Tápiószecső"
        },
        {
            "label": "Tápiószele",
            "value": "Tápiószele"
        },
        {
            "label": "Tápiószentmárton",
            "value": "Tápiószentmárton"
        },
        {
            "label": "Tápiószőlős",
            "value": "Tápiószőlős"
        },
        {
            "label": "Táplánszentkereszt",
            "value": "Táplánszentkereszt"
        },
        {
            "label": "Tapolca",
            "value": "Tapolca"
        },
        {
            "label": "Tapsony",
            "value": "Tapsony"
        },
        {
            "label": "Tápszentmiklós",
            "value": "Tápszentmiklós"
        },
        {
            "label": "Tar",
            "value": "Tar"
        },
        {
            "label": "Tarany",
            "value": "Tarany"
        },
        {
            "label": "Tarcal",
            "value": "Tarcal"
        },
        {
            "label": "Tard",
            "value": "Tard"
        },
        {
            "label": "Tardona",
            "value": "Tardona"
        },
        {
            "label": "Tardos",
            "value": "Tardos"
        },
        {
            "label": "Tarhos",
            "value": "Tarhos"
        },
        {
            "label": "Tarján",
            "value": "Tarján"
        },
        {
            "label": "Tarjánpuszta",
            "value": "Tarjánpuszta"
        },
        {
            "label": "Tárkány",
            "value": "Tárkány"
        },
        {
            "label": "Tarnabod",
            "value": "Tarnabod"
        },
        {
            "label": "Tarnalelesz",
            "value": "Tarnalelesz"
        },
        {
            "label": "Tarnaméra",
            "value": "Tarnaméra"
        },
        {
            "label": "Tarnaörs",
            "value": "Tarnaörs"
        },
        {
            "label": "Tarnaszentmária",
            "value": "Tarnaszentmária"
        },
        {
            "label": "Tarnaszentmiklós",
            "value": "Tarnaszentmiklós"
        },
        {
            "label": "Tarnazsadány",
            "value": "Tarnazsadány"
        },
        {
            "label": "Tárnok",
            "value": "Tárnok"
        },
        {
            "label": "Tárnokréti",
            "value": "Tárnokréti"
        },
        {
            "label": "Tarpa",
            "value": "Tarpa"
        },
        {
            "label": "Tarrós",
            "value": "Tarrós"
        },
        {
            "label": "Táska",
            "value": "Táska"
        },
        {
            "label": "Tass",
            "value": "Tass"
        },
        {
            "label": "Taszár",
            "value": "Taszár"
        },
        {
            "label": "Tát",
            "value": "Tát"
        },
        {
            "label": "Tata",
            "value": "Tata"
        },
        {
            "label": "Tatabánya",
            "value": "Tatabánya"
        },
        {
            "label": "Tataháza",
            "value": "Tataháza"
        },
        {
            "label": "Tatárszentgyörgy",
            "value": "Tatárszentgyörgy"
        },
        {
            "label": "Tázlár",
            "value": "Tázlár"
        },
        {
            "label": "Téglás",
            "value": "Téglás"
        },
        {
            "label": "Tékes",
            "value": "Tékes"
        },
        {
            "label": "Teklafalu",
            "value": "Teklafalu"
        },
        {
            "label": "Telekes",
            "value": "Telekes"
        },
        {
            "label": "Telekgerendás",
            "value": "Telekgerendás"
        },
        {
            "label": "Teleki",
            "value": "Teleki"
        },
        {
            "label": "Telki",
            "value": "Telki"
        },
        {
            "label": "Telkibánya",
            "value": "Telkibánya"
        },
        {
            "label": "Tengelic",
            "value": "Tengelic"
        },
        {
            "label": "Tengeri",
            "value": "Tengeri"
        },
        {
            "label": "Tengőd",
            "value": "Tengőd"
        },
        {
            "label": "Tenk",
            "value": "Tenk"
        },
        {
            "label": "Tényő",
            "value": "Tényő"
        },
        {
            "label": "Tépe",
            "value": "Tépe"
        },
        {
            "label": "Terem",
            "value": "Terem"
        },
        {
            "label": "Terény",
            "value": "Terény"
        },
        {
            "label": "Tereske",
            "value": "Tereske"
        },
        {
            "label": "Teresztenye",
            "value": "Teresztenye"
        },
        {
            "label": "Terpes",
            "value": "Terpes"
        },
        {
            "label": "Tés",
            "value": "Tés"
        },
        {
            "label": "Tésa",
            "value": "Tésa"
        },
        {
            "label": "Tésenfa",
            "value": "Tésenfa"
        },
        {
            "label": "Téseny",
            "value": "Téseny"
        },
        {
            "label": "Teskánd",
            "value": "Teskánd"
        },
        {
            "label": "Tét",
            "value": "Tét"
        },
        {
            "label": "Tetétlen",
            "value": "Tetétlen"
        },
        {
            "label": "Tevel",
            "value": "Tevel"
        },
        {
            "label": "Tibolddaróc",
            "value": "Tibolddaróc"
        },
        {
            "label": "Tiborszállás",
            "value": "Tiborszállás"
        },
        {
            "label": "Tihany",
            "value": "Tihany"
        },
        {
            "label": "Tikos",
            "value": "Tikos"
        },
        {
            "label": "Tilaj",
            "value": "Tilaj"
        },
        {
            "label": "Timár",
            "value": "Timár"
        },
        {
            "label": "Tinnye",
            "value": "Tinnye"
        },
        {
            "label": "Tiszaadony",
            "value": "Tiszaadony"
        },
        {
            "label": "Tiszaalpár",
            "value": "Tiszaalpár"
        },
        {
            "label": "Tiszabábolna",
            "value": "Tiszabábolna"
        },
        {
            "label": "Tiszabecs",
            "value": "Tiszabecs"
        },
        {
            "label": "Tiszabercel",
            "value": "Tiszabercel"
        },
        {
            "label": "Tiszabezdéd",
            "value": "Tiszabezdéd"
        },
        {
            "label": "Tiszabő",
            "value": "Tiszabő"
        },
        {
            "label": "Tiszabura",
            "value": "Tiszabura"
        },
        {
            "label": "Tiszacsécse",
            "value": "Tiszacsécse"
        },
        {
            "label": "Tiszacsege",
            "value": "Tiszacsege"
        },
        {
            "label": "Tiszacsermely",
            "value": "Tiszacsermely"
        },
        {
            "label": "Tiszadada",
            "value": "Tiszadada"
        },
        {
            "label": "Tiszaderzs",
            "value": "Tiszaderzs"
        },
        {
            "label": "Tiszadob",
            "value": "Tiszadob"
        },
        {
            "label": "Tiszadorogma",
            "value": "Tiszadorogma"
        },
        {
            "label": "Tiszaeszlár",
            "value": "Tiszaeszlár"
        },
        {
            "label": "Tiszaföldvár",
            "value": "Tiszaföldvár"
        },
        {
            "label": "Tiszafüred",
            "value": "Tiszafüred"
        },
        {
            "label": "Tiszagyenda",
            "value": "Tiszagyenda"
        },
        {
            "label": "Tiszagyulaháza",
            "value": "Tiszagyulaháza"
        },
        {
            "label": "Tiszaigar",
            "value": "Tiszaigar"
        },
        {
            "label": "Tiszainoka",
            "value": "Tiszainoka"
        },
        {
            "label": "Tiszajenő",
            "value": "Tiszajenő"
        },
        {
            "label": "Tiszakanyár",
            "value": "Tiszakanyár"
        },
        {
            "label": "Tiszakarád",
            "value": "Tiszakarád"
        },
        {
            "label": "Tiszakécske",
            "value": "Tiszakécske"
        },
        {
            "label": "Tiszakerecseny",
            "value": "Tiszakerecseny"
        },
        {
            "label": "Tiszakeszi",
            "value": "Tiszakeszi"
        },
        {
            "label": "Tiszakóród",
            "value": "Tiszakóród"
        },
        {
            "label": "Tiszakürt",
            "value": "Tiszakürt"
        },
        {
            "label": "Tiszaladány",
            "value": "Tiszaladány"
        },
        {
            "label": "Tiszalök",
            "value": "Tiszalök"
        },
        {
            "label": "Tiszalúc",
            "value": "Tiszalúc"
        },
        {
            "label": "Tiszamogyorós",
            "value": "Tiszamogyorós"
        },
        {
            "label": "Tiszanagyfalu",
            "value": "Tiszanagyfalu"
        },
        {
            "label": "Tiszanána",
            "value": "Tiszanána"
        },
        {
            "label": "Tiszaörs",
            "value": "Tiszaörs"
        },
        {
            "label": "Tiszapalkonya",
            "value": "Tiszapalkonya"
        },
        {
            "label": "Tiszapüspöki",
            "value": "Tiszapüspöki"
        },
        {
            "label": "Tiszarád",
            "value": "Tiszarád"
        },
        {
            "label": "Tiszaroff",
            "value": "Tiszaroff"
        },
        {
            "label": "Tiszasas",
            "value": "Tiszasas"
        },
        {
            "label": "Tiszasüly",
            "value": "Tiszasüly"
        },
        {
            "label": "Tiszaszalka",
            "value": "Tiszaszalka"
        },
        {
            "label": "Tiszaszentimre",
            "value": "Tiszaszentimre"
        },
        {
            "label": "Tiszaszentmárton",
            "value": "Tiszaszentmárton"
        },
        {
            "label": "Tiszasziget",
            "value": "Tiszasziget"
        },
        {
            "label": "Tiszaszőlős",
            "value": "Tiszaszőlős"
        },
        {
            "label": "Tiszatardos",
            "value": "Tiszatardos"
        },
        {
            "label": "Tiszatarján",
            "value": "Tiszatarján"
        },
        {
            "label": "Tiszatelek",
            "value": "Tiszatelek"
        },
        {
            "label": "Tiszatenyő",
            "value": "Tiszatenyő"
        },
        {
            "label": "Tiszaug",
            "value": "Tiszaug"
        },
        {
            "label": "Tiszaújváros",
            "value": "Tiszaújváros"
        },
        {
            "label": "Tiszavalk",
            "value": "Tiszavalk"
        },
        {
            "label": "Tiszavárkony",
            "value": "Tiszavárkony"
        },
        {
            "label": "Tiszavasvári",
            "value": "Tiszavasvári"
        },
        {
            "label": "Tiszavid",
            "value": "Tiszavid"
        },
        {
            "label": "Tisztaberek",
            "value": "Tisztaberek"
        },
        {
            "label": "Tivadar",
            "value": "Tivadar"
        },
        {
            "label": "Tóalmás",
            "value": "Tóalmás"
        },
        {
            "label": "Tófalu",
            "value": "Tófalu"
        },
        {
            "label": "Tófej",
            "value": "Tófej"
        },
        {
            "label": "Tófű",
            "value": "Tófű"
        },
        {
            "label": "Tokaj",
            "value": "Tokaj"
        },
        {
            "label": "Tokod",
            "value": "Tokod"
        },
        {
            "label": "Tokodaltáró",
            "value": "Tokodaltáró"
        },
        {
            "label": "Tokorcs",
            "value": "Tokorcs"
        },
        {
            "label": "Tolcsva",
            "value": "Tolcsva"
        },
        {
            "label": "Told",
            "value": "Told"
        },
        {
            "label": "Tolmács",
            "value": "Tolmács"
        },
        {
            "label": "Tolna",
            "value": "Tolna"
        },
        {
            "label": "Tolnanémedi",
            "value": "Tolnanémedi"
        },
        {
            "label": "Tomajmonostora",
            "value": "Tomajmonostora"
        },
        {
            "label": "Tomor",
            "value": "Tomor"
        },
        {
            "label": "Tompa",
            "value": "Tompa"
        },
        {
            "label": "Tompaládony",
            "value": "Tompaládony"
        },
        {
            "label": "Tordas",
            "value": "Tordas"
        },
        {
            "label": "Tormafölde",
            "value": "Tormafölde"
        },
        {
            "label": "Tormás",
            "value": "Tormás"
        },
        {
            "label": "Tormásliget",
            "value": "Tormásliget"
        },
        {
            "label": "Tornabarakony",
            "value": "Tornabarakony"
        },
        {
            "label": "Tornakápolna",
            "value": "Tornakápolna"
        },
        {
            "label": "Tornanádaska",
            "value": "Tornanádaska"
        },
        {
            "label": "Tornaszentandrás",
            "value": "Tornaszentandrás"
        },
        {
            "label": "Tornaszentjakab",
            "value": "Tornaszentjakab"
        },
        {
            "label": "Tornyiszentmiklós",
            "value": "Tornyiszentmiklós"
        },
        {
            "label": "Tornyosnémeti",
            "value": "Tornyosnémeti"
        },
        {
            "label": "Tornyospálca",
            "value": "Tornyospálca"
        },
        {
            "label": "Torony",
            "value": "Torony"
        },
        {
            "label": "Torvaj",
            "value": "Torvaj"
        },
        {
            "label": "Tószeg",
            "value": "Tószeg"
        },
        {
            "label": "Tótkomlós",
            "value": "Tótkomlós"
        },
        {
            "label": "Tótszentgyörgy",
            "value": "Tótszentgyörgy"
        },
        {
            "label": "Tótszentmárton",
            "value": "Tótszentmárton"
        },
        {
            "label": "Tótszerdahely",
            "value": "Tótszerdahely"
        },
        {
            "label": "Tótújfalu",
            "value": "Tótújfalu"
        },
        {
            "label": "Tótvázsony",
            "value": "Tótvázsony"
        },
        {
            "label": "Tök",
            "value": "Tök"
        },
        {
            "label": "Tököl",
            "value": "Tököl"
        },
        {
            "label": "Töltéstava",
            "value": "Töltéstava"
        },
        {
            "label": "Tömörd",
            "value": "Tömörd"
        },
        {
            "label": "Tömörkény",
            "value": "Tömörkény"
        },
        {
            "label": "Törökbálint",
            "value": "Törökbálint"
        },
        {
            "label": "Törökkoppány",
            "value": "Törökkoppány"
        },
        {
            "label": "Törökszentmiklós",
            "value": "Törökszentmiklós"
        },
        {
            "label": "Törtel",
            "value": "Törtel"
        },
        {
            "label": "Töttös",
            "value": "Töttös"
        },
        {
            "label": "Trizs",
            "value": "Trizs"
        },
        {
            "label": "Tunyogmatolcs",
            "value": "Tunyogmatolcs"
        },
        {
            "label": "Tura",
            "value": "Tura"
        },
        {
            "label": "Túristvándi",
            "value": "Túristvándi"
        },
        {
            "label": "Túrkeve",
            "value": "Túrkeve"
        },
        {
            "label": "Túrony",
            "value": "Túrony"
        },
        {
            "label": "Túrricse",
            "value": "Túrricse"
        },
        {
            "label": "Tuzsér",
            "value": "Tuzsér"
        },
        {
            "label": "Türje",
            "value": "Türje"
        },
        {
            "label": "Tüskevár",
            "value": "Tüskevár"
        },
        {
            "label": "Tyukod",
            "value": "Tyukod"
        },
        {
            "label": "Udvar",
            "value": "Udvar"
        },
        {
            "label": "Udvari",
            "value": "Udvari"
        },
        {
            "label": "Ugod",
            "value": "Ugod"
        },
        {
            "label": "Újbarok",
            "value": "Újbarok"
        },
        {
            "label": "Újcsanálos",
            "value": "Újcsanálos"
        },
        {
            "label": "Újdombrád",
            "value": "Újdombrád"
        },
        {
            "label": "Újfehértó",
            "value": "Újfehértó"
        },
        {
            "label": "Újhartyán",
            "value": "Újhartyán"
        },
        {
            "label": "Újiráz",
            "value": "Újiráz"
        },
        {
            "label": "Újireg",
            "value": "Újireg"
        },
        {
            "label": "Újkenéz",
            "value": "Újkenéz"
        },
        {
            "label": "Újkér",
            "value": "Újkér"
        },
        {
            "label": "Újkígyós",
            "value": "Újkígyós"
        },
        {
            "label": "Újlengyel",
            "value": "Újlengyel"
        },
        {
            "label": "Újléta",
            "value": "Újléta"
        },
        {
            "label": "Újlőrincfalva",
            "value": "Újlőrincfalva"
        },
        {
            "label": "Újpetre",
            "value": "Újpetre"
        },
        {
            "label": "Újrónafő",
            "value": "Újrónafő"
        },
        {
            "label": "Újsolt",
            "value": "Újsolt"
        },
        {
            "label": "Újszalonta",
            "value": "Újszalonta"
        },
        {
            "label": "Újszász",
            "value": "Újszász"
        },
        {
            "label": "Újszentiván",
            "value": "Újszentiván"
        },
        {
            "label": "Újszentmargita",
            "value": "Újszentmargita"
        },
        {
            "label": "Újszilvás",
            "value": "Újszilvás"
        },
        {
            "label": "Újtelek",
            "value": "Újtelek"
        },
        {
            "label": "Újtikos",
            "value": "Újtikos"
        },
        {
            "label": "Újudvar",
            "value": "Újudvar"
        },
        {
            "label": "Újvárfalva",
            "value": "Újvárfalva"
        },
        {
            "label": "Ukk",
            "value": "Ukk"
        },
        {
            "label": "Und",
            "value": "Und"
        },
        {
            "label": "Úny",
            "value": "Úny"
        },
        {
            "label": "Uppony",
            "value": "Uppony"
        },
        {
            "label": "Ura",
            "value": "Ura"
        },
        {
            "label": "Uraiújfalu",
            "value": "Uraiújfalu"
        },
        {
            "label": "Úrhida",
            "value": "Úrhida"
        },
        {
            "label": "Úri",
            "value": "Úri"
        },
        {
            "label": "Úrkút",
            "value": "Úrkút"
        },
        {
            "label": "Uszka",
            "value": "Uszka"
        },
        {
            "label": "Uszód",
            "value": "Uszód"
        },
        {
            "label": "Uzsa",
            "value": "Uzsa"
        },
        {
            "label": "Üllés",
            "value": "Üllés"
        },
        {
            "label": "Üllő",
            "value": "Üllő"
        },
        {
            "label": "Üröm",
            "value": "Üröm"
        },
        {
            "label": "Vác",
            "value": "Vác"
        },
        {
            "label": "Vácduka",
            "value": "Vácduka"
        },
        {
            "label": "Vácegres",
            "value": "Vácegres"
        },
        {
            "label": "Váchartyán",
            "value": "Váchartyán"
        },
        {
            "label": "Váckisújfalu",
            "value": "Váckisújfalu"
        },
        {
            "label": "Vácrátót",
            "value": "Vácrátót"
        },
        {
            "label": "Vácszentlászló",
            "value": "Vácszentlászló"
        },
        {
            "label": "Vadna",
            "value": "Vadna"
        },
        {
            "label": "Vadosfa",
            "value": "Vadosfa"
        },
        {
            "label": "Vág",
            "value": "Vág"
        },
        {
            "label": "Vágáshuta",
            "value": "Vágáshuta"
        },
        {
            "label": "Vaja",
            "value": "Vaja"
        },
        {
            "label": "Vajdácska",
            "value": "Vajdácska"
        },
        {
            "label": "Vajszló",
            "value": "Vajszló"
        },
        {
            "label": "Vajta",
            "value": "Vajta"
        },
        {
            "label": "Vál",
            "value": "Vál"
        },
        {
            "label": "Valkó",
            "value": "Valkó"
        },
        {
            "label": "Valkonya",
            "value": "Valkonya"
        },
        {
            "label": "Vállaj",
            "value": "Vállaj"
        },
        {
            "label": "Vállus",
            "value": "Vállus"
        },
        {
            "label": "Vámosatya",
            "value": "Vámosatya"
        },
        {
            "label": "Vámoscsalád",
            "value": "Vámoscsalád"
        },
        {
            "label": "Vámosgyörk",
            "value": "Vámosgyörk"
        },
        {
            "label": "Vámosmikola",
            "value": "Vámosmikola"
        },
        {
            "label": "Vámosoroszi",
            "value": "Vámosoroszi"
        },
        {
            "label": "Vámospércs",
            "value": "Vámospércs"
        },
        {
            "label": "Vámosszabadi",
            "value": "Vámosszabadi"
        },
        {
            "label": "Vámosújfalu",
            "value": "Vámosújfalu"
        },
        {
            "label": "Váncsod",
            "value": "Váncsod"
        },
        {
            "label": "Vanyarc",
            "value": "Vanyarc"
        },
        {
            "label": "Vanyola",
            "value": "Vanyola"
        },
        {
            "label": "Várad",
            "value": "Várad"
        },
        {
            "label": "Váralja",
            "value": "Váralja"
        },
        {
            "label": "Varászló",
            "value": "Varászló"
        },
        {
            "label": "Váraszó",
            "value": "Váraszó"
        },
        {
            "label": "Várbalog",
            "value": "Várbalog"
        },
        {
            "label": "Varbó",
            "value": "Varbó"
        },
        {
            "label": "Varbóc",
            "value": "Varbóc"
        },
        {
            "label": "Várda",
            "value": "Várda"
        },
        {
            "label": "Várdomb",
            "value": "Várdomb"
        },
        {
            "label": "Várfölde",
            "value": "Várfölde"
        },
        {
            "label": "Varga",
            "value": "Varga"
        },
        {
            "label": "Várgesztes",
            "value": "Várgesztes"
        },
        {
            "label": "Várkesző",
            "value": "Várkesző"
        },
        {
            "label": "Várong",
            "value": "Várong"
        },
        {
            "label": "Városföld",
            "value": "Városföld"
        },
        {
            "label": "Városlőd",
            "value": "Városlőd"
        },
        {
            "label": "Várpalota",
            "value": "Várpalota"
        },
        {
            "label": "Varsád",
            "value": "Varsád"
        },
        {
            "label": "Varsány",
            "value": "Varsány"
        },
        {
            "label": "Várvölgy",
            "value": "Várvölgy"
        },
        {
            "label": "Vasad",
            "value": "Vasad"
        },
        {
            "label": "Vasalja",
            "value": "Vasalja"
        },
        {
            "label": "Vásárosbéc",
            "value": "Vásárosbéc"
        },
        {
            "label": "Vásárosdombó",
            "value": "Vásárosdombó"
        },
        {
            "label": "Vásárosfalu",
            "value": "Vásárosfalu"
        },
        {
            "label": "Vásárosmiske",
            "value": "Vásárosmiske"
        },
        {
            "label": "Vásárosnamény",
            "value": "Vásárosnamény"
        },
        {
            "label": "Vasasszonyfa",
            "value": "Vasasszonyfa"
        },
        {
            "label": "Vasboldogasszony",
            "value": "Vasboldogasszony"
        },
        {
            "label": "Vasegerszeg",
            "value": "Vasegerszeg"
        },
        {
            "label": "Vashosszúfalu",
            "value": "Vashosszúfalu"
        },
        {
            "label": "Vaskeresztes",
            "value": "Vaskeresztes"
        },
        {
            "label": "Vaskút",
            "value": "Vaskút"
        },
        {
            "label": "Vasmegyer",
            "value": "Vasmegyer"
        },
        {
            "label": "Vaspör",
            "value": "Vaspör"
        },
        {
            "label": "Vassurány",
            "value": "Vassurány"
        },
        {
            "label": "Vasszécseny",
            "value": "Vasszécseny"
        },
        {
            "label": "Vasszentmihály",
            "value": "Vasszentmihály"
        },
        {
            "label": "Vasszilvágy",
            "value": "Vasszilvágy"
        },
        {
            "label": "Vasvár",
            "value": "Vasvár"
        },
        {
            "label": "Vaszar",
            "value": "Vaszar"
        },
        {
            "label": "Vászoly",
            "value": "Vászoly"
        },
        {
            "label": "Vát",
            "value": "Vát"
        },
        {
            "label": "Vatta",
            "value": "Vatta"
        },
        {
            "label": "Vázsnok",
            "value": "Vázsnok"
        },
        {
            "label": "Vécs",
            "value": "Vécs"
        },
        {
            "label": "Vecsés",
            "value": "Vecsés"
        },
        {
            "label": "Végegyháza",
            "value": "Végegyháza"
        },
        {
            "label": "Vejti",
            "value": "Vejti"
        },
        {
            "label": "Vékény",
            "value": "Vékény"
        },
        {
            "label": "Vekerd",
            "value": "Vekerd"
        },
        {
            "label": "Velem",
            "value": "Velem"
        },
        {
            "label": "Velemér",
            "value": "Velemér"
        },
        {
            "label": "Velence",
            "value": "Velence"
        },
        {
            "label": "Velény",
            "value": "Velény"
        },
        {
            "label": "Véménd",
            "value": "Véménd"
        },
        {
            "label": "Vének",
            "value": "Vének"
        },
        {
            "label": "Vép",
            "value": "Vép"
        },
        {
            "label": "Vereb",
            "value": "Vereb"
        },
        {
            "label": "Veresegyház",
            "value": "Veresegyház"
        },
        {
            "label": "Verőce",
            "value": "Verőce"
        },
        {
            "label": "Verpelét",
            "value": "Verpelét"
        },
        {
            "label": "Verseg",
            "value": "Verseg"
        },
        {
            "label": "Versend",
            "value": "Versend"
        },
        {
            "label": "Vértesacsa",
            "value": "Vértesacsa"
        },
        {
            "label": "Vértesboglár",
            "value": "Vértesboglár"
        },
        {
            "label": "Vérteskethely",
            "value": "Vérteskethely"
        },
        {
            "label": "Vértessomló",
            "value": "Vértessomló"
        },
        {
            "label": "Vértesszőlős",
            "value": "Vértesszőlős"
        },
        {
            "label": "Vértestolna",
            "value": "Vértestolna"
        },
        {
            "label": "Vése",
            "value": "Vése"
        },
        {
            "label": "Veszkény",
            "value": "Veszkény"
        },
        {
            "label": "Veszprém",
            "value": "Veszprém"
        },
        {
            "label": "Veszprémfajsz",
            "value": "Veszprémfajsz"
        },
        {
            "label": "Veszprémgalsa",
            "value": "Veszprémgalsa"
        },
        {
            "label": "Veszprémvarsány",
            "value": "Veszprémvarsány"
        },
        {
            "label": "Vésztő",
            "value": "Vésztő"
        },
        {
            "label": "Vezseny",
            "value": "Vezseny"
        },
        {
            "label": "Vid",
            "value": "Vid"
        },
        {
            "label": "Vigántpetend",
            "value": "Vigántpetend"
        },
        {
            "label": "Villány",
            "value": "Villány"
        },
        {
            "label": "Villánykövesd",
            "value": "Villánykövesd"
        },
        {
            "label": "Vilmány",
            "value": "Vilmány"
        },
        {
            "label": "Vilonya",
            "value": "Vilonya"
        },
        {
            "label": "Vilyvitány",
            "value": "Vilyvitány"
        },
        {
            "label": "Vinár",
            "value": "Vinár"
        },
        {
            "label": "Vindornyafok",
            "value": "Vindornyafok"
        },
        {
            "label": "Vindornyalak",
            "value": "Vindornyalak"
        },
        {
            "label": "Vindornyaszőlős",
            "value": "Vindornyaszőlős"
        },
        {
            "label": "Visegrád",
            "value": "Visegrád"
        },
        {
            "label": "Visnye",
            "value": "Visnye"
        },
        {
            "label": "Visonta",
            "value": "Visonta"
        },
        {
            "label": "Viss",
            "value": "Viss"
        },
        {
            "label": "Visz",
            "value": "Visz"
        },
        {
            "label": "Viszák",
            "value": "Viszák"
        },
        {
            "label": "Viszló",
            "value": "Viszló"
        },
        {
            "label": "Visznek",
            "value": "Visznek"
        },
        {
            "label": "Vitnyéd",
            "value": "Vitnyéd"
        },
        {
            "label": "Vizslás",
            "value": "Vizslás"
        },
        {
            "label": "Vizsoly",
            "value": "Vizsoly"
        },
        {
            "label": "Vízvár",
            "value": "Vízvár"
        },
        {
            "label": "Vokány",
            "value": "Vokány"
        },
        {
            "label": "Vonyarcvashegy",
            "value": "Vonyarcvashegy"
        },
        {
            "label": "Vöckönd",
            "value": "Vöckönd"
        },
        {
            "label": "Völcsej",
            "value": "Völcsej"
        },
        {
            "label": "Vönöck",
            "value": "Vönöck"
        },
        {
            "label": "Vöröstó",
            "value": "Vöröstó"
        },
        {
            "label": "Vörs",
            "value": "Vörs"
        },
        {
            "label": "Zabar",
            "value": "Zabar"
        },
        {
            "label": "Zádor",
            "value": "Zádor"
        },
        {
            "label": "Zádorfalva",
            "value": "Zádorfalva"
        },
        {
            "label": "Zagyvarékas",
            "value": "Zagyvarékas"
        },
        {
            "label": "Zagyvaszántó",
            "value": "Zagyvaszántó"
        },
        {
            "label": "Záhony",
            "value": "Záhony"
        },
        {
            "label": "Zajk",
            "value": "Zajk"
        },
        {
            "label": "Zajta",
            "value": "Zajta"
        },
        {
            "label": "Zákány",
            "value": "Zákány"
        },
        {
            "label": "Zákányfalu",
            "value": "Zákányfalu"
        },
        {
            "label": "Zákányszék",
            "value": "Zákányszék"
        },
        {
            "label": "Zala",
            "value": "Zala"
        },
        {
            "label": "Zalaapáti",
            "value": "Zalaapáti"
        },
        {
            "label": "Zalabaksa",
            "value": "Zalabaksa"
        },
        {
            "label": "Zalabér",
            "value": "Zalabér"
        },
        {
            "label": "Zalaboldogfa",
            "value": "Zalaboldogfa"
        },
        {
            "label": "Zalacsány",
            "value": "Zalacsány"
        },
        {
            "label": "Zalacséb",
            "value": "Zalacséb"
        },
        {
            "label": "Zalaegerszeg",
            "value": "Zalaegerszeg"
        },
        {
            "label": "Zalaerdőd",
            "value": "Zalaerdőd"
        },
        {
            "label": "Zalagyömörő",
            "value": "Zalagyömörő"
        },
        {
            "label": "Zalahaláp",
            "value": "Zalahaláp"
        },
        {
            "label": "Zalaháshágy",
            "value": "Zalaháshágy"
        },
        {
            "label": "Zalaigrice",
            "value": "Zalaigrice"
        },
        {
            "label": "Zalaistvánd",
            "value": "Zalaistvánd"
        },
        {
            "label": "Zalakaros",
            "value": "Zalakaros"
        },
        {
            "label": "Zalakomár",
            "value": "Zalakomár"
        },
        {
            "label": "Zalaköveskút",
            "value": "Zalaköveskút"
        },
        {
            "label": "Zalalövő",
            "value": "Zalalövő"
        },
        {
            "label": "Zalameggyes",
            "value": "Zalameggyes"
        },
        {
            "label": "Zalamerenye",
            "value": "Zalamerenye"
        },
        {
            "label": "Zalasárszeg",
            "value": "Zalasárszeg"
        },
        {
            "label": "Zalaszabar",
            "value": "Zalaszabar"
        },
        {
            "label": "Zalaszántó",
            "value": "Zalaszántó"
        },
        {
            "label": "Zalaszegvár",
            "value": "Zalaszegvár"
        },
        {
            "label": "Zalaszentbalázs",
            "value": "Zalaszentbalázs"
        },
        {
            "label": "Zalaszentgrót",
            "value": "Zalaszentgrót"
        },
        {
            "label": "Zalaszentgyörgy",
            "value": "Zalaszentgyörgy"
        },
        {
            "label": "Zalaszentiván",
            "value": "Zalaszentiván"
        },
        {
            "label": "Zalaszentjakab",
            "value": "Zalaszentjakab"
        },
        {
            "label": "Zalaszentlászló",
            "value": "Zalaszentlászló"
        },
        {
            "label": "Zalaszentlőrinc",
            "value": "Zalaszentlőrinc"
        },
        {
            "label": "Zalaszentmárton",
            "value": "Zalaszentmárton"
        },
        {
            "label": "Zalaszentmihály",
            "value": "Zalaszentmihály"
        },
        {
            "label": "Zalaszombatfa",
            "value": "Zalaszombatfa"
        },
        {
            "label": "Zaláta",
            "value": "Zaláta"
        },
        {
            "label": "Zalatárnok",
            "value": "Zalatárnok"
        },
        {
            "label": "Zalaújlak",
            "value": "Zalaújlak"
        },
        {
            "label": "Zalavár",
            "value": "Zalavár"
        },
        {
            "label": "Zalavég",
            "value": "Zalavég"
        },
        {
            "label": "Zalkod",
            "value": "Zalkod"
        },
        {
            "label": "Zamárdi",
            "value": "Zamárdi"
        },
        {
            "label": "Zámoly",
            "value": "Zámoly"
        },
        {
            "label": "Zánka",
            "value": "Zánka"
        },
        {
            "label": "Zaránk",
            "value": "Zaránk"
        },
        {
            "label": "Závod",
            "value": "Závod"
        },
        {
            "label": "Zebecke",
            "value": "Zebecke"
        },
        {
            "label": "Zebegény",
            "value": "Zebegény"
        },
        {
            "label": "Zemplénagárd",
            "value": "Zemplénagárd"
        },
        {
            "label": "Zengővárkony",
            "value": "Zengővárkony"
        },
        {
            "label": "Zichyújfalu",
            "value": "Zichyújfalu"
        },
        {
            "label": "Zics",
            "value": "Zics"
        },
        {
            "label": "Ziliz",
            "value": "Ziliz"
        },
        {
            "label": "Zimány",
            "value": "Zimány"
        },
        {
            "label": "Zirc",
            "value": "Zirc"
        },
        {
            "label": "Zók",
            "value": "Zók"
        },
        {
            "label": "Zomba",
            "value": "Zomba"
        },
        {
            "label": "Zsadány",
            "value": "Zsadány"
        },
        {
            "label": "Zsáka",
            "value": "Zsáka"
        },
        {
            "label": "Zsámbék",
            "value": "Zsámbék"
        },
        {
            "label": "Zsámbok",
            "value": "Zsámbok"
        },
        {
            "label": "Zsana",
            "value": "Zsana"
        },
        {
            "label": "Zsarolyán",
            "value": "Zsarolyán"
        },
        {
            "label": "Zsebeháza",
            "value": "Zsebeháza"
        },
        {
            "label": "Zsédeny",
            "value": "Zsédeny"
        },
        {
            "label": "Zselickisfalud",
            "value": "Zselickisfalud"
        },
        {
            "label": "Zselickislak",
            "value": "Zselickislak"
        },
        {
            "label": "Zselicszentpál",
            "value": "Zselicszentpál"
        },
        {
            "label": "Zsennye",
            "value": "Zsennye"
        },
        {
            "label": "Zsira",
            "value": "Zsira"
        },
        {
            "label": "Zsombó",
            "value": "Zsombó"
        },
        {
            "label": "Zsujta",
            "value": "Zsujta"
        },
        {
            "label": "Zsurk",
            "value": "Zsurk"
        },
        {
            "label": "Zubogy",
            "value": "Zubogy"
        }
    ];
export default { cityList };
